export const aiCTATranslations = {
  headline: {
    en: "The business landscape of the future will have those who have embraced A.I and those who wish they had",
    es: "El panorama empresarial del futuro tendrá aquellos que hayan abrazado la IA y aquellos que desearían haberlo hecho",
    de: "Die Geschäftslandschaft der Zukunft wird diejenigen haben, die KI angenommen haben und diejenigen, die es wünschten",
  },
  ctaCards: {
    a: {
      buttonText: {
        en: "Book call",
        es: "Reservar llamada",
        de: "Anruf buchen",
      },
      headline: { en: "Headline", es: "Título", de: "Überschrift" },
      overline: {
        en: "Free discovery call",
        es: "Llamada de descubrimiento gratuita",
        de: "Kostenloser Entdeckungsanruf",
      },
      text: {
        en: "Explore, ask, or seek solutions. Let's see where we intersect. From solo to enterprise everyone's invited. No strings attached.",
        es: "Explora, pregunta o busca soluciones. Veamos dónde nos cruzamos. Desde solo hasta empresa, todos están invitados. Sin ataduras.",
        de: "Erkunden, Fragen stellen oder Lösungen suchen. Lassen Sie uns sehen, wo wir uns schneiden. Von Solo bis hin zu Unternehmen sind alle eingeladen. Ohne Bedingungen.",
      },
    },
    b: {
      buttonText: {
        en: "Book free call",
        es: "Reservar llamada gratuita",
        de: "Kostenlosen Anruf buchen",
      },
      headline: { en: "Headline", es: "Título", de: "Überschrift" },
      overline: {
        en: "Join our AI Experts network",
        es: "Únase a nuestra red de expertos en IA",
        de: "Treten Sie unserem Netzwerk von KI-Experten bei",
      },
      text: {
        en: "Niche specialists or multi-disciplinary teams: Exciting AI projects need your expertise. Join our network and get matched with thrilling projects.",
        es: "Especialistas de nicho o equipos multidisciplinarios: Proyectos emocionantes de IA necesitan tu experiencia. Únete a nuestra red y sé emparejado con proyectos emocionantes.",
        de: "Nischenexperten oder multidisziplinäre Teams: Spannende KI-Projekte benötigen Ihr Fachwissen. Treten Sie unserem Netzwerk bei und werden Sie mit spannenden Projekten zusammengebracht.",
      },
    },
  },
};
