import { ReactNode } from "react";

type CardPropsType = {
  children: ReactNode;
  className?: string;
};

export const Card = ({ children, className }: CardPropsType) => {
  return (
    <div className={`border-grey border p-5 shadow-smooth bg-background rounded-bl-2xl rounded-t-2xl ${className}`}>
      {children}
    </div>
  );
};
