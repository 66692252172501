import { StepFormStepType } from "../../StepFormTypes";
import {
  getStepFormButtonFromArray,
  getStepFormButton,
} from "../formDataElementsUtils";

const businessSectors = [
  "Agriculture and Natural Resources",
  "Education / EdTech",
  "Arts, Media, and Entertainment",
  "Healthcare / Pharmaceuticals / biotech",
  "Retail / E-commerce",
  "Banking / Finance / FinTech",
  "Supply chain / logistics",
  "Travel",
  "Real estate / Construciton",
  "Manufacturing / Industrial",
  "Automotive",
  "Fashion",
  "IT / Technology",
];

export const stepFormDataUseCases: StepFormStepType[] = [
  {
    id: "useCases-1",
    title: "Which sector best describes your business?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          ...getStepFormButtonFromArray(businessSectors, "useCases-travel"),
        ], //TODO for each sector
      },
    },
  },
  {
    id: "useCases-travel",
    title:
      "Here are some of the AI use cases for your industry. CV AI Analysis and scoring: AI system used to score the CV and score it based on the position requirements, extract relevant information and speed up the process. CV AI Analysis and scoring: AI system used to score the CV and score it based on the position requirements, extract relevant information and speed up the process. CV AI Analysis and scoring: AI system used to score the CV and score it based on the position requirements, extract relevant information and speed up the process.",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormButton(
            "Book the free discovery call",
            "bookCall-1"
          ),
          getStepFormButton("I have more questions", "questions-1"),
        ],
      },
    },
  },
];
