import { useCallback } from "react";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { getParticleConfig } from "./particles-config";
import { Engine } from "tsparticles-engine";
import { useTheme } from "../../Providers/ThemeProvider";

export const ParticlesBackground = () => {
  const particlesInit = useCallback(async (engine: Engine) => {
    await loadSlim(engine);
  }, []);
  const { theme } = useTheme();

  const particlesLoaded = useCallback(async (container: any) => {}, []);

  const particleConfig: any = getParticleConfig(
    theme === "dark" ? "#17191e" : "#ffffff",
    "#5c63f3"
  );

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      loaded={particlesLoaded}
      options={particleConfig}
    />
  );
};
