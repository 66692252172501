import { StepFormStepType } from "../../StepFormTypes";
import {
  getStepFormButtonFromArray,
  getStepFormButton,
  getStepFormInputField,
} from "../formDataElementsUtils";

const businessSectors = [
  "ai.businessSector.agricultureNaturalResources",
  "ai.businessSector.educationEdTech",
  "ai.businessSector.artsMediaEntertainment",
  "ai.businessSector.healthcarePharmaceuticalsBiotech",
  "ai.businessSector.retailEcommerce",
  "ai.businessSector.bankingFinanceFinTech",
  "ai.businessSector.supplyChainLogistics",
  "ai.businessSector.travel",
  "ai.businessSector.realEstateConstruction",
  "ai.businessSector.manufacturingIndustrial",
  "ai.businessSector.automotive",
  "ai.businessSector.fashion",
  "ai.businessSector.itTechnology",
];

const companyPositions = [
  "ai.companyPositions.founder",
  "ai.companyPositions.executive",
  "ai.companyPositions.marketing",
  "ai.companyPositions.dataScientist",
  "ai.companyPositions.consultant",
  "ai.companyPositions.hr",
  "ai.companyPositions.other",
];

const companyAIExperience = [
  "ai.bookCall.companyAIExperience.noExperience",
  "ai.bookCall.companyAIExperience.someExperience",
  "ai.bookCall.companyAIExperience.intermediate",
  "ai.bookCall.companyAIExperience.advanced",
];

const aiConcerns = [
  "ai.bookCall.aiConcerns.costOfImplementation",
  "ai.bookCall.aiConcerns.dataPrivacySecurity",
  "ai.bookCall.aiConcerns.lackOfExpertise",
  "ai.bookCall.aiConcerns.unclearROI",
  "ai.bookCall.aiConcerns.noConcerns",
];

export const stepFormDataBookCall: StepFormStepType[] = [
  {
    id: "bookCall-start",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormButton(
            "ai.bookCallButtonLabel",
            "bookCall-1",
            undefined,
            true
          ),
        ],
      },
    },
  },
  {
    id: "bookCall-1",
    title: "ai.bookCall.position",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          ...getStepFormButtonFromArray(companyPositions, "bookCall-2"),
        ],
      },
    },
  },
  {
    id: "bookCall-2",
    title: "ai.bookCall.sectors",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getStepFormButtonFromArray(businessSectors, "bookCall-3")],
      },
    },
  },
  {
    id: "bookCall-3",
    title: "ai.bookCall.teamExperience",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          ...getStepFormButtonFromArray(companyAIExperience, "bookCall-4"),
        ],
      },
    },
  },
  {
    id: "bookCall-4",
    title: "ai.bookCall.aiConcernsTitle",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getStepFormButtonFromArray(aiConcerns, "bookCall-5")],
      },
    },
  },
  {
    id: "bookCall-5",
    title: "ai.bookCall.companyInfo",
    answer: {
      type: "input_fields",
      answerConfig: {
        fields: [
          getStepFormInputField("ai.bookCall.companyName"),
          getStepFormInputField("ai.bookCall.websiteOrLinkedIn"),
        ],
        nextStepId: "bookCall-6",
        nextButtonLabel: "ai.bookCall.next",
      },
    },
  },
  {
    id: "bookCall-6",
    title: "ai.bookCall.reachOut",
    answer: {
      type: "input_fields",
      answerConfig: {
        fields: [
          getStepFormInputField("ai.bookCall.contactName", "name"),
          getStepFormInputField("ai.bookCall.contactEmail", "email"),
        ],
        submitForm: true,
        nextStepId: "bookCall-start",
        nextButtonLabel: "ai.bookCall.nextTodo",
      },
    },
  },
];
