import { stepFormTranslations } from "./translations";

export const getTranslationString = (keyPath: string, language = "en") => {
  const keys = keyPath.split(".");
  let translation: any = stepFormTranslations;
  for (const key of keys) {
    translation = translation[key];
    if (!translation) {
      return null;
    }
  }

  return translation[language];
};
