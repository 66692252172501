import { StepFormStepType } from "../../StepFormTypes";
import {
  getStepFormButtonFromArray,
  getStepFormButton,
} from "../formDataElementsUtils";

const aiSubfields = [
  "I'm not sure yet",
  "Machine learning",
  "Neural Networks",
  "Computer vision",
  "Deep learning",
  "NLP",
  "Data Engineering / Science",
  "Other",
  "Multiple areas",
];

const projectStatus = [
  "Exploring options / Planning",
  "Early development",
  "Advanced Development",
  "Testing / Validation",
  "In production",
];

const availability = [
  "Immideately",
  "Within 1 month",
  "1-3 months",
  "3-6 months",
  "More than 6 months",
];

export const stepFormDataFindExperts: StepFormStepType[] = [
  {
    id: "findExpert-1",
    title: "What type of experts are you looking for ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormButton("AI team for development", "findExpert-2"),
          getStepFormButton("AI Engineer / Architect", "findExpert-2"),
          getStepFormButton("AI Consultant / Strategist", "findExpert-2"),
        ],
      },
    },
  },
  {
    id: "findExpert-2",
    title: "Any specific skill you are looking for ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getStepFormButtonFromArray(aiSubfields, "findExpert-3")],
      },
    },
  },
  {
    id: "findExpert-3",
    title: "Current status of the project",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getStepFormButtonFromArray(projectStatus, "findExpert-4")],
      },
    },
  },
  {
    id: "findExpert-4",
    title: "When do you need the team / expert to start ?",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [...getStepFormButtonFromArray(availability, "findExpert-5")],
      },
    },
  },
  {
    id: "findExpert-5",
    title:
      "Looks good! Someone within the team will check the information and get in touch to discuss the details, How can we contact you ? ",
    answer: {
      type: "button_options",
      answerConfig: {
        options: [
          getStepFormButton(
            "Preview version only",
            "TODO"
          ),
        ],
      },
    },
  },
];
