import { ReactNode } from "react";

export type SectionWrapperType = {
  fullWidth?: boolean;
  headline?: string;
  tagline?: string;
  description?: string;
  children: ReactNode;
};

export const SectionWrapper = ({
  children,
  fullWidth,
  headline,
  description,
  tagline,
}: SectionWrapperType) => {
  const moduleSpacingClassName = "lg:mt-60 md:mt-40 mt-20";
  const moduleWidthClassName = "max-w-[1400px] mx-auto";

  return (
    <div
      className={`${moduleSpacingClassName}
        ${fullWidth ? "full-width-section" : moduleWidthClassName}
    `}
    >
      <div className=" mb-10 lg:mb-20 flex flex-col items-center">
        {headline && <h2 className="heading-2 text-center ">{headline}</h2>}
        {description && <p className="text-center body-1 text-lightGrey mt-5 max-w-[800px]">{description}</p>}
      </div>
      {children}
    </div>
  );
};
