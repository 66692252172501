import "./Button.scss";

type ButtonPropsType = {
  label: string;
  className?: string;
  highlight?: boolean;
  disabled?: boolean;
  action: () => void;
};

export const Button = ({
  label,
  className,
  disabled,
  highlight,
  action,
}: ButtonPropsType) => {
  return (
    <button
      disabled={disabled}
      className={`button px-4 py-2 text-md font-medium shadow-smooth rounded-lg border ${
        disabled
          ? "text-grey"
          : "border-accent-transparent active:text-accent active:border-accent"
      } ${
        highlight
          ? "bg-accent hover:bg-transparent transition-all duration-500 hover:shadow-sm hover:shadow-accent"
          : "bg-background"
      } ${className}`}
      onClick={action}
    >
      {label}
    </button>
  );
};
