import logo from "../../Assets/logo.png";

export const Footer = () => {
  return (
    <div className="p-5 md:p-40 mt-20 md:mt-40 bg-background text-main flex-col md:flex-row flex items-center justify-between relative">
      <hr
        className="absolute top-0 w-full h-px -translate-x-1/2 border-0 opacity-10 left-1/2"
        style={{
          backgroundImage:
            "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 52.07%, rgba(255, 255, 255, 0) 100%)",
        }}
      ></hr>
      <div className="flex items-center w-fit">
        <img
          className="w-9 p-1  z-10"
          src={logo}
          alt="Logo"
        />
        <h1 className={`logo pl-4 text-main -ml-3 `}>MILLERIO</h1>
      </div>
      <p className="text-main md:text-left text-center mt-5 md:mt-0">
        Website under development - Preview version
      </p>
    </div>
  );
};
