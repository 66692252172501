import { SectionType } from "../modules/TabsSection/TabsSection";

export const servicesSolutionsData: SectionType[] = [
  {
    headline: "ai.services.solutions.a.headline",
    image: "chess",
    listOfItems: [
      { text: "ai.services.solutions.a.listOfItems.a.text" },
      { text: "ai.services.solutions.a.listOfItems.b.text" },
      { text: "ai.services.solutions.a.listOfItems.c.text" },
      { text: "ai.services.solutions.a.listOfItems.d.text" },
      { text: "ai.services.solutions.a.listOfItems.e.text" },
    ],
  },
  {
    headline: "ai.services.solutions.b.headline",
    image: "build",

    listOfItems: [
      { text: "ai.services.solutions.b.listOfItems.e.text" },
      { text: "ai.services.solutions.b.listOfItems.f.text" },
      { text: "ai.services.solutions.b.listOfItems.c.text" },
      { text: "ai.services.solutions.b.listOfItems.d.text" },
      { text: "ai.services.solutions.b.listOfItems.a.text" },
      { text: "ai.services.solutions.b.listOfItems.b.text" },
    ],
  },
  {
    headline: "ai.services.solutions.d.headline",
    image: "dev", //TODO: Add image
    listOfItems: [
      { text: "ai.services.solutions.d.listOfItems.a.text" },
      { text: "ai.services.solutions.d.listOfItems.b.text" },
      { text: "ai.services.solutions.d.listOfItems.c.text" },
      { text: "ai.services.solutions.d.listOfItems.d.text" },
      { text: "ai.services.solutions.d.listOfItems.e.text" },
    ],
  },
  {
    headline: "ai.services.solutions.c.headline",
    image: "network",
    listOfItems: [
      { text: "ai.services.solutions.c.listOfItems.e.text" },
      { text: "ai.services.solutions.c.listOfItems.a.text" },
      { text: "ai.services.solutions.c.listOfItems.b.text" },
      { text: "ai.services.solutions.c.listOfItems.d.text" },
      { text: "ai.services.solutions.c.listOfItems.c.text" },
    ],
  },
];

export const servicesDevelopmentData: SectionType[] = [
  {
    headline: "ai.services.development.c.headline",
    image: "ai-brain",
    listOfItems: [
      { text: "ai.services.development.c.listOfItems.a.text" },
      { text: "ai.services.development.c.listOfItems.b.text" },
      { text: "ai.services.development.c.listOfItems.c.text" },
      { text: "ai.services.development.c.listOfItems.d.text" },
      { text: "ai.services.development.c.listOfItems.e.text" },
    ],
  },
  {
    headline: "ai.services.development.a.headline",
    image: "chain-cube",
    listOfItems: [
      { text: "ai.services.development.a.listOfItems.a.text" },
      { text: "ai.services.development.a.listOfItems.e.text" },
      { text: "ai.services.development.a.listOfItems.b.text" },
      { text: "ai.services.development.a.listOfItems.c.text" },
      { text: "ai.services.development.a.listOfItems.d.text" },
    ],
  },
  {
    headline: "ai.services.development.b.headline",
    image: "",
    listOfItems: [
      { text: "ai.services.development.b.listOfItems.a.text" },
      { text: "ai.services.development.b.listOfItems.e.text" },
      { text: "ai.services.development.b.listOfItems.b.text" },
      { text: "ai.services.development.b.listOfItems.c.text" },
      { text: "ai.services.development.b.listOfItems.d.text" },
    ],
  },
];

export const servicesInfrastructureData: SectionType[] = [
  {
    headline: "ai.services.dataInfrastructure.a.headline",
    image: "",
    listOfItems: [
      { text: "ai.services.dataInfrastructure.a.listOfItems.a.text" },
      { text: "ai.services.dataInfrastructure.a.listOfItems.b.text" },
      { text: "ai.services.dataInfrastructure.a.listOfItems.c.text" },
      { text: "ai.services.dataInfrastructure.a.listOfItems.d.text" },
      { text: "ai.services.dataInfrastructure.a.listOfItems.e.text" },
    ],
  },
  {
    headline: "ai.services.dataInfrastructure.b.headline",
    image: "",
    listOfItems: [
      { text: "ai.services.dataInfrastructure.b.listOfItems.a.text" },
      { text: "ai.services.dataInfrastructure.b.listOfItems.b.text" },
      { text: "ai.services.dataInfrastructure.b.listOfItems.c.text" },
      { text: "ai.services.dataInfrastructure.b.listOfItems.d.text" },
      { text: "ai.services.dataInfrastructure.b.listOfItems.e.text" },
    ],
  },
  {
    headline: "ai.services.dataInfrastructure.c.headline",
    image: "",
    listOfItems: [
      { text: "ai.services.dataInfrastructure.c.listOfItems.a.text" },
      { text: "ai.services.dataInfrastructure.c.listOfItems.b.text" },
      { text: "ai.services.dataInfrastructure.c.listOfItems.c.text" },
      { text: "ai.services.dataInfrastructure.c.listOfItems.d.text" },
    ],
  },
];
