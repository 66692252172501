export const aiQATranslations = {
  headline: {
    en: "The Only Wrong Question Is the One Left Unasked",
    es: "La única pregunta equivocada es la que queda sin hacer",
    de: "Die einzige falsche Frage ist die, die unbeantwortet bleibt",
  },
  text: {
    en: "This is the text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... ",
    es: "Este es el texto ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... ",
    de: "Dies ist der Text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... his is the text ... ",
  },
  items: {
    a: {
      question: {
        en: "How can AI enhance my business operations?",
        es: "¿Cómo puede la IA mejorar mis operaciones comerciales?",
        de: "Wie kann KI meine Geschäftsabläufe verbessern?",
      },
      answer: {
        en: "AI can revolutionize virtually every aspect of your business, from streamlining internal processes to enhancing customer experiences and creating new revenue streams. Its applications are vast and varied across industries. We invite you to explore our comprehensive list of industry-specific use cases to see how AI can transform your particular business area.",
        es: "La IA puede revolucionar prácticamente todos los aspectos de su negocio, desde agilizar los procesos internos hasta mejorar las experiencias de los clientes y crear nuevas fuentes de ingresos. Sus aplicaciones son vastas y variadas en todas las industrias. Lo invitamos a explorar nuestra lista completa de casos de uso específicos de la industria para ver cómo la IA puede transformar su área de negocio particular.",
        de: "KI kann praktisch jeden Aspekt Ihres Unternehmens revolutionieren, von der Rationalisierung interner Prozesse über die Verbesserung der Kundenerfahrungen bis hin zur Schaffung neuer Einnahmequellen. Ihre Anwendungen sind branchenübergreifend vielfältig. Wir laden Sie ein, unsere umfassende Liste branchenspezifischer Anwendungsfälle zu erkunden, um zu sehen, wie KI Ihren speziellen Geschäftsbereich transformieren kann.",
      },
    },
    b: {
      question: {
        en: "What process do you use to identify the most impactful AI applications for my specific business needs? ",
        es: "¿Qué proceso utiliza para identificar las aplicaciones de IA más impactantes para las necesidades específicas de mi negocio?",
        de: "Welches Verfahren verwenden Sie, um die wirkungsvollsten KI-Anwendungen für meine spezifischen Geschäftsanforderungen zu identifizieren?",
      },
      answer: {
        en: "We begin by pairing you with an AI expert specialized in your industry. This expert, along with a dedicated team, will conduct an in-depth analysis of your company's processes and specific requirements. We then facilitate a brainstorming session with a group of experts to generate ideas tailored to your needs. Finally, we develop a customized AI blueprint and refine it through iterative discussions with you to ensure it aligns perfectly with your vision and goals.",
        es: "Comenzamos emparejándote con un experto en IA especializado en tu industria. Este experto, junto con un equipo dedicado, realizará un análisis en profundidad de los procesos y requisitos específicos de tu empresa. Luego, facilitamos una sesión de lluvia de ideas con un grupo de expertos para generar ideas adaptadas a tus necesidades. Finalmente, desarrollamos un plan personalizado de IA y lo refinamos a través de discusiones iterativas contigo para asegurarnos de que se alinee perfectamente con tu visión y objetivos.",
        de: "Wir beginnen damit, Sie mit einem KI-Experten zu verbinden, der auf Ihre Branche spezialisiert ist. Dieser Experte wird zusammen mit einem engagierten Team eine eingehende Analyse der Prozesse und spezifischen Anforderungen Ihres Unternehmens durchführen. Anschließend leiten wir eine Brainstorming-Sitzung mit einer Gruppe von Experten, um Ideen zu generieren, die auf Ihre Bedürfnisse zugeschnitten sind. Schließlich entwickeln wir einen maßgeschneiderten KI-Blueprint und verfeinern ihn durch iterative Diskussionen mit Ihnen, um sicherzustellen, dass er perfekt auf Ihre Vision und Ziele abgestimmt ist.",
      },
    },
    c: {
      question: {
        en: "What type of AI services and solutions do you provide?",
        es: "¿Qué tipo de servicios y soluciones de IA proporciona?",
        de: "Welche Arten von KI-Dienstleistungen und -Lösungen bieten Sie an?",
      },
      answer: {
        en: "We offer a comprehensive suite of AI services, including:Custom strategy and blueprint development with industry-specific experts Full-scale AI solution development Matching you with specialized AI teams or experts experienced in your industry Experienced project management for all AI initiatives AI talent acquisition services to help you build your in-house team",
        es: "Ofrecemos un conjunto completo de servicios de IA, que incluyen: Desarrollo de estrategias y planes personalizados con expertos específicos de la industria Desarrollo de soluciones de IA a gran escala Emparejamiento con equipos o expertos especializados en IA con experiencia en su industria Gestión de proyectos experimentada para todas las iniciativas de IA Servicios de adquisición de talento de IA para ayudarlo a construir su equipo interno",
        de: "Wir bieten ein umfassendes Angebot an KI-Dienstleistungen, darunter: Entwicklung maßgeschneiderter Strategien und Blueprints mit branchenspezifischen Experten Entwicklung von KI-Gesamtlösungen Vermittlung von spezialisierten KI-Teams oder Experten mit Erfahrung in Ihrer Branche Erfahrene Projektmanagement für alle KI-Initiativen KI-Talentakquise-Dienstleistungen, um Ihnen beim Aufbau Ihres internen Teams zu helfen",
      },
    },
    d: {
      question: {
        en: "Do you cater to businesses of all sizes?",
        es: "¿Atiende a empresas de todos los tamaños?",
        de: "Kümmern Sie sich um Unternehmen aller Größen?",
      },
      answer: {
        en: "Absolutely. Our services are designed to accommodate businesses of all scales, from small startups to large enterprises, as well as individuals looking to leverage AI in their projects or careers.",
        es: "Absolutamente. Nuestros servicios están diseñados para acomodar a empresas de todos los tamaños, desde pequeñas startups hasta grandes empresas, así como a individuos que buscan aprovechar la IA en sus proyectos o carreras.",
        de: "Absolut. Unsere Dienstleistungen sind darauf ausgelegt, Unternehmen aller Größen zu unterstützen, von kleinen Startups bis hin zu großen Unternehmen sowie Einzelpersonen, die KI in ihren Projekten oder Karrieren nutzen möchten.",
      },
    },
    e: {
      question: {
        en: "If I have a well-defined AI project in mind, do you offer development-only services?",
        es: "Si tengo un proyecto de IA bien definido en mente, ¿ofrecen servicios solo de desarrollo?",
        de: "Wenn ich ein klar definiertes KI-Projekt im Sinn habe, bieten Sie nur Entwicklungsleistungen an?",
      },
      answer: {
        en: "Yes, we offer development-only services for well-defined projects. Our team can develop your solution directly, or we can connect you with specialized teams that have extensive experience in your specific industry or use case.",
        es: "Sí, ofrecemos servicios solo de desarrollo para proyectos bien definidos. Nuestro equipo puede desarrollar su solución directamente, o podemos conectarlo con equipos especializados que tengan amplia experiencia en su industria o caso de uso específico.",
        de: "Ja, wir bieten ausschließlich Entwicklungsleistungen für klar definierte Projekte an. Unser Team kann Ihre Lösung direkt entwickeln, oder wir können Sie mit spezialisierten Teams verbinden, die über umfangreiche Erfahrung in Ihrer Branche oder Ihrem Anwendungsfall verfügen.",
      },
    },
    f: {
      question: {
        en: "How can Millerio help us fill an open position for an AI expert in our company?",
        es: "¿Cómo puede Millerio ayudarnos a llenar una posición abierta para un experto en IA en nuestra empresa?",
        de: "Wie kann Millerio uns dabei helfen, eine offene Stelle für einen KI-Experten in unserem Unternehmen zu besetzen?",
      },
      answer: {
        en: "We streamline your hiring process by pre-screening and testing AI experts who match your specific requirements. Our thorough vetting ensures that only the most qualified candidates, tailored to your unique needs, are presented to you, saving you time and resources in the initial stages of recruitment.",
        es: "Agilizamos su proceso de contratación al preseleccionar y evaluar a expertos en IA que coincidan con sus requisitos específicos. Nuestra evaluación exhaustiva garantiza que solo los candidatos más calificados, adaptados a sus necesidades únicas, le sean presentados, ahorrándole tiempo y recursos en las etapas iniciales de reclutamiento.",
        de: "Wir optimieren Ihren Einstellungsprozess durch Vorauswahl und Prüfung von KI-Experten, die Ihren spezifischen Anforderungen entsprechen. Unsere gründliche Überprüfung stellt sicher, dass Ihnen nur die qualifiziertesten Kandidaten, die auf Ihre individuellen Bedürfnisse zugeschnitten sind, vorgestellt werden, wodurch Sie in den ersten Phasen der Einstellung Zeit und Ressourcen sparen.",
      },
    },
    g: {
      question: {
        en: "Can you source AI specialists or teams with specific expertise from particular geographic regions?",
        es: "¿Puede obtener especialistas o equipos de IA con experiencia específica de regiones geográficas particulares?",
        de: "Können Sie KI-Spezialisten oder Teams mit spezifischer Expertise aus bestimmten geografischen Regionen beschaffen?",
      },
      answer: {
        en: "Our extensive network spans all continents, with specialists in various AI technologies and industries. We can certainly locate and connect you with verified experts or teams from your desired region who possess the specific expertise you require.",
        es: "Nuestra extensa red se extiende por todos los continentes, con especialistas en diversas tecnologías e industrias de IA. Podemos localizar y conectarlo con expertos o equipos verificados de la región que desee, que posean la experiencia específica que necesita.",
        de: "Unser umfangreiches Netzwerk erstreckt sich über alle Kontinente, mit Spezialisten in verschiedenen KI-Technologien und Branchen. Wir können Sie sicherlich mit verifizierten Experten oder Teams aus Ihrer gewünschten Region verbinden, die über das spezifische Fachwissen verfügen, das Sie benötigen.",
      },
    },
    h: {
      question: {
        en: "Which industries do you serve, and do you have any sector-specific expertise? ",
        es: "¿A qué industrias atiende y tiene alguna experiencia específica en algún sector?",
        de: "Welche Branchen bedienen Sie, und verfügen Sie über eine branchenspezifische Expertise?",
      },
      answer: {
        en: "We serve all industries and sectors. Our vast network allows us to connect you with experts who have direct, relevant experience in your specific sector, ensuring you receive specialized knowledge and insights.",
        es: "Servimos a todas las industrias y sectores. Nuestra vasta red nos permite conectarlo con expertos que tienen experiencia directa y relevante en su sector específico, asegurándole que reciba conocimientos e ideas especializados.",
        de: "Wir bedienen alle Branchen und Sektoren. Unser umfangreiches Netzwerk ermöglicht es uns, Sie mit Experten zu verbinden, die direkte, relevante Erfahrung in Ihrem spezifischen Sektor haben, um sicherzustellen, dass Sie spezialisiertes Wissen und Erkenntnisse erhalten.",
      },
    },
    i: {
      question: {
        en: "What AI technologies does your team specialize in? ",
        es: "¿En qué tecnologías de IA se especializa su equipo?",
        de: "Auf welche KI-Technologien ist Ihr Team spezialisiert?",
      },
      answer: {
        en: "Our network encompasses experts in all AI technologies. This allows us to cover any possible use case or requirement, ensuring we can match you with the right expertise for your specific needs.",
        es: "Nuestra red abarca expertos en todas las tecnologías de IA. Esto nos permite cubrir cualquier caso de uso o requisito posible, asegurándonos de que podamos emparejarlo con la experiencia adecuada para sus necesidades específicas.",
        de: "Unser Netzwerk umfasst Experten in allen KI-Technologien. Dadurch können wir jeden möglichen Anwendungsfall oder jede Anforderung abdecken und sicherstellen, dass wir Sie mit der richtigen Expertise für Ihre spezifischen Bedürfnisse zusammenbringen können.",
      },
    },
    j: {
      question: {
        en: "I'm an AI professional or represent an AI agency. How can I collaborate with your company on projects? ",
        es: "Soy un profesional de la IA o represento a una agencia de IA. ¿Cómo puedo colaborar con su empresa en proyectos?",
        de: "Ich bin ein KI-Experte oder vertrete eine KI-Agentur. Wie kann ich mit Ihrem Unternehmen bei Projekten zusammenarbeiten?",
      },
      answer: {
        en: "We're always eager to expand our network with skilled AI professionals and agencies. This allows us to source a wider range of projects and opportunities. To join our network and explore collaboration possibilities, please use [this link] to register. We look forward to potentially working together!",
        es: "Siempre estamos ansiosos por expandir nuestra red con profesionales de IA calificados y agencias. Esto nos permite obtener una gama más amplia de proyectos y oportunidades. Para unirse a nuestra red y explorar posibilidades de colaboración, utilice [este enlace] para registrarse. ¡Esperamos trabajar juntos!",
        de: "Wir sind stets bestrebt, unser Netzwerk mit qualifizierten KI-Experten und -Agenturen zu erweitern. Dies ermöglicht es uns, ein breiteres Spektrum an Projekten und Möglichkeiten zu erschließen. Um unserem Netzwerk beizutreten und Kooperationsmöglichkeiten zu erkunden, nutzen Sie bitte [diesen Link], um sich zu registrieren. Wir freuen uns auf eine mögliche Zusammenarbeit!",
      },
    },
  },
};
