import { NumberCardType } from "../Components/NumberCard/NumberCard";

export const dataFacts: NumberCardType[] = [
  {
    text: "ai.dataFacts.cards.a",
    number: 87,
  },
  {
    text: "ai.dataFacts.cards.b",
    number: 83,
  },
  {
    text: "ai.dataFacts.cards.c",
    number: 80,
  },
  {
    text: "ai.dataFacts.cards.d",
    number: 133,
    symbol: "Million",
  },
];
