export const aiBusinessSectorsTranslations = {
  agricultureNaturalResources: {
    en: "Agriculture and Natural Resources",
    es: "Agricultura y Recursos Naturales",
    de: "Landwirtschaft und natürliche Ressourcen",
  },
  educationEdTech: {
    en: "Education / EdTech",
    es: "Educación / EdTech",
    de: "Bildung / EdTech",
  },
  artsMediaEntertainment: {
    en: "Arts, Media, and Entertainment",
    es: "Artes, Medios y Entretenimiento",
    de: "Kunst, Medien und Unterhaltung",
  },
  healthcarePharmaceuticalsBiotech: {
    en: "Healthcare / Pharmaceuticals / Biotech",
    es: "Salud / Farmacéutica / Biotecnología",
    de: "Gesundheitswesen / Pharma / Biotechnologie",
  },
  retailEcommerce: {
    en: "Retail / E-commerce",
    es: "Retail / E-commerce",
    de: "Einzelhandel / E-Commerce",
  },
  bankingFinanceFinTech: {
    en: "Banking / Finance / FinTech",
    es: "Banca / Finanzas / FinTech",
    de: "Banken / Finanzen / FinTech",
  },
  supplyChainLogistics: {
    en: "Supply chain / Logistics",
    es: "Cadena de suministro / Logística",
    de: "Lieferkette / Logistik",
  },
  travel: {
    en: "Travel",
    es: "Viajes",
    de: "Reisen",
  },
  realEstateConstruction: {
    en: "Real estate / Construction",
    es: "Bienes raíces / Construcción",
    de: "Immobilien / Bauwesen",
  },
  manufacturingIndustrial: {
    en: "Manufacturing / Industrial",
    es: "Manufactura / Industrial",
    de: "Fertigung / Industrie",
  },
  automotive: {
    en: "Automotive",
    es: "Automotriz",
    de: "Automobil",
  },
  fashion: {
    en: "Fashion",
    es: "Moda",
    de: "Mode",
  },
  itTechnology: {
    en: "IT / Technology",
    es: "TI / Tecnología",
    de: "IT / Technologie",
  },
};
