export const aiDataFactsTranslations = {
  headline: {
    en: "We are in the race to embrace AI, but navigating this new field alone can be challenging",
    es: "Estamos en la carrera por adoptar la IA, pero navegar solo en este nuevo campo puede ser desafiante.",
    de: "Wir sind im Rennen, KI anzunehmen, aber alleine in diesem neuen Bereich zu navigieren kann herausfordernd sein.",
  },
  cards: {
    a: {
      en: "of companies believe AI will allow them to obtain a competitive advantage.",
      es: "de las empresas creen que la IA les permitirá obtener una ventaja competitiva.",
      de: "von Unternehmen glauben, dass KI ihnen einen Wettbewerbsvorteil verschaffen wird.",
    },
    b: {
      en: "of executives believe AI is a strategic priority for their businesses today.",
      es: "de los ejecutivos creen que la IA es una prioridad estratégica para sus negocios hoy en día.",
      de: "von Führungskräften glauben, dass KI heute eine strategische Priorität für ihre Unternehmen ist.",
    },
    c: {
      en: "of Companies will Adopt Intelligent Automation by 2025.",
      es: "de las empresas adoptarán automatización inteligente para 2025.",
      de: "von Unternehmen werden bis 2025 intelligente Automatisierung annehmen.",
    },
    d: {
      en: "new jobs AI is estimated to create by 2030.",
      es: "nuevos empleos se estima que creará la IA para 2030.",
      de: "neue Arbeitsplätze wird KI voraussichtlich bis 2030 schaffen.",
    },
  },
};