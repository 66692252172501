export const aiSubFieldsTranslations = {
  strategyConsultancy: {
    en: "AI Strategy / Consultancy",
    es: "Estrategia / Consultoría de IA",
    de: "KI-Strategie / Beratung",
  },
  solutions: {
    en: "AI Solutions",
    es: "Soluciones de IA",
    de: "KI-Lösungen",
  },
  machineLearning: {
    en: "Machine learning",
    es: "Aprendizaje automático",
    de: "Maschinelles Lernen",
  },
  neuralNetworks: {
    en: "Neural Networks",
    es: "Redes neuronales",
    de: "Neuronale Netzwerke",
  },
  computerVision: {
    en: "Computer Vision",
    es: "Visión por computadora",
    de: "Computer Vision",
  },
  deepLearning: {
    en: "Deep Learning",
    es: "Aprendizaje profundo",
    de: "Deep Learning",
  },
  data: {
    en: "Data Engineering / Science",
    es: "Ingeniería / Ciencia de Datos",
    de: "Datenengineering / Datenwissenschaft",
  },
};
