import { Accordion } from "../../Components/Accordion/Accordion";
import { Button } from "../../Components/Button/Button";
import { useTranslate } from "../../hooks/useTranslation";
import { useScroll } from "../../Providers/ScrollContextProvider";
import { StepForm } from "../../StepForm/StepForm";
import { stepFormDataAI } from "../../StepForm/stepFormData/ai/formData-ai";
import { aiFaqData, faqDataType } from "./faqData";

type FAQPropsType = {
  className?: string;
  data: faqDataType;
};

export const FAQ = ({ className, data }: FAQPropsType) => {
  const tranlsate = useTranslate();
  const { sectionRefs, scrollToSection } = useScroll();

  return (
    <div ref={sectionRefs.qaSection} className={`${className}`}>
      <div className="flex flex-col">
        {/* <div className="mx-auto" >
          <StepForm
            formId="ai-faq"
            stepFormData={stepFormDataAI}
            className="h-fit max-h-[500px] bg-background md:w-[500px]"
            firstStep="questions-start"
          />
        </div> */}
        <Accordion className="max-w-[800px] mx-auto" items={data.items} />
        <p className="body-2  mt-20 font-bold drop-shadow-md	text-accent opacity-70 text-center">
          If you have any other questions, you can book a free discovery call with us
        </p>
        <Button
          label={"Book Discovery Call"}
          highlight
          className="w-fit mx-auto mt-10"
          action={() => scrollToSection("callToAction")}
        />
      </div>
    </div>
  );
};
