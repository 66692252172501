import { NumberCard, NumberCardType } from "../../Components/NumberCard/NumberCard";
import { useTranslate } from "../../hooks/useTranslation";

type NumberCardsListPropsType = {
  className?: string;
  headline?: string;
  text?: string;
  numberCardsList: NumberCardType[];
};

export const NumberCardsList = ({
  className,
  headline,
  text,
  numberCardsList,
}: NumberCardsListPropsType) => {
  const translate = useTranslate();

  return (
    <div className={`${className}`}>
      <div className="gap-5 md:gap-10 relative grid grid-cols-6 xl:grid-cols-12">
        {numberCardsList.map((numberCard) => {
          return (
            <NumberCard
              key={numberCard.text}
              number={numberCard.number}
              className="col-span-6 xsm:col-span-3"
              text={translate(numberCard.text)}
              symbol={numberCard.symbol}
            />
          );
        })}
      </div>
    </div>
  );
};
