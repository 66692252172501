export const aiServicesTranslations = {
  headline: {
    en: "Every journey begins with a single step.",
    es: "Todo viaje comienza con un solo paso.",
    de: "Jede Reise beginnt mit einem einzigen Schritt.",
  },
  solutions: {
    headline: {
      en: "AI Business Strategy & Solutions",
      es: "Soluciones de Negocios y Estrategia de IA",
      de: "KI-Geschäftslösungen & Strategie",
    },
    description: {
      en: "Whether you're looking to revolutionize your operations or stay ahead of the competition, let us help you make the right AI decisions and find the perfect solutions for your business needs.",
    },
    tag: {
      en: "",
    },
    a: {
      headline: {
        en: "AI Consulting & Strategy",
        es: "Consultoría de estrategia",
        de: "KI-Strategieberatung",
      },
      text: {
        en: "",
        es: "",
        de: "",
      },
      listOfItems: {
        a: {
          text: {
            en: "Discover opportunities",
            es: "Encuentra oportunidades",
            de: "Entdecken Sie Chancen",
          },
        },
        b: {
          text: {
            en: "Identify high-impact areas",
            es: "Identifica áreas de alto impacto",
            de: "Identifizieren Sie Bereiche mit hohem Einfluss",
          },
        },
        c: {
          text: {
            en: "Validate winning use cases",
            es: "Validar Casos de Uso Ganadores",
            de: "Gewinnbringende Anwendungsfälle validieren",
          },
        },
        d: {
          text: {
            en: "Craft strategic blueprints",
            es: "Diseña planes estratégicos",
            de: "Strategische Blaupausen entwerfen",
          },
        },
        e: {
          text: {
            en: "Accelerate with Proof of Concept",
            es: "Acelerar con Prueba de Concepto",
            de: "Beschleunigen Sie mit Proof of Concept",
          },
        },
      },
    },
    b: {
      headline: {
        en: "Tailored AI solutions",
        es: "Soluciones de IA a medida",
        de: "Maßgeschneiderte KI-Lösungen",
      },
      text: {
        en: "",
        es: "",
        de: "",
      },
      listOfItems: {
        a: {
          text: {
            en: "Automate workflows",
            es: "Automatiza flujos de trabajo",
            de: "Arbeitsabläufe automatisieren",
          },
        },
        b: {
          text: {
            en: "Reduce costs",
            es: "Reduce costos",
            de: "Kosten senken",
          },
        },
        c: {
          text: {
            en: "Uncover new revenue streams",
            es: "Descubre nuevas fuentes de ingresos",
            de: "Neue Einnahmequellen erschließen",
          },
        },
        d: {
          text: {
            en: "Boost sales",
            es: "Aumenta las ventas",
            de: "Umsatz steigern",
          },
        },
        e: {
          text: {
            en: "Tailored to unique needs",
            es: "A medida de tus necesidades",
            de: "Zugeschnitten auf individuelle Bedürfnisse",
          },
        },
        f: {
          text: {
            en: "Seamless integration",
            es: "Integración sin interrupciones",
            de: "Nahtlose Integration",
          },
        },
      },
    },
    c: {
      headline: {
        en: "Global AI Network",
        es: "Red Global de IA",
        de: "Globales KI-Netzwerk",
      },
      text: {
        en: "",
        es: "",
        de: "",
      },
      listOfItems: {
        a: {
          text: {
            en: "Pre-vetted experts",
            es: "Expertos preseleccionados",
            de: "Vorausgewählte Experten",
          },
        },
        b: {
          text: {
            en: "Dedicated teams",
            es: "Equipos dedicados",
            de: "Engagierte Teams",
          },
        },
        c: {
          text: {
            en: "Talent access",
            es: "Acceso a talento",
            de: "Talentzugang",
          },
        },
        d: {
          text: {
            en: "Business partners",
            es: "Socios comerciales",
            de: "Geschäftspartner",
          },
        },
        e: {
          text: {
            en: "Niche specialists",
            es: "Especialistas en nichos",
            de: "Nischenspezialisten",
          },
        },
      },
    },
    d: {
      headline: {
        en: "AI Product Development",
        es: "Desarrollo de Productos de IA",
        de: "KI-Produktentwicklung",
      },
      text: {
        en: "",
        es: "",
        de: "",
      },
      listOfItems: {
        a: {
          text: {
            en: "Design and development",
            es: "Diseño y desarrollo",
            de: "Design und Entwicklung",
          },
        },
        b: {
          text: {
            en: "Consumer-facing applications",
            es: "Aplicaciones para el consumidor",
            de: "Kundenorientierte Anwendungen",
          },
        },
        c: {
          text: {
            en: "Enterprise tools",
            es: "Herramientas empresariales",
            de: "Unternehmenswerkzeuge",
          },
        },
        d: {
          text: {
            en: "Innovative AI products",
            es: "Productos de IA innovadores",
            de: "Innovative KI-Produkte",
          },
        },
        e: {
          text: {
            en: "Agile development",
            es: "Desarrollo ágil",
            de: "Agile Entwicklung",
          },
        },
      },
    },
  },
  development: {
    headline: {
      en: "AI Development & Implementation",
      es: "Desarrollo e Implementación de IA",
      de: "KI-Entwicklung und -Implementierung",
    },
    a: {
      headline: {
        en: "AI Model Development",
        es: "Desarrollo de Modelos de IA",
        de: "KI-Modellentwicklung",
      },
      text: {
        en: "",
        es: "",
        de: "",
      },
      listOfItems: {
        a: {
          text: {
            en: "Machine learning models",
            es: "Modelos de aprendizaje automático",
            de: "Maschinelle Lernmodelle",
          },
        },
        b: {
          text: {
            en: "Classification",
            es: "Clasificación",
            de: "Klassifikation",
          },
        },
        c: {
          text: {
            en: "Regression",
            es: "Regresión",
            de: "Regression",
          },
        },
        d: {
          text: {
            en: "Clustering",
            es: "Agrupamiento",
            de: "Clustering",
          },
        },
        e: {
          text: {
            en: "Deep learning Models",
            es: "Modelos de aprendizaje profundo",
            de: "Deep-Learning-Modelle",
          },
        },
      },
    },
    b: {
      headline: {
        en: "AI Implementation",
        es: "Implementación de IA",
        de: "KI-Implementierung",
      },
      text: {
        en: "",
        es: "",
        de: "",
      },
      listOfItems: {
        a: {
          text: {
            en: "Integration into existing systems",
            es: "Integración en sistemas existentes",
            de: "Integration in bestehende Systeme",
          },
        },
        b: {
          text: {
            en: "Focus on scalability",
            es: "Enfoque en escalabilidad",
            de: "Fokus auf Skalierbarkeit",
          },
        },
        c: {
          text: {
            en: "Ongoing support",
            es: "Soporte continuo",
            de: "Laufende Unterstützung",
          },
        },
        d: {
          text: {
            en: "Minimal disruption to operations",
            es: "Disrupción mínima en las operaciones",
            de: "Minimale Betriebsunterbrechungen",
          },
        },
        e: {
          text: {
            en: "Measurable results",
            es: "Resultados medibles",
            de: "Messbare Ergebnisse",
          },
        },
      },
    },
    c: {
      headline: {
        en: "End-to-End AI",
        es: "IA de Extremo a Extremo",
        de: "End-to-End-KI",
      },
      text: {
        en: "",
        es: "",
        de: "",
      },
      listOfItems: {
        a: {
          text: {
            en: "Language Understanding & NLP",
            es: "Comprensión del Lenguaje y PLN",
            de: "Sprachverständnis und NLP",
          },
        },
        b: {
          text: {
            en: "Image Recognition & Vision AI",
            es: "Reconocimiento de Imágenes y Visión IA",
            de: "Bilderkennung und Vision-KI",
          },
        },
        c: {
          text: {
            en: "Smart Decision Systems",
            es: "Sistemas de Decisión Inteligentes",
            de: "Intelligente Entscheidungssysteme",
          },
        },
        d: {
          text: {
            en: "Classification & Prediction",
            es: "Clasificación y Predicción",
            de: "Klassifikation und Vorhersage",
          },
        },
        e: {
          text: {
            en: "AI for Robotics & Automation",
            es: "IA para Robótica y Automatización",
            de: "KI für Robotik und Automatisierung",
          },
        },
      },
    },
  },
  dataInfrastructure: {
    headline: {
      en: "Data & Infrastructure",
      es: "Datos e Infraestructura",
      de: "Daten und Infrastruktur",
    },
    a: {
      headline: {
        en: "Data Strategy & Analysis",
        es: "Estrategia y Análisis de Datos",
        de: "Datenstrategie & Analyse",
      },
      text: {
        en: "",
        es: "",
        de: "",
      },
      listOfItems: {
        a: {
          text: {
            en: "Comprehensive Data strategies",
            es: "Estrategias de datos integrales",
            de: "Umfassende Datenstrategien",
          },
        },
        b: {
          text: {
            en: "Identify data sources",
            es: "Identificar fuentes de datos",
            de: "Datenquellen identifizieren",
          },
        },
        c: {
          text: {
            en: "Data governance practices",
            es: "Prácticas de gobernanza de datos",
            de: "Datenverwaltungspraktiken",
          },
        },
        d: {
          text: {
            en: "Data compliance",
            es: "Cumplimiento de datos",
            de: "Datenkonformität",
          },
        },
        e: {
          text: {
            en: "In-depth data analysis",
            es: "Análisis de datos profundo",
            de: "Detaillierte Datenanalyse",
          },
        },
      },
    },
    b: {
      headline: {
        en: "AI Infrastructure & Tools",
        es: "Infraestructura y Herramientas",
        de: "KI-Infrastruktur & Tools",
      },
      text: {
        en: "",
        es: "",
        de: "",
      },
      listOfItems: {
        a: {
          text: {
            en: "Set up AI infrastructure",
            es: "Configurar infraestructura de IA",
            de: "KI-Infrastruktur einrichten",
          },
        },
        b: {
          text: {
            en: "Manage AI infrastructure",
            es: "Gestionar infraestructura de IA",
            de: "KI-Infrastruktur verwalten",
          },
        },
        c: {
          text: {
            en: "Access the best tools",
            es: "Acceder a las mejores herramientas",
            de: "Zugang zu den besten Tools",
          },
        },
        d: {
          text: {
            en: "Scalability & reliability",
            es: "Escalabilidad y fiabilidad",
            de: "Skalierbarkeit & Zuverlässigkeit",
          },
        },
        e: {
          text: {
            en: "Security & compliance",
            es: "Seguridad y cumplimiento",
            de: "Sicherheit & Konformität",
          },
        },
      },
    },
    c: {
      headline: {
        en: "Ethical AI & Governance",
        es: "IA Ética y Gobernanza",
        de: "Ethische KI & Governance",
      },
      text: {
        en: "",
        es: "",
        de: "",
      },
      listOfItems: {
        a: {
          text: {
            en: "Ethical AI use frameworks",
            es: "Marcos de uso ético de IA",
            de: "Rahmen für ethische KI-Nutzung",
          },
        },
        b: {
          text: {
            en: "Compliance with regulations",
            es: "Cumplimiento con las regulaciones",
            de: "Einhaltung von Vorschriften",
          },
        },
        c: {
          text: {
            en: "Evaluate AI systems",
            es: "Evaluar sistemas de IA",
            de: "KI-Systeme bewerten",
          },
        },
        d: {
          text: {
            en: "Training and resources",
            es: "Capacitación y recursos",
            de: "Schulungen und Ressourcen",
          },
        },
      },
    },
  },
};
