import React, { useEffect, useState } from "react";
import { Card } from "../../Components/Card/Card";
import { useTranslate } from "../../hooks/useTranslation";
import {
  useCasesDefaults,
  findUseCaseData,
  industriesId,
  getIndustryLabel,
  industryType,
} from "./experienceTextCardsData";
import { Button } from "../../Components/Button/Button";
import { InputField } from "../../Components/InputField/InputField";
import { useScrollToSections } from "../../hooks/useScrollToSections";
import {
  ScrollProvider,
  useScroll,
} from "../../Providers/ScrollContextProvider";
import Dropdown, { DropdownOption } from "../../Components/Dropdown/Dropdown";
import { Slider } from "../../Components/Slider/Slider";

// Utility function
export const searchItems = (input: string, items: string[]): string[] => {
  const lowercasedInput = input.toLowerCase();
  return items.filter((item) => item.toLowerCase().includes(lowercasedInput));
};

export type TextCardsType = {
  headline: string;
  text: string;
  tag?: string;
};

export type TextCardsPropsType = {
  defaultUseCases?: TextCardsType[];
  className?: string;
};

export const UseCases = ({
  defaultUseCases = useCasesDefaults,
  className,
}: TextCardsPropsType) => {
  const [selectedIndustry, setSelectedIndustry] = useState<industryType>();
  const [useCases, setUseCases] = useState(defaultUseCases);
  const [filteredIndustryButtons, setFilteredIndustryButtons] =
    useState<string[]>(industriesId);
  const [searchTerm, setSearchTerm] = useState("");

  const translate = useTranslate();

  const { sectionRefs } = useScroll();

  useEffect(() => {
    if (selectedIndustry) {
      const foundUseCases = findUseCaseData(selectedIndustry);
      setUseCases(foundUseCases);
    }
  }, [selectedIndustry]);

  useEffect(() => {
    const filteredIndustries = searchItems(searchTerm, industriesId); //TODO - Fix this, it doesn't work because it's searching with ids, not labels.
    setFilteredIndustryButtons(filteredIndustries);
  }, [searchTerm]);

  const industriesDropdownOptions = industriesId.map((industry) => {
    const label = translate(getIndustryLabel(industry as industryType));
    return {
      label,
      value: industry,
    };
  });

  const mobileSlides = useCases.map((item, index) => (
    <Card key={index} className="flex flex-col gap-2 mb-4 col-span-3 relative">
      {item.tag && (
        <p className="px-2 py-1 text-secondary bg-background rounded-lg absolute -bottom-3 shadow-lg right-4">
          {translate(item.tag)}
        </p>
      )}
      <h3 className="heading-3 text-accent opacity-80">
        {translate(item.headline)}
      </h3>
      <p className="body-1">{translate(item.text)}</p>
    </Card>
  ));

  return (
    <div ref={sectionRefs.useCases} className={`${className}`}>
      <div className="section-bg p-10 flex flex-col gap-10 justify-between">
        <Dropdown
          placeholder={translate("ai.searchIndustryLabel")}
          options={industriesDropdownOptions}
          className="w-full max-w-[400px] mx-auto"
          onSelect={(option) =>
            setSelectedIndustry(option.value as industryType)
          }
        />
        <div className="flex hidden flex-col h-fit lg:grid grid-cols-6 gap-5 gap-10">
          {useCases.map((example, index) => (
            <Card
              key={index}
              className="flex flex-col gap-2  col-span-3 relative"
            >
              {example.tag && (
                <p className="px-2 py-1 text-secondary bg-background rounded-lg absolute -bottom-3 shadow-lg right-4">
                  {translate(example.tag)}
                </p>
              )}
              <h3 className="heading-3 text-accent opacity-80">
                {translate(example.headline)}
              </h3>
              <p className="body-1">{translate(example.text)}</p>
            </Card>
          ))}
        </div>
      </div>
      <div className="lg:hidden mt-10 full-width-section">
        <Slider
          slides={mobileSlides}
          className="w-full h-full"
          slideClassName="flex flex-col px-10"
        />
      </div>
    </div>
  );
};
