import { carouselData } from "./carouselConfig";
import CarouselItem from "./CarouselItem";

import { getCountrySvg } from "../../Utils/countriesSvgs/getCountrySvg";

import "./Carousel.scss";
import { getThemeColor } from "../../Utils/tailwind/tailwindUtils";

export const Carousel = ({ className }: { className?: string }) => {
  const carouselItem = carouselData.map((item, index) => {
    return (
      <CarouselItem
        key={item.id + index}
        text={item.text}
        image={getCountrySvg(item.id, getThemeColor("blue"), "200px", "200px")}
      />
    );
  });
  return (
    <div className={`carousel-wrapper ${className} `}>
      <div className="carousel-track">{carouselItem}</div>
      <div className="carousel-track">{carouselItem}</div>
    </div>
  );
};
