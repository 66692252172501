import React, { useState, useEffect, useRef } from "react";
import { ChevronDown, Search } from "lucide-react";
import { getThemeColor } from "../../Utils/tailwind/tailwindUtils";

export type DropdownOption = {
  value: string;
  label: string;
};

type DropdownProps = {
  options: DropdownOption[];
  onSelect: (option: DropdownOption) => void;
  placeholder?: string;
  className?: string;
};

const Dropdown: React.FC<DropdownProps> = ({
  options,
  onSelect,
  placeholder = "Select an option",
  className = "",
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedOption, setSelectedOption] = useState<DropdownOption | null>(
    null
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  const clickTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        // Introduce a slight delay before closing the dropdown
        clickTimeoutRef.current = setTimeout(() => {
          setIsOpen(false);
          setSearchTerm("");
        }, 50);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      if (clickTimeoutRef.current) {
        clearTimeout(clickTimeoutRef.current);
      }
    };
  }, []);

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionSelect = (option: DropdownOption) => {
    setSelectedOption(option);
    onSelect(option);
    setIsOpen(false);
    setSearchTerm("");
  };

  return (
    <div ref={dropdownRef} className={`relative ${className}`}>
      <button
        className={`bg-background rounded-md shadow-lg opacity-80 active:shadow-sm active:scale-[0.99] hover:opacity-100 w-full flex justify-between items-center p-4 ${
          isOpen ? "rounded-b-none" : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span
          className={`body-2 text-secondary font-medium ${
            selectedOption ? "" : ""
          }`}
        >
          {selectedOption ? selectedOption.label : placeholder}
        </span>
        <ChevronDown
          color={getThemeColor("secondary")}
          className={`ml-2 h-5 w-5 transition-transform ${
            isOpen ? "rotate-180" : ""
          } `}
        />
      </button>
      {isOpen && (
        <div className="absolute bg-background z-10 overflow-hidden w-full shadow-smooth rounded-b-2xl border-grey border border-t-none ">
          <div className="relative border border-grey m-2 rounded-full bg-dark overflow-hidden">
            <label className="">
              <Search
                color="gray"
                className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 events-none"
              />
              <input
                autoFocus
                type="text"
                className="body-1 w-full pl-10 pr-4 py-2  bg-transparent text-gray focus:outline-none"
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </label>
          </div>
          <ul className="max-h-60 overflow-auto py-1">
            {filteredOptions.map((option) => (
              <li
                key={option.value}
                className="px-4 py-2 cursor-pointer text-white hover:text-secondary"
                onClick={() => {
                  handleOptionSelect(option);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
