import { useTranslate } from "../../hooks/useTranslation";
import { NumberCardsList } from "../../modules/NumberCardsList/NumberCardsList";
import { UseCases } from "../../modules/UseCases/UseCases";
import { Hero } from "../../modules/Hero/Hero";
import { PageWrapper } from "../PageWrapper";
import { CallToAction } from "../../modules/CallToAction/CallToAction";
import { Carousel } from "../../Components/Carousel/Carousel";
import { FAQ } from "../../modules/FAQ/FAQ";
import { stepFormDataAI } from "../../StepForm/stepFormData/ai/formData-ai";
import { TabsSection } from "../../modules/TabsSection/TabsSection";
import {
  servicesDevelopmentData,
  servicesInfrastructureData,
  servicesSolutionsData,
} from "../../PageData/services";
import { dataFacts } from "../../PageData/dataFacts";
import { SectionWrapper } from "../SectionWrapper";
import { aiFaqData } from "../../modules/FAQ/faqData";
import { SafeTextRenderer } from "../../Utils/SafeTextRenderer";
import PhiloImg from "../../Assets/phili.png";
import { Card } from "../../Components/Card/Card";
import { TextQuote } from "../../modules/TextQuote/TextQuote";

export const AIPage = () => {
  const translate = useTranslate();

  return (
    <PageWrapper>
      <Hero
        first={translate("ai.hero.first")}
        last={translate("ai.hero.last")}
        strings={[
          translate("ai.hero.strings.a"),
          translate("ai.hero.strings.b"),
          translate("ai.hero.strings.c"),
          translate("ai.hero.strings.d"),
        ]}
        stepFormData={stepFormDataAI}
        className={`max-w-[1000px] mx-auto mt-20`}
        text={translate("ai.hero.text")}
      />
      <p className=" body-2 mt-20 font-bold drop-shadow-md	text-accent text-center mb-10">
        Trusted Partner of 60+ Teams & Professionals Worldwide
      </p>
      <Carousel className="full-width-section" />
      <SectionWrapper
        description={translate("ai.services.solutions.description")}
        headline={translate("ai.services.solutions.headline")}
      >
        <TabsSection sections={servicesSolutionsData} />
      </SectionWrapper>
      <SectionWrapper headline={translate("ai.dataFacts.headline")}>
        <NumberCardsList numberCardsList={dataFacts} />
      </SectionWrapper>
      {/* <SectionWrapper>
        <TabsSection
          right
          headline="ai.services.development.headline"
          sections={servicesDevelopmentData}
        />
      </SectionWrapper>
      <SectionWrapper>
        <TabsSection
          headline="ai.services.dataInfrastructure.headline"
          sections={servicesInfrastructureData}
        />
      </SectionWrapper> */}
      <SectionWrapper headline={translate("ai.experience.headline")}>
        <UseCases />
      </SectionWrapper>
      <SectionWrapper headline={translate("ai.callToAction.headline")}>
        <CallToAction />
      </SectionWrapper>
      <SectionWrapper headline="The Millerio Mission">
        <TextQuote
          bgImage={PhiloImg}
          text={translate("ai.missionText")}
          quote={translate("ai.missionQuote")}
          author={"Heraclitus"}
        />
      </SectionWrapper>

      <SectionWrapper headline={translate(aiFaqData.headline)}>
        <FAQ data={aiFaqData} />
      </SectionWrapper>
    </PageWrapper>
  );
};
