import { themeColors } from "../../Utils/tailwind/tailwindColorsConfig";

export const getParticleConfig = (mainColor: string, contrast: string) => {
  return {
    background: {
      color: {
        value: mainColor,
      },
    },
    fpsLimit: 60,
    interactivity: {
      events: {
        onClick: {
          enable: true,
          mode: "push",
        },
        resize: true,
      },
      modes: {
        push: {
          quantity: 1,
        },
        attract: {
          duration: 10,
        },
      },
    },
    particles: {
      color: {
        value: contrast,
      },
      links: {
        color: contrast,
        distance: 145,
        enable: true,
        opacity: 0.15,
        width: 1,
      },
      move: {
        direction: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: true,
        speed: 1,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 1200,
        },
        value: 60,
      },
      opacity: {
        value: 0.15,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 4 },
      },
    },
    detectRetina: true,
  };
};
