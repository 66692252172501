export const themeColors = {
  transparent: "transparent",
  current: "currentColor",
  main: "var(--color-main)",
  background: "var(--color-background)",
  accent: "var(--color-accent)",
  secondary: "var(--color-secondary)",
  grey: "var(--color-grey)",
  darkGrey: "var(--color-darkGrey)",
  lightGrey: "var(--color-lightGrey)",
  dark: "var(--color-dark)",
  stroke: "var(--color-stroke)",
  blue: "var(--color-blue)",
  red: "var(--color-red)",
};
