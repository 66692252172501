import { useEffect, useState } from "react";
import { StepFormValidationIdsType } from "../../StepForm/StepFormTypes";

type InputFieldPropsType = {
  value: string;
  className?: string;
  label: string;
  validationId?: StepFormValidationIdsType;
  onChange: (value: string) => void;
};

export const InputField = ({
  value,
  label,
  validationId,
  onChange,
  className,
}: InputFieldPropsType) => {
  const [error, setError] = useState("");
  const [touched, setTouched] = useState(false);

  const validate = (inputValue: string) => {
    if (!validationId || !touched) return;

    if (validationId === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(inputValue)) {
        setError("Please enter a valid email address");
      } else {
        setError("");
      }
    } else if (validationId === "name") {
      if ( inputValue.length < 3) {
        setError("Name must be at least 3 characters long");
      } else {
        setError("");
      }
    }
  };

  useEffect(() => {
    validate(value || "");
  }, [value, touched]);

  return (
    <div className="w-full relative pb-4">
      <div
        className={`relative w-full bg-transparent border rounded-lg border-accent-transparent ${
          error ? "border-red hover:border-red" : ""
        } ${className}`}
      >
        <input
          onChange={(e) => {
            onChange(e.target.value);
            setTouched(true);
          }}
          onBlur={() => setTouched(true)}
          type="text"
          value={value}
          id={label}
          placeholder=""
          className="peer py-2 px-4 w-full text-lg bg-transparent placeholder:text-transparent outline-none"
        />
        <label
          htmlFor={label}
          className="absolute bg-background left-1 pointer-events-none ml-1 -translate-y-2 text-main px-2 text-sm duration-100 ease-linear peer-placeholder-shown:translate-y-2 peer-placeholder-shown:text-lg peer-placeholder-shown:text-lightGrey peer-focus:ml-1 peer-focus:-translate-y-2 peer-focus:px-2 peer-focus:text-sm peer-focus:text-main"
        >
          {label}{validationId && "*"}
        </label>
      </div>
      {error && touched && <p className="text-red text-sm mt-1 absolute">{error}</p>}
    </div>
  );
};
