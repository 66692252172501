import React, { createContext, useContext, ReactNode } from "react";
import {
  useScrollToSections,
  SectionRefsType,
  ScrollToSectionType,
} from "../hooks/useScrollToSections"; // Adjust this import

// Define the type of the context value
type ScrollContextType = {
  sectionRefs: SectionRefsType;
  scrollToSection: ScrollToSectionType;
};

// Initialize the context with the correct type, or undefined if the value will be provided later
const ScrollContext = createContext<ScrollContextType | undefined>(undefined);

// ScrollProvider component to provide the context value
export const ScrollProvider = ({ children }: { children: ReactNode }) => {
  const { sectionRefs, scrollToSection } = useScrollToSections();

  return (
    <ScrollContext.Provider value={{ sectionRefs, scrollToSection }}>
      {children}
    </ScrollContext.Provider>
  );
};

// Custom hook to use the scroll context
export const useScroll = () => {
  const context = useContext(ScrollContext);

  // Check if context is undefined, and throw an error if it is
  if (!context) {
    throw new Error("useScroll must be used within a ScrollProvider");
  }

  return context;
};
