import { TextCardsType } from "./UseCases";

export const industriesId: industryType[] = [
  "legalServices",
  "education",
  "hr",
  "manufacturingIndustrial",
  "finance",
  "retail",
  "agricultureAndNaturalResources",
  "artsMediaAndEntertainment",
  "healthcarePharmaceuticalsBiotech",
  "supplyChainLogistics",
  "travel",
  "realEstateConstruction",
  "automotive",
  "fashion",
  "itTechnology",
];

export type industryType =
  | "legalServices"
  | "education"
  | "hr"
  | "manufacturingIndustrial"
  | "finance"
  | "retail"
  | "agricultureAndNaturalResources"
  | "artsMediaAndEntertainment"
  | "healthcarePharmaceuticalsBiotech"
  | "supplyChainLogistics"
  | "travel"
  | "realEstateConstruction"
  | "automotive"
  | "fashion"
  | "itTechnology";

export const useCasesDefaults: TextCardsType[] = [
  {
    headline: "ai.industries.hr.exampleApplications.a.headline",
    text: "ai.industries.hr.exampleApplications.a.text",
    tag: "ai.industries.hr.label",
  },
  {
    headline: "ai.industries.legalServices.exampleApplications.a.headline",
    text: "ai.industries.legalServices.exampleApplications.a.text",
    tag: "ai.industries.legalServices.label",
  },
  {
    headline: "ai.industries.finance.exampleApplications.a.headline",
    text: "ai.industries.finance.exampleApplications.a.text",
    tag: "ai.industries.finance.label",
  },
  {
    headline:
      "ai.industries.manufacturingIndustrial.exampleApplications.a.headline",
    text: "ai.industries.manufacturingIndustrial.exampleApplications.a.text",
    tag: "ai.industries.manufacturingIndustrial.label",
  },
];

export const findUseCaseData = (industry: industryType) => {
  return [
    {
      headline: `ai.industries.${industry}.exampleApplications.a.headline`,
      text: `ai.industries.${industry}.exampleApplications.a.text`,
      tag: `ai.industries.${industry}.label`,
    },
    {
      headline: `ai.industries.${industry}.exampleApplications.b.headline`,
      text: `ai.industries.${industry}.exampleApplications.b.text`,
      tag: `ai.industries.${industry}.label`,
    },
    {
      headline: `ai.industries.${industry}.exampleApplications.c.headline`,
      text: `ai.industries.${industry}.exampleApplications.c.text`,
      tag: `ai.industries.${industry}.label`,
    },
    {
      headline: `ai.industries.${industry}.exampleApplications.d.headline`,
      text: `ai.industries.${industry}.exampleApplications.d.text`,
      tag: `ai.industries.${industry}.label`,
    },
  ];
};

export const getIndustryLabel = (industryId: industryType) => {
  return `ai.industries.${industryId}.label`

}
