import { StepFormDataType, StepFormStepType } from "../../StepFormTypes";
import { stepFormAuSubforms } from "./formData-ai-SubForms";
import { getStepFormButton } from "../formDataElementsUtils";

export const stepFormDataAI: StepFormDataType = {
  firstStep: "ai-1",
  steps: [
    {
      id: "ai-1",
      answer: {
        type: "button_options",
        answerConfig: {
          options: [
            getStepFormButton(
              "ai.bookCallButtonLabel",
              "callToAction",
              "scrollToSection",
              true
            ),
            getStepFormButton(
              "ai.useCases",
              "useCases",
              "scrollToSection"
            ),
          ],
        },
      },
    },
    {
      id: "ai-2",
      title: "ai.howWorks.title",
      answer: {
        type: "button_options",
        answerConfig: {
          options: [
            getStepFormButton("ai.bookCallButtonLabel", "bookCall-1"),
            getStepFormButton(
              "ai.moreQuestions",
              "qaSection",
              "scrollToSection"
            ),
            getStepFormButton(
              "ai.useCases",
              "useCases",
              "scrollToSection"
            ),
          ],
        },
      },
    },
    ...stepFormAuSubforms,
  ],
};
