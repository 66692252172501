export const aiCompanyPositionsTranslations = {
  founder: {
    en: "Founder / CEO",
    es: "Fundador / CEO",
    de: "Gründer / CEO",
  },
  executive: {
    en: "Executive Management",
    es: "Gestión Ejecutiva",
    de: "Geschäftsführung",
  },
  marketing: {
    en: "Marketing / Sales",
    es: "Marketing / Ventas",
    de: "Marketing / Vertrieb",
  },
  dataScientist: {
    en: "Data Scientist / Engineer",
    es: "Científico de Datos / Ingeniero",
    de: "Datenwissenschaftler / Ingenieur",
  },
  consultant: {
    en: "Consultant / Advisor",
    es: "Consultor / Asesor",
    de: "Berater / Ratgeber",
  },
  hr: {
    en: "HR",
    es: "RRHH",
    de: "Personalwesen",
  },
  other: {
    en: "Other",
    es: "Otro",
    de: "Sonstiges",
  },
};
