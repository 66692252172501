import { AIBrainSvg } from "./aibrain";
import { BrainSvg } from "./brain";
import { BuildSvg } from "./build";
import { ChainCube } from "./chainCube";
import { ChessSvg } from "./chess";
import { DevSvg } from "./dev";
import { FullService } from "./fullService";
import { LogoSvg } from "./logo";
import { NetSvg } from "./net";
import { NetowrkSvg } from "./network";

export const getSvg = (
  name: string,
  fill?: string,
  width?: string,
  height?: string,
  gradient?: string
) => {
  switch (name) {
    case "brain":
      return <BrainSvg fill={fill} width={width} height={height} />;
    case "ai-brain":
      return <AIBrainSvg fill={fill} width={width} height={height} />;
    case "net":
      return <NetSvg fill={fill} width={width} height={height} />;
    case "chess":
      return <ChessSvg fill={fill} width={width} height={height} />;
    case "build":
      return <BuildSvg fill={fill} width={width} height={height} />;
    case "network":
      return <NetowrkSvg fill={fill} width={width} height={height} />;
    case "full-service":
      return <FullService fill={fill} width={width} height={height} />;
    case "dev":
      return <DevSvg fill={fill} width={width} height={height} />;
    case "chain-cube":
      return <ChainCube fill={fill} width={width} height={height} />;
    case "logo":
      return (
        <LogoSvg
          fill={fill}
          width={width}
          height={height}
          gradient={gradient}
        />
      );
  }
};
