import { useRef } from "react";
import { Card } from "../Card/Card";
import useScrollTriggeredCountUp from "../../hooks/useCountUp";

export type NumberCardType = {
  number: number;
  symbol?: string;
  text: string;
  className?: string;
};

export const NumberCard = ({
  number,
  symbol = "%",
  text,
  className,
}: NumberCardType) => {
  const ref = useRef<HTMLDivElement>(null);
  const count = useScrollTriggeredCountUp(ref, number);

  return (
    <Card className={`shadow-sm shadow-accent ${className}`}>
      <div
        ref={ref}
        className="text-4xl text-gradient drop-shadow-xl md:text-6xl 2xl:text-9xl font-extrabold"
      >
        {count}
        <span className="text-xl md:text-4xl">{symbol}</span>
      </div>
      <h3 className="body-2 mt-auto">{text}</h3>
    </Card>
  );
};
