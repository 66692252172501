import { useState, useEffect } from "react";
import { StepFormDataType, StepFormStepType } from "./StepFormTypes";
import { FormUserActionType } from "./useRenderStepAnswer";
import {
  FormStateAnswerInputFieldType,
  FormStateType,
  FormStateUserAnswerType,
} from "./StepFormTypes";
import { ScrollToSectionType } from "../hooks/useScrollToSections";

export const useStepForm = (
  formState: FormStateType,
  firstStep: string | undefined,
  stepFormData: StepFormDataType,
  scrollContainerRef: any,
  scrollToSection: ScrollToSectionType,
  updateFormState: (
    stepHeadline: string | undefined,
    userAnswer: FormStateUserAnswerType
  ) => void,
  submitForm: () => void
) => {
  const [currentStep, setCurrentStep] = useState(
    firstStep || stepFormData.firstStep
  );

  const currentStepData: StepFormStepType =
    stepFormData.steps.find((step: any) => step.id === currentStep) ||
    stepFormData.steps[1];

  const answerInState: any = formState.userActions[currentStepData.id];

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [currentStep]);

  const handleUserAction = (action: FormUserActionType) => {
    const stepHeadline = currentStepData.title;

    switch (action.userActionType) {
      case "button_options":
        const userAnswer = action.actionData.label;

        switch (action.actionData.actionType) {
          case "changeStep":
            setCurrentStep(action.actionData.nextStepId);
            break;
          case "scrollToSection":
            if (scrollToSection) {
              scrollToSection(action.actionData.sectionName);
            } else {
              console.error("ScrollToSection Not provided");
            }
            break;
        }

        updateFormState(stepHeadline, userAnswer);
        break;
      case "input_fields":
        const updatedField = action.actionData.field;
        const updatedFieldValue = action.actionData.value;

        const inputFieldsInStep = answerInState
          ? answerInState.user_answer
          : undefined;

        const newAnswerInState: FormStateAnswerInputFieldType = {
          ...inputFieldsInStep,
          [updatedField.id]: {
            fieldLabel: updatedField.label,
            inputValue: updatedFieldValue,
          },
        };
        updateFormState(stepHeadline, newAnswerInState);
        break;
      case "input_fields_button":
        if (action.submitForm) {
          console.log("Submitting form");
          submitForm();
        }
        setCurrentStep(action.nextStepId);
        break;
      default:
        break;
    }
  };

  return {
    handleUserAction,
    currentStep,
    currentStepData,
    answerInState,
  };
};
