import { aiBusinessSectorsTranslations } from "./aiBusinessSectorsTranslations";
import { aiCompanyPositionsTranslations } from "./aiCompanyPositionsTranslations";
import { aiHowWorksTranslations } from "./aiHowWorksTranslations";
import { aiStartingTimeframesTranslations } from "./aiStartingTimeframesTranslations";
import { aiSubFieldsTranslations } from "./aiSubFieldsTranslations";
import { aiTalentTranslations } from "./aiTalentTranslations";
import { aiBookCallTranslations } from "./bookCallTranslations";

export const aiTranslations = {
  bookCallButtonLabel: {
    en: "Book Free Discovery Call",
    es: "Agenda una llamada gratis",
    de: "Kostenloses Beratungsgespräch buchen",
  },
  howWorksButtonLabel: {
    en: "What can AI do?",
    es: "¿Qué puede hacer la IA?",
    de: "Was kann KI tun?",
  },
  moreQuestions: {
    en: "I have more questions",
    es: "Tengo más preguntas",
    de: "Ich habe noch Fragen",
  },
  useCases: {
    en: "Find use cases for my business",
    es: "Encuentra casos de uso para mi negocio",
    de: "Anwendungsfälle für mein Unternehmen finden",
  },
  howWorks: aiHowWorksTranslations,
  businessSector: aiBusinessSectorsTranslations,
  talent: aiTalentTranslations,
  startingTimeframes: aiStartingTimeframesTranslations,
  subFields: aiSubFieldsTranslations,
  companyPositions: aiCompanyPositionsTranslations,
  ...aiBookCallTranslations,
};
