export const aiHeroTranslations = {
  first: {
    en: "Gain AI Advantage with",
    es: "Ventaja de IA con",
    de: "AI Advantage mit",
  },
  strings: {
    a: {
      en: "Custom Solutions",
      es: "Soluciones a Medida",
      de: "Zugeschnittene Lösungen",
    },
    b: {
      en: "Tailored Strategy",
      es: "Estrategia adaptada",
      de: "Individuelle KI-Strategie",
    },
    c: {
      en: "Expert Teams",
      es: "Equipos Dedicados",
      de: "KI-Expertenteams",
    },
    d: {
      en: "Top-tier Talent",
      es: "Professionales",
      de: "Fachkräfte",
    },
  },
  last: {
    en: "",
    es: "",
    de: "",
  },
  text: {
    en: "We partner with visionaries to navigate the rapidly evolving AI landscape, exploring possibilities, making optimal decisions, and ensuring seamless implementation and effective team integration.",
    es: "Ya sea que necesite especialistas en IA, estrategias innovadoras o ayuda para explorar posibilidades, le conectamos con los expertos adecuados y le ofrecemos soluciones a medida. Sus objetivos son únicos, por eso cada solución es personalizada, cada resultado medible y cada paso sencillo.",
    de: "Ob Sie KI-Spezialisten, innovative Strategien oder Hilfe bei der Erkundung von Möglichkeiten benötigen – wir vermitteln Ihnen die passenden Experten und liefern maßgeschneiderte Lösungen. Ihre Ziele sind einzigartig – daher ist jede Lösung individuell, jedes Ergebnis messbar und jeder Schritt einfach.",
  },
  ctaCard: {
    buttonText: {
      en: "Book free call",
      es: "Reservar llamada gratuita",
      de: "Kostenlosen Anruf buchen",
    },
    headline: { en: "Headline", es: "Título", de: "Überschrift" },
    text: {
      en: "The business landscape of the future will have those who have embraced A.I and those who wish they had",
      es: "El panorama empresarial del futuro tendrá aquellos que hayan abrazado la IA y aquellos que desearían haberlo hecho",
      de: "Die Geschäftslandschaft der Zukunft wird diejenigen haben, die KI angenommen haben und diejenigen, die es wünschten",
    },
  },
};
