import { Button } from "../../Components/Button/Button";
import { RotatingText } from "../../Components/RotatingText/RotatingText";
import { useScroll } from "../../Providers/ScrollContextProvider";
import { StepForm } from "../../StepForm/StepForm";
import { StepFormDataType } from "../../StepForm/StepFormTypes";

type HeroPropsTypes = {
  first: string;
  last?: string;
  strings?: string[];
  text?: string;
  className?: string;
  stepFormData?: StepFormDataType;
};

export const Hero = ({
  first,
  last,
  strings,
  text,
  className,
  stepFormData,
}: HeroPropsTypes) => {
  const { scrollToSection } = useScroll();

  return (
    <div
      className={`pt-40 pb-0 md:pt-60 gap-10 relative flex items-center flex-wrap lg:flex-nowrap flex-col justify-between ${className}`}
    >
      <div className=" w-full relative">
        <div className="heading-1 text-center">
          <div className="flex flex-wrap flex-col items-center w-full justify-center ">
            <h1>{first}</h1>
            {strings && <RotatingText strings={strings} />}
          </div>

          {last}
        </div>
        {text && (
          <h3 className="heading-4 mt-10 text-center text-darkGrey/60">
            {text}
          </h3>
        )}
      </div>
      {/* {stepFormData && (
        <StepForm
          formId="ai-hero"
          stepFormData={stepFormData}
          className="h-fit border-none shadow-none bg-color-none"
        />
      )} */}
      <div className="flex flex-wrap items-center gap-5 justify-center" >
        <Button
          label={"Book Discovery Call"}
          highlight
          className="w-fit"
          action={() => scrollToSection("callToAction")}
        />
        <Button
          label={"Find use cases"}
          className="w-fit"
          action={() => scrollToSection("useCases")}
        />
      </div>
    </div>
  );
};
