import { useAtom } from "jotai";
import { translationLanguageAtom } from "../../jotai/state";
import { useEffect, useRef, useState } from "react";
import {
  Earth,
  EarthIcon,
  Globe,
  LucideEarth,
  LucideLanguages,
} from "lucide-react";
import { getThemeColor } from "../../Utils/tailwind/tailwindUtils";

const languages = [
  { id: "es", label: "Español" },
  { id: "en", label: "English" },
  { id: "de", label: "Deutsch" },
];

export const LanguageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [lang, setLang] = useAtom(translationLanguageAtom);
  const langMenuRef = useRef<HTMLDivElement>(null);

  const languageButton = (id: string, label: string) => {
    return (
      <button
        className="w-full py-4 px-4 pr-8 text-left hover:text-accent border-b border-stroke last:border-0 "
        onClick={() => {
          setLang(id);
          setIsOpen(false);
        }}
      >
        {label}
      </button>
    );
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        langMenuRef.current &&
        !langMenuRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="relative ">
      <button
        className="flex flex-row items-center gap-2 uppercase hover:text-accent rounded-md px-2 py-1 group"
        onClick={() => setIsOpen(true)}
      >
        <Globe
          size={18}
          strokeWidth={1}
          className="group-hover:stroke-accent"
        />
      </button>
      {isOpen && (
        <div
          ref={langMenuRef}
          className="absolute overflow-hidden bg-background shadow-smooth border border-stroke rounded-sm right-2 top-8"
        >
          {languages.map((langItem) => {
            if (langItem.id !== lang) {
              return languageButton(langItem.id, langItem.label);
            }
          })}
        </div>
      )}
    </div>
  );
};
