import { useRef, useState } from "react";
import {
  FormStateType,
  FormStateUserAnswerType,
  StepFormDataType,
} from "./StepFormTypes";
import { SafeTextRenderer } from "../Utils/SafeTextRenderer";
import { getThemeColor } from "../Utils/tailwind/tailwindUtils";
import { useScroll } from "../Providers/ScrollContextProvider";
import { useStepForm } from "./useStepForm";
import { StepFormAnswer } from "./Components/StepFormAnswer";

import { useStepFormTranslations } from "./StepFormTranslations/useStepFormTranslations";
import { translationLanguageAtom } from "../jotai/state";
import { useAtom } from "jotai";
import { useFormspreeSubmission } from "./StepFormTranslations/FormSubmission/useFormSpreeSubmission";
import { getSvg } from "../Utils/svgs/getSvg";
import { motion } from "framer-motion";

export type StepFormPropsTypes = {
  className?: string;
  stepFormData: StepFormDataType;
  firstStep?: string;
  overline?: string;
  formId: string;
};

export const StepForm = ({
  className,
  stepFormData,
  firstStep,
  overline,
  formId,
}: StepFormPropsTypes) => {
  const [lang] = useAtom(translationLanguageAtom);

  const [formState, setFormState] = useState<FormStateType>({
    formId: formId,
    userActions: {},
  });

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const { scrollToSection } = useScroll();
  const translateString = useStepFormTranslations(lang);

  const updateFormState = (
    stepHeadline: string = "No Title",
    userAnswer: FormStateUserAnswerType
  ) => {
    setFormState((prevState) => ({
      ...prevState,
      userActions: {
        ...prevState.userActions,
        [currentStepData.id]: {
          step_title: translateString(stepHeadline, "en"),
          user_answer:
            typeof userAnswer === "string"
              ? translateString(userAnswer, "en")
              : userAnswer,
        },
      },
    }));
  };
  const { submitForm } = useFormspreeSubmission("xnnakegj");

  const { handleUserAction, currentStepData, answerInState } = useStepForm(
    formState,
    firstStep,
    stepFormData,
    scrollContainerRef,
    scrollToSection,
    updateFormState,
    () => submitForm(formState)
  );

  let stepHeadline;

  if (currentStepData.title) {
    stepHeadline = translateString(currentStepData.title);
  }

  return (
    <div
      className={`react-typed-container h-full w-full card-highlight bg-background pr-0 overflow-hidden flex flex-col relative ${className}`}
    >
      <div className="p-2 absolute">
        {getSvg(
          "logo",
          getThemeColor("accent"),
          "20px",
          "20px",
          getThemeColor("secondary")
        )}
      </div>
      <div
        className={`relative max-h-full min-h-full flex flex-col  ${
          stepHeadline ? "items-between" : "justify-center"
        } `}
      >
        {stepHeadline && (
          <motion.div
            className={`flex h-2/5 flex-col gap-2 justify-between`}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            key={stepHeadline}
            exit="exit"
            transition={{ duration: 1 }}
          >
            <div className="body-2 step-form-text-container h-full flex items-center justify-center flex-wrap flex-col p-5 md:pt-10">
              {overline && <p className="text-accent text-base">{overline}</p>}
              <SafeTextRenderer
                className="text-main w-fit flex flex-col px-5"
                text={stepHeadline}
              />
            </div>
          </motion.div>
        )}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: .5 }}
          exit="exit"
          key={currentStepData.id}
          ref={scrollContainerRef}
          className={`overflow-y-auto h-3/5 mx-3 mb-3 p-5 items-center justify-center ${stepHeadline ? "card-highlight" : ""} `}
        >
          <StepFormAnswer
            currentStepData={currentStepData}
            answerInState={answerInState}
            handleUserAction={handleUserAction}
            translate={lang}
          />
        </motion.div>
      </div>
    </div>
  );
};
