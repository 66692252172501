export const aiStartingTimeframesTranslations = {
    now: {
      en: "Immediately",
      es: "Inmediatamente",
      de: "Sofort"
    },
    oneMonth: {
      en: "Within 1 month",
      es: "Dentro de 1 mes",
      de: "Innerhalb von 1 Monat"
    },
    oneThreeMonth: {
      en: "1-3 months",
      es: "1-3 meses",
      de: "1-3 Monate"
    },
    threeSix: {
      en: "3-6 months",
      es: "3-6 meses",
      de: "3-6 Monate"
    },
    sixMore: {
      en: "More than 6 months",
      es: "Más de 6 meses",
      de: "Mehr als 6 Monate"
    }
  };