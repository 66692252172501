export const aiUseCasesTranslations = {
  headline: {
    en: "AI in Action: Far beyond just chatbots",
    es: "IA en Acción: Transformación Empresarial en el Mundo Real",
    de: "KI in Aktion: Reale Geschäftsumwandlung",
  },
  text: {
    en: "Explore real AI use cases in your industry",
    es: "Explora casos de uso reales de IA en tu industria",
    de: "Entdecken Sie reale KI-Anwendungsfälle in Ihrer Branche",
  },
  selectedIndustryText: {
    en: "AI use cases in ",
    es: "Casos de uso de IA en ",
    de: "KI-Anwendungsfälle in ",
  },
};
