import { useRef, useCallback } from "react";

export type SectionRefsType = {
  [key: string]: React.RefObject<HTMLDivElement>;
};

export type ScrollToSectionType = (sectionName: keyof SectionRefsType) => void;

export const useScrollToSections = () => {
  const sectionRefs: SectionRefsType = {
    qaSection: useRef<HTMLDivElement>(null),
    useCases: useRef<HTMLDivElement>(null),
    callToAction: useRef<HTMLDivElement>(null),
  };

  const scrollToSection: ScrollToSectionType = useCallback((sectionName) => {
    const sectionRef = sectionRefs[sectionName];
    if (sectionRef && sectionRef.current) {
      const yOffset = -200; // 100px offset
      const element = sectionRef.current;
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [sectionRefs]);

  return { sectionRefs, scrollToSection };
};