import "./RotatingText.scss";

export const RotatingText = ({ strings }: { strings: string[] }) => {
  const heightClassName = `h-[60px] md:h-[72px] lg:h-[89px]`;
  const textSizeClassName = `text-4xl md:text-6xl lg:text-8xl text-center text-nowrap mx-auto`;
  return (
    <div className="rotating-text w-fit">
      <span className={`relative `}>
        <span className={`invisible ${textSizeClassName}`}>{strings[0]}</span>
        <div className="cube-container nowrap text-center flex justify-center">
          {strings.map((string, i) => {
            return (
              <div
                key={string}
                className={`cube-face-${
                  i + 1
                } ${heightClassName} ${textSizeClassName}`}
              >
                {string}
              </div>
            );
          })}
        </div>
      </span>
    </div>
  );
};
