import { useTranslate } from "../../hooks/useTranslation";
import { useScroll } from "../../Providers/ScrollContextProvider";
import { StepForm } from "../../StepForm/StepForm";
import { stepFormDataAI } from "../../StepForm/stepFormData/ai/formData-ai";
import { TextElementSection } from "../TextElementSection/TextElementSection";

type CallToActionPropsType = {
  className?: string;
};

export const CallToAction = ({
  className,
}: CallToActionPropsType) => {
  const { sectionRefs } = useScroll();

  const translate = useTranslate();
  return (
    <div ref={sectionRefs.callToAction} className={` ${className}`}>
      <div className="gap-10 flex flex-col">
        <TextElementSection
          headline={translate("ai.callToAction.ctaCards.a.overline")}
          text={translate("ai.callToAction.ctaCards.a.text")}
        >
          <StepForm
            formId="ai-cta-book-call"
            stepFormData={stepFormDataAI}
            firstStep="bookCall-start"
          />
        </TextElementSection>
        <TextElementSection
          headline={translate("ai.callToAction.ctaCards.b.overline")}
          text={translate("ai.callToAction.ctaCards.b.text")}
          order="right"
        >
          <StepForm
            formId="ai-cta-talent"
            stepFormData={stepFormDataAI}
            firstStep="talent-1"
          />
        </TextElementSection>
      </div>
    </div>
  );
};
