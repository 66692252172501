//@ts-ignore

export const NetowrkSvg = ({
  fill = "#000000",
  stroke,
  width = "100px",
  height = "100px",
}: {
  fill?: string;
  stroke?: string;
  width?: string;
  height?: string;
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      zoomAndPan="magnify"
      viewBox="0 0 262.5 151.500001"
      height={height}
      preserveAspectRatio="xMidYMid meet"
      version="1.0"
    >
      <defs>
        <filter x="0%" y="0%" width="100%" height="100%" id="bc9e7e4bfe">
          <feColorMatrix
            values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"
            color-interpolation-filters="sRGB"
          />
        </filter>
        <clipPath id="ab2a317881">
          <path
            d="M 0.183594 67 L 5 67 L 5 72 L 0.183594 72 Z M 0.183594 67 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="189c43b6f4">
          <path
            d="M 184 148 L 187 148 L 187 150.652344 L 184 150.652344 Z M 184 148 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="0896b634c9">
          <path
            d="M 260 93 L 261.820312 93 L 261.820312 95 L 260 95 Z M 260 93 "
            clip-rule="nonzero"
          />
        </clipPath>
        <mask id="aaf6e7aaca">
          <g filter="url(#bc9e7e4bfe)">
            <rect
              x="-26.25"
              width="315"
              fill="#000000"
              y="-15.15"
              height="181.800001"
              fill-opacity="0.54"
            />
          </g>
        </mask>
        <clipPath id="c3c5d2ecc6">
          <path
            d="M 0.335938 0.164062 L 1 0.164062 L 1 14.515625 L 0.335938 14.515625 Z M 0.335938 0.164062 "
            clip-rule="nonzero"
          />
        </clipPath>
        <clipPath id="dc53b0869c">
          <rect x="0" width="2" y="0" height="15" />
        </clipPath>
      </defs>
      <path
        fill={fill}
        d="M 69.078125 116.6875 L 68.988281 116.6875 L 68.988281 96.125 L 69.078125 96.125 Z M 69.078125 116.6875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 49.71875 106.40625 L 49.628906 106.40625 L 49.628906 89.304688 L 49.71875 89.304688 Z M 49.71875 106.40625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 60.363281 101.808594 L 60.269531 101.808594 L 60.269531 83.53125 L 60.363281 83.53125 Z M 60.363281 101.808594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 57.265625 99.824219 L 57.175781 99.824219 L 57.175781 87.589844 L 57.265625 87.589844 Z M 57.265625 99.824219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 28.046875 143.078125 L 27.898438 143.078125 L 27.898438 91.4375 L 28.046875 91.4375 Z M 28.046875 143.078125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 88.585938 126.636719 L 88.4375 126.636719 L 88.4375 95.945312 L 88.585938 95.945312 Z M 88.585938 126.636719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 103.464844 106.859375 L 103.375 106.859375 L 103.375 93.390625 L 103.464844 93.390625 Z M 103.464844 106.859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 96.851562 98.742188 L 96.761719 98.742188 L 96.761719 92.941406 L 96.851562 92.941406 Z M 96.851562 98.742188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 93.789062 107.519531 L 93.695312 107.519531 L 93.695312 87.347656 L 93.789062 87.347656 Z M 93.789062 107.519531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 85.761719 99.554688 L 85.671875 99.554688 L 85.671875 82.417969 L 85.761719 82.417969 Z M 85.761719 99.554688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 121.863281 109.953125 L 121.773438 109.953125 L 121.773438 100.785156 L 121.863281 100.785156 Z M 121.863281 109.953125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 116.574219 119 L 116.421875 119 L 116.421875 85.484375 L 116.574219 85.484375 Z M 116.574219 119 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 134.457031 126.066406 L 134.308594 126.066406 L 134.308594 77.550781 L 134.457031 77.550781 Z M 134.457031 126.066406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 150.929688 128.46875 L 150.839844 128.46875 L 150.839844 110.585938 L 150.929688 110.585938 Z M 150.929688 128.46875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 160.339844 138.660156 L 160.1875 138.660156 L 160.1875 103.28125 L 160.339844 103.28125 Z M 160.339844 138.660156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 185.4375 149.121094 L 185.289062 149.121094 L 185.289062 111.15625 L 185.4375 111.15625 Z M 185.4375 149.121094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 208.613281 126.636719 L 208.523438 126.636719 L 208.523438 102.347656 L 208.613281 102.347656 Z M 208.613281 126.636719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 215.828125 145.875 L 215.679688 145.875 L 215.679688 111.246094 L 215.828125 111.246094 Z M 215.828125 145.875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 203.59375 135.683594 L 203.503906 135.683594 L 203.503906 96.15625 L 203.59375 96.15625 Z M 203.59375 135.683594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 191.511719 122.21875 L 191.421875 122.21875 L 191.421875 96.335938 L 191.511719 96.335938 Z M 191.511719 122.21875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 175.910156 117.167969 L 175.820312 117.167969 L 175.820312 96.578125 L 175.910156 96.578125 Z M 175.910156 117.167969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 172.691406 128.589844 L 172.542969 128.589844 L 172.542969 91.378906 L 172.691406 91.378906 Z M 172.691406 128.589844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 155.800781 118.882812 L 155.710938 118.882812 L 155.710938 96.699219 L 155.800781 96.699219 Z M 155.800781 118.882812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 225.054688 133.882812 L 224.964844 133.882812 L 224.964844 95.945312 L 225.054688 95.945312 Z M 225.054688 133.882812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 234.734375 105.203125 L 234.644531 105.203125 L 234.644531 92.277344 L 234.734375 92.277344 Z M 234.734375 105.203125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 198.605469 111.246094 L 198.515625 111.246094 L 198.515625 92.730469 L 198.605469 92.730469 Z M 198.605469 111.246094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 179.425781 138.660156 L 179.335938 138.660156 L 179.335938 84.792969 L 179.425781 84.792969 Z M 179.425781 138.660156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 106.742188 121.558594 L 106.652344 121.558594 L 106.652344 89.152344 L 106.742188 89.152344 Z M 106.742188 121.558594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 37.277344 113.710938 L 37.1875 113.710938 L 37.1875 73.339844 L 37.277344 73.339844 Z M 37.277344 113.710938 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 80.171875 113.53125 L 80.082031 113.53125 L 80.082031 92.277344 L 80.171875 92.277344 Z M 80.171875 113.53125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 78.277344 123.8125 L 78.1875 123.8125 L 78.1875 77.550781 L 78.277344 77.550781 Z M 78.277344 123.8125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 100.371094 102.230469 L 100.28125 102.230469 L 100.28125 91.527344 L 100.371094 91.527344 Z M 100.371094 102.230469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 90.992188 115.152344 L 90.902344 115.152344 L 90.902344 77.910156 L 90.992188 77.910156 Z M 90.992188 115.152344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 123.304688 105.714844 L 123.214844 105.714844 L 123.214844 83.019531 L 123.304688 83.019531 Z M 123.304688 105.714844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 167.070312 115.605469 L 166.980469 115.605469 L 166.980469 96.699219 L 167.070312 96.699219 Z M 167.070312 115.605469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 145.21875 112.207031 L 145.128906 112.207031 L 145.128906 87.257812 L 145.21875 87.257812 Z M 145.21875 112.207031 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 220.488281 107.699219 L 220.398438 107.699219 L 220.398438 82.417969 L 220.488281 82.417969 Z M 220.488281 107.699219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 238.523438 119 L 238.371094 119 L 238.371094 90.835938 L 238.523438 90.835938 Z M 238.523438 119 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 168.453125 83.832031 C 168.453125 83.53125 168.214844 83.261719 167.882812 83.261719 C 167.554688 83.261719 167.3125 83.5 167.3125 83.832031 C 167.3125 84.132812 167.554688 84.402344 167.882812 84.402344 C 168.214844 84.402344 168.453125 84.164062 168.453125 83.832031 Z M 168.453125 83.832031 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 202.960938 78.902344 C 202.960938 78.746094 203.019531 78.609375 203.128906 78.5 C 203.242188 78.386719 203.375 78.332031 203.535156 78.332031 C 203.691406 78.332031 203.828125 78.386719 203.9375 78.5 C 204.050781 78.609375 204.105469 78.746094 204.105469 78.902344 C 204.105469 79.058594 204.050781 79.195312 203.9375 79.304688 C 203.828125 79.417969 203.691406 79.472656 203.535156 79.472656 C 203.375 79.472656 203.242188 79.417969 203.128906 79.304688 C 203.019531 79.195312 202.960938 79.058594 202.960938 78.902344 Z M 202.960938 78.902344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 196.410156 76.707031 C 196.410156 76.527344 196.257812 76.40625 196.109375 76.40625 C 195.929688 76.40625 195.808594 76.558594 195.808594 76.707031 C 195.808594 76.890625 195.960938 77.007812 196.109375 77.007812 C 196.289062 77.039062 196.410156 76.890625 196.410156 76.707031 Z M 196.410156 76.707031 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 217.441406 66.617188 C 217.394531 66.433594 217.425781 66.257812 217.523438 66.097656 C 217.625 65.933594 217.769531 65.832031 217.953125 65.785156 C 218.140625 65.742188 218.3125 65.769531 218.476562 65.871094 C 218.636719 65.972656 218.742188 66.113281 218.785156 66.300781 C 218.828125 66.484375 218.800781 66.660156 218.703125 66.820312 C 218.601562 66.984375 218.457031 67.085938 218.273438 67.132812 C 218.085938 67.175781 217.914062 67.148438 217.75 67.046875 C 217.585938 66.945312 217.484375 66.804688 217.441406 66.617188 Z M 217.441406 66.617188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 166.800781 88.460938 C 166.800781 88.882812 166.472656 89.214844 166.050781 89.214844 C 165.628906 89.214844 165.296875 88.882812 165.296875 88.460938 C 165.296875 88.039062 165.628906 87.710938 166.050781 87.710938 C 166.441406 87.710938 166.800781 88.039062 166.800781 88.460938 Z M 166.679688 88.460938 C 166.679688 88.101562 166.378906 87.800781 166.019531 87.800781 C 165.660156 87.800781 165.359375 88.101562 165.359375 88.460938 C 165.359375 88.820312 165.660156 89.121094 166.019531 89.121094 C 166.378906 89.121094 166.679688 88.820312 166.679688 88.460938 Z M 166.679688 88.460938 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 167.734375 74.84375 C 167.734375 75.265625 167.402344 75.597656 166.980469 75.597656 C 166.5625 75.597656 166.230469 75.265625 166.230469 74.84375 C 166.230469 74.425781 166.5625 74.09375 166.980469 74.09375 C 167.402344 74.09375 167.734375 74.425781 167.734375 74.84375 Z M 167.644531 74.84375 C 167.644531 74.484375 167.34375 74.183594 166.980469 74.183594 C 166.621094 74.183594 166.320312 74.484375 166.320312 74.84375 C 166.320312 75.207031 166.621094 75.507812 166.980469 75.507812 C 167.34375 75.507812 167.644531 75.207031 167.644531 74.84375 Z M 167.644531 74.84375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 87.292969 73.191406 C 87.292969 73.613281 86.964844 73.941406 86.542969 73.941406 C 86.121094 73.941406 85.792969 73.613281 85.792969 73.191406 C 85.792969 72.769531 86.121094 72.441406 86.542969 72.441406 C 86.964844 72.441406 87.292969 72.769531 87.292969 73.191406 Z M 87.175781 73.191406 C 87.175781 72.832031 86.875 72.53125 86.511719 72.53125 C 86.152344 72.53125 85.851562 72.832031 85.851562 73.191406 C 85.851562 73.550781 86.152344 73.851562 86.511719 73.851562 C 86.875 73.851562 87.175781 73.550781 87.175781 73.191406 Z M 87.175781 73.191406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 31.234375 70.246094 C 31.234375 70.667969 30.902344 70.996094 30.480469 70.996094 C 30.0625 70.996094 29.730469 70.667969 29.730469 70.246094 C 29.730469 69.824219 30.0625 69.492188 30.480469 69.492188 C 30.902344 69.492188 31.234375 69.824219 31.234375 70.246094 Z M 31.144531 70.246094 C 31.144531 69.886719 30.84375 69.585938 30.480469 69.585938 C 30.121094 69.585938 29.820312 69.886719 29.820312 70.246094 C 29.820312 70.605469 30.121094 70.90625 30.480469 70.90625 C 30.84375 70.90625 31.144531 70.605469 31.144531 70.246094 Z M 31.144531 70.246094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 250.066406 71.359375 C 250.066406 71.777344 249.734375 72.109375 249.3125 72.109375 C 248.894531 72.109375 248.5625 71.777344 248.5625 71.359375 C 248.5625 70.9375 248.894531 70.605469 249.3125 70.605469 C 249.707031 70.605469 250.066406 70.9375 250.066406 71.359375 Z M 249.945312 71.359375 C 249.945312 70.996094 249.644531 70.695312 249.285156 70.695312 C 248.921875 70.695312 248.621094 70.996094 248.621094 71.359375 C 248.621094 71.71875 248.921875 72.019531 249.285156 72.019531 C 249.644531 71.988281 249.945312 71.71875 249.945312 71.359375 Z M 249.945312 71.359375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 116 77.941406 C 116 78.363281 115.671875 78.691406 115.25 78.691406 C 114.828125 78.691406 114.5 78.363281 114.5 77.941406 C 114.5 77.519531 114.828125 77.1875 115.25 77.1875 C 115.671875 77.1875 116 77.519531 116 77.941406 Z M 115.882812 77.941406 C 115.882812 77.578125 115.582031 77.28125 115.21875 77.28125 C 114.859375 77.28125 114.558594 77.578125 114.558594 77.941406 C 114.558594 78.300781 114.859375 78.601562 115.21875 78.601562 C 115.582031 78.601562 115.882812 78.300781 115.882812 77.941406 Z M 115.882812 77.941406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 195.296875 85.15625 C 195.296875 85.574219 194.96875 85.90625 194.546875 85.90625 C 194.125 85.90625 193.792969 85.574219 193.792969 85.15625 C 193.792969 84.734375 194.125 84.402344 194.546875 84.402344 C 194.96875 84.402344 195.296875 84.734375 195.296875 85.15625 Z M 195.207031 85.15625 C 195.207031 84.792969 194.90625 84.492188 194.546875 84.492188 C 194.1875 84.492188 193.886719 84.792969 193.886719 85.15625 C 193.886719 85.515625 194.1875 85.816406 194.546875 85.816406 C 194.90625 85.785156 195.207031 85.515625 195.207031 85.15625 Z M 195.207031 85.15625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 208.34375 82.089844 C 208.34375 82.511719 208.011719 82.839844 207.59375 82.839844 C 207.171875 82.839844 206.839844 82.511719 206.839844 82.089844 C 206.839844 81.667969 207.171875 81.335938 207.59375 81.335938 C 208.011719 81.335938 208.34375 81.667969 208.34375 82.089844 Z M 208.253906 82.089844 C 208.253906 81.726562 207.953125 81.425781 207.59375 81.425781 C 207.230469 81.425781 206.929688 81.726562 206.929688 82.089844 C 206.929688 82.449219 207.230469 82.75 207.59375 82.75 C 207.953125 82.75 208.253906 82.449219 208.253906 82.089844 Z M 208.253906 82.089844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 215.347656 74.574219 C 215.347656 74.996094 215.015625 75.324219 214.597656 75.324219 C 214.175781 75.324219 213.84375 74.996094 213.84375 74.574219 C 213.84375 74.152344 214.175781 73.824219 214.597656 73.824219 C 214.988281 73.824219 215.347656 74.152344 215.347656 74.574219 Z M 215.226562 74.574219 C 215.226562 74.214844 214.925781 73.914062 214.566406 73.914062 C 214.203125 73.914062 213.90625 74.214844 213.90625 74.574219 C 213.90625 74.933594 214.203125 75.234375 214.566406 75.234375 C 214.957031 75.234375 215.226562 74.933594 215.226562 74.574219 Z M 215.226562 74.574219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 175.609375 88.941406 C 175.609375 89.214844 175.398438 89.421875 175.128906 89.421875 C 174.855469 89.421875 174.648438 89.214844 174.648438 88.941406 C 174.648438 88.671875 174.855469 88.460938 175.128906 88.460938 C 175.398438 88.460938 175.609375 88.671875 175.609375 88.941406 Z M 175.457031 88.941406 C 175.457031 88.761719 175.308594 88.640625 175.15625 88.640625 C 174.976562 88.640625 174.855469 88.792969 174.855469 88.941406 C 174.855469 89.121094 175.007812 89.242188 175.15625 89.242188 C 175.308594 89.242188 175.457031 89.121094 175.457031 88.941406 Z M 175.457031 88.941406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 154.746094 82.269531 C 154.746094 82.71875 154.386719 83.082031 153.9375 83.082031 C 153.484375 83.082031 153.125 82.71875 153.125 82.269531 C 153.125 81.820312 153.484375 81.457031 153.9375 81.457031 C 154.386719 81.457031 154.746094 81.820312 154.746094 82.269531 Z M 154.445312 82.269531 C 154.445312 81.96875 154.207031 81.757812 153.9375 81.757812 C 153.636719 81.757812 153.425781 82 153.425781 82.269531 C 153.425781 82.570312 153.664062 82.78125 153.9375 82.78125 C 154.207031 82.808594 154.445312 82.570312 154.445312 82.269531 Z M 154.445312 82.269531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 138.996094 91.527344 C 138.996094 92.160156 138.484375 92.640625 137.882812 92.640625 C 137.253906 92.640625 136.773438 92.128906 136.773438 91.527344 C 136.773438 90.894531 137.285156 90.414062 137.882812 90.414062 C 138.484375 90.414062 138.996094 90.925781 138.996094 91.527344 Z M 138.605469 91.527344 C 138.605469 91.136719 138.273438 90.804688 137.882812 90.804688 C 137.492188 90.804688 137.164062 91.136719 137.164062 91.527344 C 137.164062 91.917969 137.492188 92.25 137.882812 92.25 C 138.273438 92.277344 138.605469 91.949219 138.605469 91.527344 Z M 138.605469 91.527344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 230.046875 84.613281 C 230.046875 85.003906 229.714844 85.335938 229.324219 85.335938 C 228.933594 85.335938 228.601562 85.003906 228.601562 84.613281 C 228.601562 84.222656 228.933594 83.890625 229.324219 83.890625 C 229.714844 83.890625 230.046875 84.222656 230.046875 84.613281 Z M 229.777344 84.613281 C 229.777344 84.34375 229.566406 84.132812 229.292969 84.132812 C 229.023438 84.132812 228.8125 84.34375 228.8125 84.613281 C 228.8125 84.882812 229.023438 85.09375 229.292969 85.09375 C 229.566406 85.09375 229.777344 84.882812 229.777344 84.613281 Z M 229.777344 84.613281 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 211.140625 92.097656 C 211.140625 92.488281 210.808594 92.820312 210.417969 92.820312 C 210.027344 92.820312 209.695312 92.488281 209.695312 92.097656 C 209.695312 91.707031 210.027344 91.378906 210.417969 91.378906 C 210.808594 91.378906 211.140625 91.679688 211.140625 92.097656 Z M 210.898438 92.097656 C 210.898438 91.828125 210.6875 91.617188 210.417969 91.617188 C 210.148438 91.617188 209.9375 91.828125 209.9375 92.097656 C 209.9375 92.371094 210.148438 92.578125 210.417969 92.578125 C 210.6875 92.550781 210.898438 92.339844 210.898438 92.097656 Z M 210.898438 92.097656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 227.371094 71.359375 C 227.371094 71.75 227.039062 72.078125 226.648438 72.078125 C 226.257812 72.078125 225.929688 71.75 225.929688 71.359375 C 225.929688 70.96875 226.257812 70.636719 226.648438 70.636719 C 227.039062 70.605469 227.371094 70.9375 227.371094 71.359375 Z M 227.101562 71.359375 C 227.101562 71.085938 226.890625 70.875 226.621094 70.875 C 226.347656 70.875 226.136719 71.085938 226.136719 71.359375 C 226.136719 71.628906 226.347656 71.839844 226.621094 71.839844 C 226.890625 71.808594 227.101562 71.597656 227.101562 71.359375 Z M 227.101562 71.359375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 103.164062 84.402344 C 103.164062 85.035156 102.65625 85.515625 102.054688 85.515625 C 101.421875 85.515625 100.941406 85.003906 100.941406 84.402344 C 100.941406 83.773438 101.453125 83.292969 102.054688 83.292969 C 102.65625 83.292969 103.164062 83.800781 103.164062 84.402344 Z M 102.773438 84.402344 C 102.773438 84.011719 102.445312 83.683594 102.054688 83.683594 C 101.664062 83.683594 101.332031 84.011719 101.332031 84.402344 C 101.332031 84.792969 101.664062 85.125 102.054688 85.125 C 102.445312 85.15625 102.773438 84.824219 102.773438 84.402344 Z M 102.773438 84.402344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 184.507812 96.457031 C 184.507812 96.726562 184.296875 96.9375 184.027344 96.9375 C 183.753906 96.9375 183.542969 96.726562 183.542969 96.457031 C 183.542969 96.1875 183.753906 95.976562 184.027344 95.976562 C 184.296875 96.007812 184.507812 96.21875 184.507812 96.457031 Z M 184.324219 96.457031 C 184.324219 96.277344 184.175781 96.15625 184.027344 96.15625 C 183.84375 96.15625 183.726562 96.308594 183.726562 96.457031 C 183.726562 96.636719 183.875 96.757812 184.027344 96.757812 C 184.207031 96.757812 184.324219 96.636719 184.324219 96.457031 Z M 184.324219 96.457031 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 69.378906 71.6875 C 69.378906 71.328125 69.109375 71.058594 68.746094 71.058594 C 68.386719 71.058594 68.117188 71.328125 68.117188 71.6875 C 68.117188 72.050781 68.386719 72.320312 68.746094 72.320312 C 69.109375 72.320312 69.378906 72.050781 69.378906 71.6875 Z M 69.378906 71.6875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 71.421875 71.6875 C 71.421875 73.160156 70.222656 74.363281 68.746094 74.363281 C 67.273438 74.363281 66.074219 73.160156 66.074219 71.6875 C 66.074219 70.214844 67.273438 69.011719 68.746094 69.011719 C 70.222656 69.011719 71.421875 70.214844 71.421875 71.6875 Z M 71.273438 71.6875 C 71.273438 70.304688 70.128906 69.164062 68.746094 69.164062 C 67.363281 69.164062 66.222656 70.304688 66.222656 71.6875 C 66.222656 73.070312 67.363281 74.214844 68.746094 74.214844 C 70.128906 74.214844 71.273438 73.070312 71.273438 71.6875 Z M 71.273438 71.6875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 2.707031 69.074219 C 2.707031 68.804688 2.496094 68.59375 2.226562 68.59375 C 1.957031 68.59375 1.746094 68.804688 1.746094 69.074219 C 1.746094 69.34375 1.957031 69.554688 2.226562 69.554688 C 2.496094 69.554688 2.707031 69.34375 2.707031 69.074219 Z M 2.707031 69.074219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#ab2a317881)">
        <path
          fill={fill}
          d="M 4.269531 69.074219 C 4.269531 70.214844 3.339844 71.117188 2.226562 71.117188 C 1.085938 71.117188 0.183594 70.183594 0.183594 69.074219 C 0.183594 67.960938 1.113281 67.027344 2.226562 67.027344 C 3.339844 67 4.269531 67.929688 4.269531 69.074219 Z M 4.148438 69.074219 C 4.148438 68.019531 3.277344 67.148438 2.226562 67.148438 C 1.175781 67.148438 0.304688 68.019531 0.304688 69.074219 C 0.304688 70.125 1.175781 70.996094 2.226562 70.996094 C 3.277344 70.996094 4.148438 70.125 4.148438 69.074219 Z M 4.148438 69.074219 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill={fill}
        d="M 28.558594 142.988281 C 28.558594 142.6875 28.316406 142.417969 27.988281 142.417969 C 27.6875 142.417969 27.417969 142.660156 27.417969 142.988281 C 27.417969 143.289062 27.65625 143.558594 27.988281 143.558594 C 28.289062 143.558594 28.558594 143.320312 28.558594 142.988281 Z M 28.558594 142.988281 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 35.722656 113.808594 C 35.675781 113.609375 35.667969 113.410156 35.703125 113.210938 C 35.734375 113.007812 35.804688 112.820312 35.910156 112.648438 C 36.019531 112.476562 36.15625 112.328125 36.320312 112.210938 C 36.484375 112.09375 36.664062 112.007812 36.863281 111.960938 C 37.0625 111.914062 37.261719 111.910156 37.460938 111.941406 C 37.660156 111.972656 37.847656 112.042969 38.023438 112.152344 C 38.195312 112.257812 38.339844 112.394531 38.460938 112.558594 C 38.578125 112.722656 38.660156 112.90625 38.707031 113.101562 C 38.753906 113.300781 38.761719 113.5 38.730469 113.699219 C 38.695312 113.902344 38.625 114.085938 38.519531 114.261719 C 38.414062 114.433594 38.277344 114.578125 38.113281 114.699219 C 37.945312 114.816406 37.765625 114.898438 37.566406 114.945312 C 37.371094 114.992188 37.171875 115 36.96875 114.96875 C 36.769531 114.933594 36.582031 114.867188 36.410156 114.757812 C 36.238281 114.652344 36.089844 114.515625 35.972656 114.351562 C 35.851562 114.1875 35.769531 114.003906 35.722656 113.808594 Z M 35.722656 113.808594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 37.578125 123.8125 C 37.578125 123.632812 37.425781 123.480469 37.246094 123.480469 C 37.066406 123.480469 36.914062 123.632812 36.914062 123.8125 C 36.914062 123.992188 37.066406 124.140625 37.246094 124.140625 C 37.425781 124.140625 37.578125 123.992188 37.578125 123.8125 Z M 37.578125 123.8125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 48.621094 106.226562 C 48.597656 106.082031 48.601562 105.9375 48.636719 105.792969 C 48.667969 105.652344 48.730469 105.519531 48.816406 105.398438 C 48.902344 105.28125 49.007812 105.179688 49.132812 105.101562 C 49.257812 105.027344 49.394531 104.976562 49.539062 104.953125 C 49.683594 104.929688 49.828125 104.933594 49.972656 104.96875 C 50.117188 105 50.25 105.0625 50.367188 105.148438 C 50.488281 105.234375 50.585938 105.339844 50.664062 105.464844 C 50.742188 105.589844 50.792969 105.726562 50.816406 105.871094 C 50.839844 106.015625 50.835938 106.160156 50.800781 106.304688 C 50.765625 106.449219 50.707031 106.582031 50.621094 106.699219 C 50.535156 106.820312 50.429688 106.917969 50.300781 106.996094 C 50.175781 107.074219 50.042969 107.125 49.894531 107.148438 C 49.75 107.171875 49.605469 107.164062 49.460938 107.132812 C 49.320312 107.097656 49.1875 107.039062 49.066406 106.953125 C 48.949219 106.867188 48.847656 106.757812 48.773438 106.632812 C 48.695312 106.507812 48.644531 106.375 48.621094 106.226562 Z M 48.621094 106.226562 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 57.597656 99.824219 C 57.597656 99.613281 57.445312 99.464844 57.234375 99.464844 C 57.023438 99.464844 56.875 99.613281 56.875 99.824219 C 56.875 100.035156 57.023438 100.183594 57.234375 100.183594 C 57.445312 100.183594 57.597656 100.035156 57.597656 99.824219 Z M 57.597656 99.824219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 57.597656 115.152344 C 57.597656 114.945312 57.445312 114.792969 57.234375 114.792969 C 57.023438 114.792969 56.875 114.945312 56.875 115.152344 C 56.875 115.363281 57.023438 115.515625 57.234375 115.515625 C 57.445312 115.515625 57.597656 115.363281 57.597656 115.152344 Z M 57.597656 115.152344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 60.660156 101.808594 C 60.660156 101.597656 60.511719 101.445312 60.300781 101.445312 C 60.089844 101.445312 59.941406 101.597656 59.941406 101.808594 C 59.941406 102.019531 60.089844 102.167969 60.300781 102.167969 C 60.511719 102.167969 60.660156 101.988281 60.660156 101.808594 Z M 60.660156 101.808594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 60.660156 108.421875 C 60.660156 108.210938 60.511719 108.058594 60.300781 108.058594 C 60.089844 108.058594 59.941406 108.210938 59.941406 108.421875 C 59.941406 108.632812 60.089844 108.78125 60.300781 108.78125 C 60.511719 108.78125 60.660156 108.632812 60.660156 108.421875 Z M 60.660156 108.421875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 68.148438 116.835938 C 68.148438 116.589844 68.234375 116.375 68.410156 116.199219 C 68.585938 116.023438 68.800781 115.9375 69.046875 115.9375 C 69.296875 115.9375 69.511719 116.023438 69.6875 116.199219 C 69.863281 116.375 69.949219 116.589844 69.949219 116.835938 C 69.949219 117.085938 69.863281 117.300781 69.6875 117.476562 C 69.511719 117.652344 69.296875 117.738281 69.046875 117.738281 C 68.800781 117.738281 68.585938 117.652344 68.410156 117.476562 C 68.234375 117.300781 68.148438 117.085938 68.148438 116.835938 Z M 68.148438 116.835938 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 78.605469 124.203125 C 78.605469 123.992188 78.457031 123.839844 78.246094 123.839844 C 78.035156 123.839844 77.886719 123.992188 77.886719 124.203125 C 77.886719 124.414062 78.035156 124.5625 78.246094 124.5625 C 78.457031 124.5625 78.605469 124.414062 78.605469 124.203125 Z M 78.605469 124.203125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 77.886719 132.679688 C 77.886719 132.578125 77.921875 132.496094 77.992188 132.421875 C 78.0625 132.351562 78.148438 132.316406 78.246094 132.316406 C 78.347656 132.316406 78.429688 132.351562 78.5 132.421875 C 78.570312 132.496094 78.605469 132.578125 78.605469 132.679688 C 78.605469 132.777344 78.570312 132.863281 78.5 132.933594 C 78.429688 133.003906 78.347656 133.039062 78.246094 133.039062 C 78.148438 133.039062 78.0625 133.003906 77.992188 132.933594 C 77.921875 132.863281 77.886719 132.777344 77.886719 132.679688 Z M 77.886719 132.679688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 80.5 113.199219 C 80.5 112.988281 80.351562 112.839844 80.140625 112.839844 C 79.929688 112.839844 79.78125 112.988281 79.78125 113.199219 C 79.78125 113.410156 79.929688 113.5625 80.140625 113.5625 C 80.351562 113.5625 80.5 113.410156 80.5 113.199219 Z M 80.5 113.199219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 80.5 119.785156 C 80.5 119.574219 80.351562 119.421875 80.140625 119.421875 C 79.929688 119.421875 79.78125 119.574219 79.78125 119.785156 C 79.78125 119.992188 79.929688 120.144531 80.140625 120.144531 C 80.351562 120.144531 80.5 119.964844 80.5 119.785156 Z M 80.5 119.785156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 87.476562 126.636719 C 87.476562 126.496094 87.5 126.363281 87.554688 126.234375 C 87.609375 126.105469 87.683594 125.992188 87.78125 125.894531 C 87.882812 125.792969 87.996094 125.71875 88.125 125.664062 C 88.253906 125.613281 88.386719 125.585938 88.527344 125.585938 C 88.667969 125.585938 88.800781 125.613281 88.929688 125.664062 C 89.058594 125.71875 89.171875 125.792969 89.269531 125.894531 C 89.371094 125.992188 89.445312 126.105469 89.5 126.234375 C 89.550781 126.363281 89.578125 126.496094 89.578125 126.636719 C 89.578125 126.777344 89.550781 126.910156 89.5 127.039062 C 89.445312 127.167969 89.371094 127.28125 89.269531 127.378906 C 89.171875 127.480469 89.058594 127.554688 88.929688 127.609375 C 88.800781 127.664062 88.667969 127.6875 88.527344 127.6875 C 88.386719 127.6875 88.253906 127.664062 88.125 127.609375 C 87.996094 127.554688 87.882812 127.480469 87.78125 127.378906 C 87.683594 127.28125 87.609375 127.167969 87.554688 127.039062 C 87.5 126.910156 87.476562 126.777344 87.476562 126.636719 Z M 87.476562 126.636719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 91.503906 115.035156 C 91.503906 114.734375 91.261719 114.460938 90.933594 114.460938 C 90.601562 114.460938 90.359375 114.703125 90.359375 115.035156 C 90.359375 115.335938 90.601562 115.605469 90.933594 115.605469 C 91.261719 115.605469 91.503906 115.363281 91.503906 115.035156 Z M 91.503906 115.035156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 94.058594 107.667969 C 94.058594 107.488281 93.90625 107.339844 93.726562 107.339844 C 93.546875 107.339844 93.398438 107.488281 93.398438 107.667969 C 93.398438 107.851562 93.546875 108 93.726562 108 C 93.90625 108 94.058594 107.851562 94.058594 107.667969 Z M 94.058594 107.667969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 95.796875 99.050781 C 95.765625 98.914062 95.761719 98.777344 95.785156 98.640625 C 95.804688 98.5 95.851562 98.375 95.925781 98.253906 C 96 98.136719 96.09375 98.035156 96.207031 97.953125 C 96.320312 97.871094 96.445312 97.816406 96.578125 97.785156 C 96.714844 97.753906 96.851562 97.746094 96.992188 97.769531 C 97.128906 97.792969 97.257812 97.839844 97.375 97.914062 C 97.492188 97.988281 97.59375 98.078125 97.675781 98.191406 C 97.757812 98.304688 97.8125 98.429688 97.84375 98.566406 C 97.878906 98.703125 97.882812 98.839844 97.859375 98.976562 C 97.839844 99.113281 97.789062 99.242188 97.714844 99.359375 C 97.644531 99.480469 97.550781 99.578125 97.4375 99.660156 C 97.324219 99.742188 97.199219 99.800781 97.0625 99.832031 C 96.929688 99.863281 96.789062 99.867188 96.652344 99.847656 C 96.515625 99.824219 96.386719 99.777344 96.269531 99.703125 C 96.148438 99.628906 96.050781 99.535156 95.96875 99.421875 C 95.886719 99.308594 95.828125 99.1875 95.796875 99.050781 Z M 95.796875 99.050781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 100.910156 102.230469 C 100.910156 101.929688 100.671875 101.65625 100.339844 101.65625 C 100.007812 101.65625 99.769531 101.898438 99.769531 102.230469 C 99.769531 102.53125 100.007812 102.800781 100.339844 102.800781 C 100.671875 102.800781 100.910156 102.53125 100.910156 102.230469 Z M 100.910156 102.230469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 86.0625 99.523438 C 86.0625 99.34375 85.910156 99.191406 85.730469 99.191406 C 85.550781 99.191406 85.402344 99.34375 85.402344 99.523438 C 85.402344 99.703125 85.550781 99.855469 85.730469 99.855469 C 85.910156 99.855469 86.0625 99.671875 86.0625 99.523438 Z M 86.0625 99.523438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 86.0625 108.75 C 86.0625 108.570312 85.910156 108.421875 85.730469 108.421875 C 85.550781 108.421875 85.402344 108.570312 85.402344 108.75 C 85.402344 108.933594 85.550781 109.082031 85.730469 109.082031 C 85.910156 109.082031 86.0625 108.933594 86.0625 108.75 Z M 86.0625 108.75 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 100.910156 91.496094 C 100.910156 91.195312 100.671875 90.925781 100.339844 90.925781 C 100.007812 90.925781 99.769531 91.167969 99.769531 91.496094 C 99.769531 91.796875 100.007812 92.070312 100.339844 92.070312 C 100.671875 92.070312 100.910156 91.796875 100.910156 91.496094 Z M 100.910156 91.496094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 120.328125 77.308594 C 120.328125 77.152344 120.386719 77.015625 120.496094 76.90625 C 120.609375 76.792969 120.742188 76.738281 120.902344 76.738281 C 121.058594 76.738281 121.191406 76.792969 121.304688 76.90625 C 121.417969 77.015625 121.472656 77.152344 121.472656 77.308594 C 121.472656 77.46875 121.417969 77.601562 121.304688 77.714844 C 121.191406 77.824219 121.058594 77.878906 120.902344 77.878906 C 120.742188 77.878906 120.609375 77.824219 120.496094 77.714844 C 120.386719 77.601562 120.328125 77.46875 120.328125 77.308594 Z M 120.328125 77.308594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 140.621094 82.601562 C 140.621094 82.300781 140.378906 82.027344 140.046875 82.027344 C 139.75 82.027344 139.476562 82.269531 139.476562 82.601562 C 139.476562 82.902344 139.71875 83.171875 140.046875 83.171875 C 140.378906 83.171875 140.621094 82.902344 140.621094 82.601562 Z M 140.621094 82.601562 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 86.0625 82.359375 C 86.0625 82.179688 85.910156 82.027344 85.730469 82.027344 C 85.550781 82.027344 85.402344 82.179688 85.402344 82.359375 C 85.402344 82.539062 85.550781 82.691406 85.730469 82.691406 C 85.910156 82.691406 86.0625 82.539062 86.0625 82.359375 Z M 86.0625 82.359375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 103.738281 106.859375 C 103.738281 106.675781 103.585938 106.558594 103.4375 106.558594 C 103.285156 106.558594 103.136719 106.707031 103.136719 106.859375 C 103.136719 107.039062 103.285156 107.160156 103.4375 107.160156 C 103.585938 107.160156 103.738281 107.007812 103.738281 106.859375 Z M 103.738281 106.859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 103.738281 111.757812 C 103.738281 111.578125 103.585938 111.457031 103.4375 111.457031 C 103.285156 111.457031 103.136719 111.605469 103.136719 111.757812 C 103.136719 111.9375 103.285156 112.058594 103.4375 112.058594 C 103.585938 112.058594 103.738281 111.90625 103.738281 111.757812 Z M 103.738281 111.757812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 103.738281 127.871094 C 103.738281 127.6875 103.585938 127.570312 103.4375 127.570312 C 103.285156 127.570312 103.136719 127.71875 103.136719 127.871094 C 103.136719 128.050781 103.285156 128.171875 103.4375 128.171875 C 103.585938 128.171875 103.738281 128.019531 103.738281 127.871094 Z M 103.738281 127.871094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 106.113281 121.765625 C 106.113281 121.609375 106.167969 121.476562 106.277344 121.363281 C 106.390625 121.25 106.523438 121.195312 106.683594 121.195312 C 106.839844 121.195312 106.976562 121.25 107.085938 121.363281 C 107.199219 121.476562 107.253906 121.609375 107.253906 121.765625 C 107.253906 121.925781 107.199219 122.058594 107.085938 122.171875 C 106.976562 122.28125 106.839844 122.339844 106.683594 122.339844 C 106.523438 122.339844 106.390625 122.28125 106.277344 122.171875 C 106.167969 122.058594 106.113281 121.925781 106.113281 121.765625 Z M 106.113281 121.765625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 115.175781 119.214844 C 115.144531 119.042969 115.152344 118.871094 115.191406 118.699219 C 115.234375 118.527344 115.304688 118.371094 115.40625 118.230469 C 115.507812 118.085938 115.636719 117.96875 115.785156 117.878906 C 115.933594 117.785156 116.09375 117.726562 116.269531 117.699219 C 116.441406 117.667969 116.613281 117.675781 116.785156 117.714844 C 116.953125 117.757812 117.109375 117.828125 117.253906 117.929688 C 117.394531 118.03125 117.511719 118.160156 117.605469 118.308594 C 117.695312 118.457031 117.757812 118.617188 117.785156 118.792969 C 117.8125 118.964844 117.808594 119.136719 117.765625 119.308594 C 117.726562 119.476562 117.65625 119.632812 117.550781 119.777344 C 117.449219 119.917969 117.324219 120.035156 117.175781 120.128906 C 117.027344 120.21875 116.863281 120.28125 116.691406 120.308594 C 116.519531 120.335938 116.347656 120.332031 116.175781 120.289062 C 116.003906 120.25 115.847656 120.179688 115.707031 120.074219 C 115.5625 119.972656 115.445312 119.847656 115.355469 119.699219 C 115.261719 119.550781 115.203125 119.386719 115.175781 119.214844 Z M 115.175781 119.214844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 122.402344 109.984375 C 122.402344 109.683594 122.164062 109.414062 121.832031 109.414062 C 121.503906 109.414062 121.261719 109.652344 121.261719 109.984375 C 121.261719 110.285156 121.503906 110.554688 121.832031 110.554688 C 122.164062 110.554688 122.402344 110.316406 122.402344 109.984375 Z M 122.402344 109.984375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 126.003906 C 133.105469 125.828125 133.140625 125.652344 133.207031 125.488281 C 133.277344 125.324219 133.375 125.175781 133.5 125.050781 C 133.628906 124.921875 133.773438 124.824219 133.941406 124.757812 C 134.105469 124.6875 134.277344 124.652344 134.457031 124.652344 C 134.636719 124.652344 134.808594 124.6875 134.976562 124.757812 C 135.140625 124.824219 135.289062 124.921875 135.414062 125.050781 C 135.539062 125.175781 135.640625 125.324219 135.707031 125.488281 C 135.777344 125.652344 135.808594 125.828125 135.808594 126.003906 C 135.808594 126.183594 135.777344 126.359375 135.707031 126.523438 C 135.640625 126.6875 135.539062 126.835938 135.414062 126.960938 C 135.289062 127.089844 135.140625 127.1875 134.976562 127.253906 C 134.808594 127.324219 134.636719 127.359375 134.457031 127.359375 C 134.277344 127.359375 134.105469 127.324219 133.941406 127.253906 C 133.773438 127.1875 133.628906 127.089844 133.5 126.960938 C 133.375 126.835938 133.277344 126.6875 133.207031 126.523438 C 133.140625 126.359375 133.105469 126.183594 133.105469 126.003906 Z M 133.105469 126.003906 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 123.574219 105.714844 C 123.574219 105.535156 123.425781 105.386719 123.246094 105.386719 C 123.066406 105.386719 122.914062 105.535156 122.914062 105.714844 C 122.914062 105.894531 123.066406 106.046875 123.246094 106.046875 C 123.457031 106.046875 123.574219 105.894531 123.574219 105.714844 Z M 123.574219 105.714844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 123.574219 115.9375 C 123.574219 115.753906 123.425781 115.605469 123.246094 115.605469 C 123.066406 115.605469 122.914062 115.753906 122.914062 115.9375 C 122.914062 116.117188 123.066406 116.265625 123.246094 116.265625 C 123.457031 116.265625 123.574219 116.117188 123.574219 115.9375 Z M 123.574219 115.9375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 145.519531 112.117188 C 145.519531 111.9375 145.371094 111.789062 145.1875 111.789062 C 145.007812 111.789062 144.859375 111.9375 144.859375 112.117188 C 144.859375 112.296875 145.007812 112.449219 145.1875 112.449219 C 145.371094 112.449219 145.519531 112.296875 145.519531 112.117188 Z M 145.519531 112.117188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 144.859375 119.664062 C 144.859375 119.570312 144.890625 119.492188 144.953125 119.429688 C 145.019531 119.363281 145.097656 119.332031 145.1875 119.332031 C 145.28125 119.332031 145.359375 119.363281 145.421875 119.429688 C 145.488281 119.492188 145.519531 119.570312 145.519531 119.664062 C 145.519531 119.753906 145.488281 119.832031 145.421875 119.898438 C 145.359375 119.960938 145.28125 119.992188 145.1875 119.992188 C 145.097656 119.992188 145.019531 119.960938 144.953125 119.898438 C 144.890625 119.832031 144.859375 119.753906 144.859375 119.664062 Z M 144.859375 119.664062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 156.101562 118.972656 C 156.101562 118.761719 155.949219 118.609375 155.738281 118.609375 C 155.527344 118.609375 155.378906 118.761719 155.378906 118.972656 C 155.378906 119.183594 155.527344 119.332031 155.738281 119.332031 C 155.949219 119.332031 156.101562 119.183594 156.101562 118.972656 Z M 156.101562 118.972656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 151.441406 128.410156 C 151.441406 128.109375 151.199219 127.839844 150.871094 127.839844 C 150.539062 127.839844 150.300781 128.078125 150.300781 128.410156 C 150.300781 128.710938 150.539062 128.980469 150.871094 128.980469 C 151.199219 128.980469 151.441406 128.710938 151.441406 128.410156 Z M 151.441406 128.410156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 160.609375 138.660156 C 160.609375 138.449219 160.457031 138.300781 160.25 138.300781 C 160.039062 138.300781 159.886719 138.449219 159.886719 138.660156 C 159.886719 138.871094 160.039062 139.019531 160.25 139.019531 C 160.457031 139.019531 160.609375 138.871094 160.609375 138.660156 Z M 160.609375 138.660156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 167.433594 115.574219 C 167.433594 115.363281 167.253906 115.183594 167.042969 115.183594 C 166.832031 115.183594 166.652344 115.363281 166.652344 115.574219 C 166.652344 115.785156 166.832031 115.964844 167.042969 115.964844 C 167.253906 115.964844 167.433594 115.785156 167.433594 115.574219 Z M 167.433594 115.574219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 173.535156 128.742188 C 173.535156 128.261719 173.144531 127.839844 172.632812 127.839844 C 172.121094 127.839844 171.730469 128.230469 171.730469 128.742188 C 171.730469 129.222656 172.121094 129.644531 172.632812 129.644531 C 173.144531 129.644531 173.535156 129.253906 173.535156 128.742188 Z M 173.535156 128.742188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 179.003906 139.050781 C 179.003906 138.953125 179.039062 138.867188 179.109375 138.796875 C 179.183594 138.726562 179.265625 138.691406 179.367188 138.691406 C 179.464844 138.691406 179.550781 138.726562 179.621094 138.796875 C 179.691406 138.867188 179.726562 138.953125 179.726562 139.050781 C 179.726562 139.152344 179.691406 139.234375 179.621094 139.304688 C 179.550781 139.375 179.464844 139.414062 179.367188 139.414062 C 179.265625 139.414062 179.183594 139.375 179.109375 139.304688 C 179.039062 139.234375 179.003906 139.152344 179.003906 139.050781 Z M 179.003906 139.050781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 179.726562 145.304688 C 179.726562 145.09375 179.578125 144.941406 179.367188 144.941406 C 179.15625 144.941406 179.003906 145.09375 179.003906 145.304688 C 179.003906 145.515625 179.15625 145.664062 179.367188 145.664062 C 179.578125 145.664062 179.726562 145.515625 179.726562 145.304688 Z M 179.726562 145.304688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#189c43b6f4)">
        <path
          fill={fill}
          d="M 184.472656 149.691406 C 184.414062 149.449219 184.449219 149.222656 184.582031 149.011719 C 184.710938 148.800781 184.898438 148.664062 185.140625 148.609375 C 185.382812 148.550781 185.609375 148.589844 185.824219 148.71875 C 186.035156 148.851562 186.167969 149.035156 186.226562 149.277344 C 186.285156 149.519531 186.246094 149.75 186.117188 149.960938 C 185.984375 150.171875 185.796875 150.304688 185.554688 150.363281 C 185.3125 150.421875 185.085938 150.382812 184.875 150.253906 C 184.664062 150.121094 184.527344 149.933594 184.472656 149.691406 Z M 184.472656 149.691406 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill={fill}
        d="M 192.019531 121.917969 C 192.019531 121.617188 191.78125 121.347656 191.449219 121.347656 C 191.148438 121.347656 190.878906 121.585938 190.878906 121.917969 C 190.878906 122.21875 191.121094 122.488281 191.449219 122.488281 C 191.78125 122.488281 192.019531 122.25 192.019531 121.917969 Z M 192.019531 121.917969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 202.722656 135.683594 C 202.722656 135.453125 202.804688 135.253906 202.96875 135.089844 C 203.132812 134.925781 203.332031 134.84375 203.5625 134.84375 C 203.796875 134.84375 203.996094 134.925781 204.160156 135.089844 C 204.324219 135.253906 204.40625 135.453125 204.40625 135.683594 C 204.40625 135.917969 204.324219 136.117188 204.160156 136.28125 C 203.996094 136.445312 203.796875 136.527344 203.5625 136.527344 C 203.332031 136.527344 203.132812 136.445312 202.96875 136.28125 C 202.804688 136.117188 202.722656 135.917969 202.722656 135.683594 Z M 202.722656 135.683594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 216.308594 145.875 C 216.308594 145.574219 216.070312 145.304688 215.738281 145.304688 C 215.4375 145.304688 215.167969 145.542969 215.167969 145.875 C 215.167969 146.175781 215.40625 146.445312 215.738281 146.445312 C 216.039062 146.445312 216.308594 146.175781 216.308594 145.875 Z M 216.308594 145.875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 224.335938 134 C 224.335938 133.808594 224.402344 133.648438 224.539062 133.511719 C 224.671875 133.378906 224.835938 133.308594 225.027344 133.308594 C 225.21875 133.308594 225.378906 133.378906 225.515625 133.511719 C 225.648438 133.648438 225.71875 133.808594 225.71875 134 C 225.71875 134.191406 225.648438 134.355469 225.515625 134.492188 C 225.378906 134.625 225.21875 134.691406 225.027344 134.691406 C 224.835938 134.691406 224.671875 134.625 224.539062 134.492188 C 224.402344 134.355469 224.335938 134.191406 224.335938 134 Z M 224.335938 134 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 225.328125 141.425781 C 225.328125 141.246094 225.207031 141.125 225.027344 141.125 C 224.847656 141.125 224.726562 141.246094 224.726562 141.425781 C 224.726562 141.605469 224.847656 141.726562 225.027344 141.726562 C 225.175781 141.726562 225.328125 141.605469 225.328125 141.425781 Z M 225.328125 141.425781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 239.003906 118.792969 C 239.003906 118.492188 238.761719 118.21875 238.433594 118.21875 C 238.132812 118.21875 237.863281 118.460938 237.863281 118.792969 C 237.863281 119.09375 238.101562 119.363281 238.433594 119.363281 C 238.734375 119.363281 239.003906 119.121094 239.003906 118.792969 Z M 239.003906 118.792969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 234.433594 105.234375 C 234.433594 105.074219 234.515625 104.996094 234.675781 104.996094 C 234.835938 104.996094 234.914062 105.074219 234.914062 105.234375 C 234.914062 105.394531 234.835938 105.476562 234.675781 105.476562 C 234.515625 105.476562 234.433594 105.394531 234.433594 105.234375 Z M 234.433594 105.234375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 234.433594 112.839844 C 234.433594 112.679688 234.515625 112.597656 234.675781 112.597656 C 234.835938 112.597656 234.914062 112.679688 234.914062 112.839844 C 234.914062 113 234.835938 113.082031 234.675781 113.082031 C 234.515625 113.082031 234.433594 113 234.433594 112.839844 Z M 234.433594 112.839844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 234.945312 126.636719 C 234.945312 126.488281 234.824219 126.394531 234.707031 126.394531 C 234.554688 126.394531 234.464844 126.515625 234.464844 126.636719 C 234.464844 126.789062 234.585938 126.878906 234.707031 126.878906 C 234.824219 126.878906 234.945312 126.789062 234.945312 126.636719 Z M 234.945312 126.636719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 221 107.941406 C 221 107.640625 220.757812 107.367188 220.425781 107.367188 C 220.125 107.367188 219.855469 107.609375 219.855469 107.941406 C 219.855469 108.242188 220.097656 108.511719 220.425781 108.511719 C 220.757812 108.511719 221 108.242188 221 107.941406 Z M 221 107.941406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 221 119.664062 C 221 119.363281 220.757812 119.09375 220.425781 119.09375 C 220.125 119.09375 219.855469 119.332031 219.855469 119.664062 C 219.855469 119.964844 220.097656 120.234375 220.425781 120.234375 C 220.757812 120.234375 221 119.992188 221 119.664062 Z M 221 119.664062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 207.082031 126.636719 C 207.082031 126.441406 207.117188 126.253906 207.191406 126.074219 C 207.269531 125.894531 207.375 125.734375 207.511719 125.59375 C 207.652344 125.457031 207.808594 125.351562 207.992188 125.277344 C 208.171875 125.203125 208.359375 125.164062 208.554688 125.164062 C 208.75 125.164062 208.9375 125.203125 209.117188 125.277344 C 209.296875 125.351562 209.457031 125.457031 209.59375 125.59375 C 209.734375 125.734375 209.839844 125.894531 209.914062 126.074219 C 209.988281 126.253906 210.027344 126.441406 210.027344 126.636719 C 210.027344 126.832031 209.988281 127.019531 209.914062 127.199219 C 209.839844 127.382812 209.734375 127.539062 209.59375 127.679688 C 209.457031 127.816406 209.296875 127.921875 209.117188 127.996094 C 208.9375 128.074219 208.75 128.109375 208.554688 128.109375 C 208.359375 128.109375 208.171875 128.074219 207.992188 127.996094 C 207.808594 127.921875 207.652344 127.816406 207.511719 127.679688 C 207.375 127.539062 207.269531 127.382812 207.191406 127.199219 C 207.117188 127.019531 207.082031 126.832031 207.082031 126.636719 Z M 207.082031 126.636719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 198.875 111.515625 C 198.875 111.335938 198.722656 111.214844 198.574219 111.214844 C 198.394531 111.214844 198.273438 111.367188 198.273438 111.515625 C 198.273438 111.667969 198.425781 111.816406 198.574219 111.816406 C 198.722656 111.816406 198.875 111.699219 198.875 111.515625 Z M 198.875 111.515625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 198.242188 126.003906 C 198.242188 125.921875 198.273438 125.851562 198.332031 125.792969 C 198.390625 125.734375 198.460938 125.703125 198.542969 125.703125 C 198.628906 125.703125 198.699219 125.734375 198.757812 125.792969 C 198.816406 125.851562 198.84375 125.921875 198.84375 126.003906 C 198.84375 126.089844 198.816406 126.160156 198.757812 126.21875 C 198.699219 126.277344 198.628906 126.304688 198.542969 126.304688 C 198.460938 126.304688 198.390625 126.277344 198.332031 126.21875 C 198.273438 126.160156 198.242188 126.089844 198.242188 126.003906 Z M 198.242188 126.003906 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 175.546875 117.347656 C 175.546875 117.257812 175.582031 117.179688 175.644531 117.113281 C 175.710938 117.050781 175.789062 117.019531 175.878906 117.019531 C 175.972656 117.019531 176.046875 117.050781 176.113281 117.113281 C 176.179688 117.179688 176.210938 117.257812 176.210938 117.347656 C 176.210938 117.441406 176.179688 117.519531 176.113281 117.582031 C 176.046875 117.648438 175.972656 117.679688 175.878906 117.679688 C 175.789062 117.679688 175.710938 117.648438 175.644531 117.582031 C 175.582031 117.519531 175.546875 117.441406 175.546875 117.347656 Z M 175.546875 117.347656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 176.210938 122.820312 C 176.210938 122.640625 176.058594 122.488281 175.878906 122.488281 C 175.699219 122.488281 175.546875 122.640625 175.546875 122.820312 C 175.546875 123 175.699219 123.148438 175.878906 123.148438 C 176.058594 123.148438 176.210938 123 176.210938 122.820312 Z M 176.210938 122.820312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 77.132812 77.941406 C 77.132812 77.792969 77.164062 77.652344 77.21875 77.515625 C 77.277344 77.378906 77.355469 77.257812 77.460938 77.15625 C 77.566406 77.050781 77.683594 76.96875 77.820312 76.914062 C 77.957031 76.855469 78.097656 76.828125 78.246094 76.828125 C 78.394531 76.828125 78.535156 76.855469 78.671875 76.914062 C 78.808594 76.96875 78.929688 77.050781 79.03125 77.15625 C 79.136719 77.257812 79.21875 77.378906 79.273438 77.515625 C 79.332031 77.652344 79.359375 77.792969 79.359375 77.941406 C 79.359375 78.089844 79.332031 78.230469 79.273438 78.367188 C 79.21875 78.503906 79.136719 78.621094 79.03125 78.726562 C 78.929688 78.832031 78.808594 78.910156 78.671875 78.96875 C 78.535156 79.023438 78.394531 79.054688 78.246094 79.054688 C 78.097656 79.054688 77.957031 79.023438 77.820312 78.96875 C 77.683594 78.910156 77.566406 78.832031 77.460938 78.726562 C 77.355469 78.621094 77.277344 78.503906 77.21875 78.367188 C 77.164062 78.230469 77.132812 78.089844 77.132812 77.941406 Z M 77.132812 77.941406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 80.5 77.941406 C 80.5 79.203125 79.480469 80.226562 78.214844 80.226562 C 76.953125 80.226562 75.933594 79.203125 75.933594 77.941406 C 75.933594 76.679688 76.953125 75.65625 78.214844 75.65625 C 79.507812 75.65625 80.5 76.679688 80.5 77.941406 Z M 80.382812 77.941406 C 80.382812 76.769531 79.417969 75.804688 78.246094 75.804688 C 77.074219 75.804688 76.113281 76.769531 76.113281 77.941406 C 76.113281 79.113281 77.074219 80.074219 78.246094 80.074219 C 79.417969 80.074219 80.382812 79.113281 80.382812 77.941406 Z M 80.382812 77.941406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 50.109375 89.511719 C 50.109375 89.273438 49.929688 89.09375 49.691406 89.09375 C 49.449219 89.09375 49.269531 89.273438 49.269531 89.511719 C 49.269531 89.753906 49.449219 89.933594 49.691406 89.933594 C 49.929688 89.933594 50.109375 89.753906 50.109375 89.511719 Z M 50.109375 89.511719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 50.5625 89.511719 C 50.5625 89.996094 50.171875 90.386719 49.691406 90.386719 C 49.210938 90.386719 48.820312 89.996094 48.820312 89.511719 C 48.820312 89.03125 49.210938 88.640625 49.691406 88.640625 C 50.171875 88.640625 50.5625 89.03125 50.5625 89.511719 Z M 50.5 89.511719 C 50.5 89.0625 50.140625 88.671875 49.660156 88.671875 C 49.210938 88.671875 48.820312 89.03125 48.820312 89.511719 C 48.820312 89.964844 49.179688 90.355469 49.660156 90.355469 C 50.140625 90.355469 50.5 89.964844 50.5 89.511719 Z M 50.5 89.511719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 69.46875 87.320312 C 69.46875 87.078125 69.289062 86.898438 69.046875 86.898438 C 68.808594 86.898438 68.628906 87.078125 68.628906 87.320312 C 68.628906 87.558594 68.808594 87.738281 69.046875 87.738281 C 69.289062 87.738281 69.46875 87.558594 69.46875 87.320312 Z M 69.46875 87.320312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 69.890625 87.320312 C 69.890625 87.800781 69.5 88.191406 69.019531 88.191406 C 68.539062 88.191406 68.148438 87.800781 68.148438 87.320312 C 68.148438 86.839844 68.539062 86.449219 69.019531 86.449219 C 69.5 86.449219 69.890625 86.839844 69.890625 87.320312 Z M 69.859375 87.320312 C 69.859375 86.867188 69.5 86.476562 69.019531 86.476562 C 68.566406 86.476562 68.175781 86.839844 68.175781 87.320312 C 68.175781 87.769531 68.539062 88.160156 69.019531 88.160156 C 69.46875 88.160156 69.859375 87.800781 69.859375 87.320312 Z M 69.859375 87.320312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 75.179688 65.347656 C 75.179688 65.105469 75 64.925781 74.761719 64.925781 C 74.519531 64.925781 74.339844 65.105469 74.339844 65.347656 C 74.339844 65.585938 74.519531 65.765625 74.761719 65.765625 C 75 65.765625 75.179688 65.585938 75.179688 65.347656 Z M 75.179688 65.347656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 75.632812 65.347656 C 75.632812 65.828125 75.242188 66.21875 74.761719 66.21875 C 74.277344 66.21875 73.886719 65.828125 73.886719 65.347656 C 73.886719 64.863281 74.277344 64.472656 74.761719 64.472656 C 75.242188 64.472656 75.632812 64.863281 75.632812 65.347656 Z M 75.570312 65.347656 C 75.570312 64.894531 75.210938 64.503906 74.730469 64.503906 C 74.277344 64.503906 73.886719 64.863281 73.886719 65.347656 C 73.886719 65.796875 74.25 66.1875 74.730469 66.1875 C 75.210938 66.1875 75.570312 65.828125 75.570312 65.347656 Z M 75.570312 65.347656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 117.113281 85.457031 C 117.113281 85.09375 116.8125 84.824219 116.480469 84.824219 C 116.121094 84.824219 115.851562 85.125 115.851562 85.457031 C 115.851562 85.816406 116.152344 86.085938 116.480469 86.085938 C 116.84375 86.117188 117.113281 85.816406 117.113281 85.457031 Z M 117.113281 85.457031 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 117.804688 85.457031 C 117.804688 86.175781 117.203125 86.777344 116.480469 86.777344 C 115.761719 86.777344 115.160156 86.175781 115.160156 85.457031 C 115.160156 84.734375 115.761719 84.132812 116.480469 84.132812 C 117.203125 84.164062 117.804688 84.734375 117.804688 85.457031 Z M 117.714844 85.457031 C 117.714844 84.765625 117.171875 84.222656 116.480469 84.222656 C 115.789062 84.222656 115.25 84.765625 115.25 85.457031 C 115.25 86.148438 115.789062 86.6875 116.480469 86.6875 C 117.144531 86.71875 117.714844 86.148438 117.714844 85.457031 Z M 117.714844 85.457031 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 135.601562 93 C 135.601562 92.789062 135.417969 92.609375 135.210938 92.609375 C 135 92.609375 134.816406 92.789062 134.816406 93 C 134.816406 93.210938 135 93.390625 135.210938 93.390625 C 135.417969 93.421875 135.601562 93.242188 135.601562 93 Z M 135.601562 93 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 136.019531 93 C 136.019531 93.453125 135.660156 93.8125 135.210938 93.8125 C 134.757812 93.8125 134.398438 93.453125 134.398438 93 C 134.398438 92.550781 134.757812 92.1875 135.210938 92.1875 C 135.660156 92.1875 136.019531 92.550781 136.019531 93 Z M 135.960938 93 C 135.960938 92.578125 135.628906 92.25 135.210938 92.25 C 134.789062 92.25 134.457031 92.578125 134.457031 93 C 134.457031 93.421875 134.789062 93.753906 135.210938 93.753906 C 135.628906 93.78125 135.960938 93.421875 135.960938 93 Z M 135.960938 93 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 151.351562 110.617188 C 151.351562 110.375 151.140625 110.164062 150.898438 110.164062 C 150.660156 110.164062 150.449219 110.375 150.449219 110.617188 C 150.449219 110.855469 150.660156 111.066406 150.898438 111.066406 C 151.140625 111.066406 151.351562 110.886719 151.351562 110.617188 Z M 151.351562 110.617188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 151.800781 110.617188 C 151.800781 111.125 151.382812 111.515625 150.898438 111.515625 C 150.390625 111.515625 150 111.097656 150 110.617188 C 150 110.105469 150.417969 109.714844 150.898438 109.714844 C 151.382812 109.714844 151.800781 110.132812 151.800781 110.617188 Z M 151.742188 110.617188 C 151.742188 110.132812 151.351562 109.742188 150.871094 109.742188 C 150.390625 109.742188 150 110.132812 150 110.617188 C 150 111.097656 150.390625 111.488281 150.871094 111.488281 C 151.351562 111.488281 151.742188 111.097656 151.742188 110.617188 Z M 151.742188 110.617188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 232.089844 74.84375 C 232.089844 74.605469 232.175781 74.398438 232.34375 74.226562 C 232.515625 74.058594 232.722656 73.972656 232.960938 73.972656 C 233.203125 73.972656 233.410156 74.058594 233.578125 74.226562 C 233.75 74.398438 233.832031 74.605469 233.832031 74.84375 C 233.832031 75.085938 233.75 75.289062 233.578125 75.460938 C 233.410156 75.632812 233.203125 75.714844 232.960938 75.714844 C 232.722656 75.714844 232.515625 75.632812 232.34375 75.460938 C 232.175781 75.289062 232.089844 75.085938 232.089844 74.84375 Z M 232.089844 74.84375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 234.765625 74.84375 C 234.765625 75.835938 233.953125 76.648438 232.960938 76.648438 C 231.96875 76.648438 231.160156 75.835938 231.160156 74.84375 C 231.160156 73.851562 231.96875 73.042969 232.960938 73.042969 C 233.953125 73.042969 234.765625 73.851562 234.765625 74.84375 Z M 234.644531 74.84375 C 234.644531 73.914062 233.894531 73.160156 232.960938 73.160156 C 232.03125 73.160156 231.277344 73.914062 231.277344 74.84375 C 231.277344 75.777344 232.03125 76.527344 232.960938 76.527344 C 233.894531 76.527344 234.644531 75.777344 234.644531 74.84375 Z M 234.644531 74.84375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 258.964844 83.050781 C 258.964844 82.808594 258.78125 82.628906 258.542969 82.628906 C 258.300781 82.628906 258.121094 82.808594 258.121094 83.050781 C 258.121094 83.292969 258.300781 83.472656 258.542969 83.472656 C 258.78125 83.472656 258.964844 83.292969 258.964844 83.050781 Z M 258.964844 83.050781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 259.414062 83.050781 C 259.414062 83.53125 259.023438 83.921875 258.542969 83.921875 C 258.0625 83.921875 257.671875 83.53125 257.671875 83.050781 C 257.671875 82.570312 258.0625 82.179688 258.542969 82.179688 C 258.992188 82.179688 259.414062 82.570312 259.414062 83.050781 Z M 259.355469 83.050781 C 259.355469 82.601562 258.992188 82.210938 258.511719 82.210938 C 258.0625 82.210938 257.671875 82.570312 257.671875 83.050781 C 257.671875 83.5 258.03125 83.890625 258.511719 83.890625 C 258.964844 83.890625 259.355469 83.5 259.355469 83.050781 Z M 259.355469 83.050781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 127.832031 70.441406 C 127.789062 70.253906 127.816406 70.082031 127.917969 69.917969 C 128.019531 69.757812 128.160156 69.652344 128.347656 69.609375 C 128.535156 69.566406 128.707031 69.59375 128.871094 69.695312 C 129.03125 69.792969 129.136719 69.9375 129.179688 70.125 C 129.222656 70.308594 129.195312 70.484375 129.09375 70.644531 C 128.996094 70.808594 128.851562 70.910156 128.664062 70.957031 C 128.480469 71 128.304688 70.972656 128.144531 70.871094 C 127.980469 70.769531 127.878906 70.628906 127.832031 70.441406 Z M 127.832031 70.441406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 129.949219 70.277344 C 129.949219 71.058594 129.316406 71.71875 128.507812 71.71875 C 127.722656 71.71875 127.0625 71.085938 127.0625 70.277344 C 127.0625 69.492188 127.695312 68.832031 128.507812 68.832031 C 129.289062 68.832031 129.949219 69.464844 129.949219 70.277344 Z M 129.859375 70.277344 C 129.859375 69.523438 129.257812 68.921875 128.507812 68.921875 C 127.753906 68.921875 127.152344 69.523438 127.152344 70.277344 C 127.152344 71.027344 127.753906 71.628906 128.507812 71.628906 C 129.257812 71.597656 129.859375 70.996094 129.859375 70.277344 Z M 129.859375 70.277344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 158.503906 79.414062 C 158.503906 79.273438 158.554688 79.152344 158.65625 79.050781 C 158.753906 78.953125 158.875 78.902344 159.015625 78.902344 C 159.15625 78.902344 159.277344 78.953125 159.378906 79.050781 C 159.476562 79.152344 159.527344 79.273438 159.527344 79.414062 C 159.527344 79.554688 159.476562 79.675781 159.378906 79.773438 C 159.277344 79.875 159.15625 79.925781 159.015625 79.925781 C 158.875 79.925781 158.753906 79.875 158.65625 79.773438 C 158.554688 79.675781 158.503906 79.554688 158.503906 79.414062 Z M 158.503906 79.414062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 160.039062 79.414062 C 160.039062 79.984375 159.585938 80.464844 158.984375 80.464844 C 158.414062 80.464844 157.933594 80.015625 157.933594 79.414062 C 157.933594 78.84375 158.386719 78.363281 158.984375 78.363281 C 159.585938 78.390625 160.039062 78.84375 160.039062 79.414062 Z M 159.976562 79.414062 C 159.976562 78.871094 159.527344 78.453125 159.015625 78.453125 C 158.476562 78.453125 158.054688 78.902344 158.054688 79.414062 C 158.054688 79.953125 158.503906 80.375 159.015625 80.375 C 159.527344 80.375 159.976562 79.953125 159.976562 79.414062 Z M 159.976562 79.414062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 176.332031 96.578125 C 176.332031 96.335938 176.121094 96.125 175.878906 96.125 C 175.640625 96.125 175.429688 96.335938 175.429688 96.578125 C 175.429688 96.816406 175.640625 97.027344 175.878906 97.027344 C 176.121094 97.027344 176.332031 96.816406 176.332031 96.578125 Z M 176.332031 96.578125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 176.78125 96.578125 C 176.78125 97.089844 176.359375 97.480469 175.878906 97.480469 C 175.367188 97.480469 174.976562 97.058594 174.976562 96.578125 C 174.976562 96.066406 175.398438 95.675781 175.878906 95.675781 C 176.390625 95.675781 176.78125 96.066406 176.78125 96.578125 Z M 176.75 96.578125 C 176.75 96.097656 176.359375 95.707031 175.878906 95.707031 C 175.398438 95.707031 175.007812 96.097656 175.007812 96.578125 C 175.007812 97.058594 175.398438 97.449219 175.878906 97.449219 C 176.359375 97.449219 176.75 97.058594 176.75 96.578125 Z M 176.75 96.578125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 171.21875 76.527344 C 171.21875 76.289062 171.011719 76.078125 170.769531 76.078125 C 170.527344 76.078125 170.320312 76.289062 170.320312 76.527344 C 170.320312 76.769531 170.527344 76.980469 170.769531 76.980469 C 171.011719 76.980469 171.21875 76.769531 171.21875 76.527344 Z M 171.21875 76.527344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 171.699219 76.527344 C 171.699219 77.039062 171.28125 77.429688 170.800781 77.429688 C 170.320312 77.429688 169.898438 77.007812 169.898438 76.527344 C 169.898438 76.015625 170.320312 75.625 170.800781 75.625 C 171.28125 75.625 171.699219 76.015625 171.699219 76.527344 Z M 171.640625 76.527344 C 171.640625 76.046875 171.25 75.65625 170.769531 75.65625 C 170.289062 75.65625 169.898438 76.046875 169.898438 76.527344 C 169.898438 77.007812 170.289062 77.398438 170.769531 77.398438 C 171.25 77.398438 171.640625 76.980469 171.640625 76.527344 Z M 171.640625 76.527344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 197.101562 69.042969 C 197.101562 68.917969 197.144531 68.8125 197.234375 68.722656 C 197.320312 68.636719 197.425781 68.59375 197.550781 68.59375 C 197.675781 68.59375 197.78125 68.636719 197.871094 68.722656 C 197.960938 68.8125 198.003906 68.917969 198.003906 69.042969 C 198.003906 69.167969 197.960938 69.273438 197.871094 69.363281 C 197.78125 69.449219 197.675781 69.492188 197.550781 69.492188 C 197.425781 69.492188 197.320312 69.449219 197.234375 69.363281 C 197.144531 69.273438 197.101562 69.167969 197.101562 69.042969 Z M 197.101562 69.042969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 198.453125 69.042969 C 198.453125 69.554688 198.03125 69.945312 197.550781 69.945312 C 197.042969 69.945312 196.652344 69.523438 196.652344 69.042969 C 196.652344 68.53125 197.070312 68.140625 197.550781 68.140625 C 198.03125 68.140625 198.453125 68.53125 198.453125 69.042969 Z M 198.394531 69.042969 C 198.394531 68.5625 198.003906 68.171875 197.523438 68.171875 C 197.042969 68.171875 196.652344 68.5625 196.652344 69.042969 C 196.652344 69.523438 197.042969 69.914062 197.523438 69.914062 C 198.003906 69.914062 198.394531 69.523438 198.394531 69.042969 Z M 198.394531 69.042969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 103.855469 93.480469 C 103.855469 93.242188 103.675781 93.0625 103.4375 93.0625 C 103.195312 93.0625 103.015625 93.242188 103.015625 93.480469 C 103.015625 93.722656 103.195312 93.902344 103.4375 93.902344 C 103.675781 93.902344 103.855469 93.722656 103.855469 93.480469 Z M 103.855469 93.480469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 104.308594 93.480469 C 104.308594 93.960938 103.917969 94.351562 103.4375 94.351562 C 102.957031 94.351562 102.566406 93.960938 102.566406 93.480469 C 102.566406 93 102.957031 92.609375 103.4375 92.609375 C 103.917969 92.609375 104.308594 93 104.308594 93.480469 Z M 104.246094 93.480469 C 104.246094 93.03125 103.886719 92.640625 103.40625 92.640625 C 102.957031 92.640625 102.566406 93 102.566406 93.480469 C 102.566406 93.960938 102.925781 94.324219 103.40625 94.324219 C 103.855469 94.324219 104.246094 93.933594 104.246094 93.480469 Z M 104.246094 93.480469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 96.613281 92.941406 C 96.613281 92.800781 96.683594 92.730469 96.824219 92.730469 C 96.964844 92.730469 97.035156 92.800781 97.035156 92.941406 C 97.035156 93.082031 96.964844 93.152344 96.824219 93.152344 C 96.683594 93.152344 96.613281 93.082031 96.613281 92.941406 Z M 96.613281 92.941406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 97.246094 92.941406 C 97.246094 93.179688 97.0625 93.359375 96.824219 93.359375 C 96.582031 93.359375 96.402344 93.179688 96.402344 92.941406 C 96.402344 92.699219 96.582031 92.519531 96.824219 92.519531 C 97.0625 92.519531 97.246094 92.699219 97.246094 92.941406 Z M 97.214844 92.941406 C 97.214844 92.730469 97.035156 92.519531 96.792969 92.519531 C 96.582031 92.519531 96.371094 92.699219 96.371094 92.941406 C 96.371094 93.152344 96.554688 93.359375 96.792969 93.359375 C 97.035156 93.359375 97.214844 93.179688 97.214844 92.941406 Z M 97.214844 92.941406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 144.648438 87.289062 C 144.648438 87.140625 144.699219 87.011719 144.804688 86.90625 C 144.910156 86.800781 145.039062 86.75 145.1875 86.75 C 145.339844 86.75 145.464844 86.800781 145.570312 86.90625 C 145.675781 87.011719 145.730469 87.140625 145.730469 87.289062 C 145.730469 87.4375 145.675781 87.566406 145.570312 87.671875 C 145.464844 87.777344 145.339844 87.832031 145.1875 87.832031 C 145.039062 87.832031 144.910156 87.777344 144.804688 87.671875 C 144.699219 87.566406 144.648438 87.4375 144.648438 87.289062 Z M 144.648438 87.289062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 147.414062 87.289062 C 147.414062 88.523438 146.421875 89.511719 145.1875 89.511719 C 143.957031 89.511719 142.964844 88.523438 142.964844 87.289062 C 142.964844 86.058594 143.957031 85.066406 145.1875 85.066406 C 146.421875 85.066406 147.414062 86.058594 147.414062 87.289062 Z M 147.261719 87.289062 C 147.261719 86.148438 146.332031 85.214844 145.1875 85.214844 C 144.046875 85.214844 143.113281 86.148438 143.113281 87.289062 C 143.113281 88.429688 144.046875 89.363281 145.1875 89.363281 C 146.332031 89.363281 147.261719 88.429688 147.261719 87.289062 Z M 147.261719 87.289062 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 159.949219 103.402344 C 159.949219 103.316406 159.976562 103.246094 160.035156 103.1875 C 160.09375 103.128906 160.164062 103.101562 160.25 103.101562 C 160.332031 103.101562 160.402344 103.128906 160.460938 103.1875 C 160.519531 103.246094 160.550781 103.316406 160.550781 103.402344 C 160.550781 103.484375 160.519531 103.554688 160.460938 103.613281 C 160.402344 103.671875 160.332031 103.703125 160.25 103.703125 C 160.164062 103.703125 160.09375 103.671875 160.035156 103.613281 C 159.976562 103.554688 159.949219 103.484375 159.949219 103.402344 Z M 159.949219 103.402344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 161.511719 103.402344 C 161.511719 104.09375 160.941406 104.664062 160.25 104.664062 C 159.558594 104.664062 158.984375 104.09375 158.984375 103.402344 C 158.984375 102.710938 159.558594 102.136719 160.25 102.136719 C 160.941406 102.136719 161.511719 102.710938 161.511719 103.402344 Z M 161.421875 103.402344 C 161.421875 102.738281 160.878906 102.230469 160.25 102.230469 C 159.617188 102.230469 159.078125 102.769531 159.078125 103.402344 C 159.078125 104.03125 159.617188 104.574219 160.25 104.574219 C 160.878906 104.574219 161.421875 104.0625 161.421875 103.402344 Z M 161.421875 103.402344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 173.113281 91.40625 C 173.113281 91.136719 172.902344 90.925781 172.632812 90.925781 C 172.363281 90.925781 172.152344 91.136719 172.152344 91.40625 C 172.152344 91.679688 172.363281 91.886719 172.632812 91.886719 C 172.875 91.859375 173.113281 91.648438 173.113281 91.40625 Z M 173.113281 91.40625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 174.617188 91.40625 C 174.617188 92.519531 173.714844 93.390625 172.632812 93.390625 C 171.519531 93.390625 170.648438 92.488281 170.648438 91.40625 C 170.648438 90.324219 171.550781 89.421875 172.632812 89.421875 C 173.714844 89.394531 174.617188 90.296875 174.617188 91.40625 Z M 174.496094 91.40625 C 174.496094 90.386719 173.65625 89.542969 172.632812 89.542969 C 171.609375 89.542969 170.769531 90.386719 170.769531 91.40625 C 170.769531 92.429688 171.609375 93.269531 172.632812 93.269531 C 173.65625 93.269531 174.496094 92.429688 174.496094 91.40625 Z M 174.496094 91.40625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 174.136719 70.484375 C 174.136719 70.351562 174.183594 70.238281 174.277344 70.144531 C 174.371094 70.050781 174.484375 70.003906 174.617188 70.003906 C 174.75 70.003906 174.863281 70.050781 174.957031 70.144531 C 175.050781 70.238281 175.097656 70.351562 175.097656 70.484375 C 175.097656 70.617188 175.050781 70.730469 174.957031 70.824219 C 174.863281 70.921875 174.75 70.96875 174.617188 70.96875 C 174.484375 70.96875 174.371094 70.921875 174.277344 70.824219 C 174.183594 70.730469 174.136719 70.617188 174.136719 70.484375 Z M 174.136719 70.484375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 176.601562 70.484375 C 176.601562 71.597656 175.699219 72.46875 174.617188 72.46875 C 173.503906 72.46875 172.632812 71.566406 172.632812 70.484375 C 172.632812 69.375 173.535156 68.503906 174.617188 68.503906 C 175.699219 68.503906 176.601562 69.375 176.601562 70.484375 Z M 176.480469 70.484375 C 176.480469 69.464844 175.640625 68.621094 174.617188 68.621094 C 173.59375 68.621094 172.753906 69.464844 172.753906 70.484375 C 172.753906 71.507812 173.59375 72.351562 174.617188 72.351562 C 175.640625 72.351562 176.480469 71.507812 176.480469 70.484375 Z M 176.480469 70.484375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 27.445312 91.40625 C 27.445312 91.257812 27.5 91.128906 27.605469 91.023438 C 27.710938 90.917969 27.839844 90.867188 27.988281 90.867188 C 28.136719 90.867188 28.265625 90.917969 28.371094 91.023438 C 28.476562 91.128906 28.527344 91.257812 28.527344 91.40625 C 28.527344 91.558594 28.476562 91.683594 28.371094 91.789062 C 28.265625 91.894531 28.136719 91.949219 27.988281 91.949219 C 27.839844 91.949219 27.710938 91.894531 27.605469 91.789062 C 27.5 91.683594 27.445312 91.558594 27.445312 91.40625 Z M 27.445312 91.40625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 30.210938 91.40625 C 30.210938 92.640625 29.21875 93.632812 27.988281 93.632812 C 26.753906 93.632812 25.761719 92.640625 25.761719 91.40625 C 25.761719 90.175781 26.753906 89.183594 27.988281 89.183594 C 29.191406 89.183594 30.210938 90.175781 30.210938 91.40625 Z M 30.0625 91.40625 C 30.0625 90.265625 29.128906 89.332031 27.988281 89.332031 C 26.84375 89.332031 25.914062 90.265625 25.914062 91.40625 C 25.914062 92.550781 26.84375 93.480469 27.988281 93.480469 C 29.128906 93.480469 30.0625 92.550781 30.0625 91.40625 Z M 30.0625 91.40625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 88.859375 96.066406 C 88.859375 95.886719 88.707031 95.734375 88.527344 95.734375 C 88.347656 95.734375 88.195312 95.886719 88.195312 96.066406 C 88.195312 96.246094 88.347656 96.398438 88.527344 96.398438 C 88.707031 96.425781 88.859375 96.277344 88.859375 96.066406 Z M 88.859375 96.066406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 89.96875 96.066406 C 89.96875 96.878906 89.308594 97.507812 88.527344 97.507812 C 87.746094 97.507812 87.085938 96.847656 87.085938 96.066406 C 87.085938 95.253906 87.746094 94.625 88.527344 94.625 C 89.308594 94.625 89.96875 95.285156 89.96875 96.066406 Z M 89.878906 96.066406 C 89.878906 95.316406 89.277344 94.714844 88.527344 94.714844 C 87.777344 94.714844 87.175781 95.316406 87.175781 96.066406 C 87.175781 96.816406 87.777344 97.417969 88.527344 97.417969 C 89.277344 97.417969 89.878906 96.816406 89.878906 96.066406 Z M 89.878906 96.066406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 99.46875 66.519531 C 99.46875 66.335938 99.316406 66.1875 99.136719 66.1875 C 98.957031 66.1875 98.808594 66.335938 98.808594 66.519531 C 98.808594 66.699219 98.957031 66.847656 99.136719 66.847656 C 99.316406 66.878906 99.46875 66.730469 99.46875 66.519531 Z M 99.46875 66.519531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 100.582031 66.519531 C 100.582031 67.328125 99.917969 67.960938 99.136719 67.960938 C 98.355469 67.960938 97.695312 67.300781 97.695312 66.519531 C 97.695312 65.707031 98.355469 65.074219 99.136719 65.074219 C 99.917969 65.074219 100.582031 65.738281 100.582031 66.519531 Z M 100.492188 66.519531 C 100.492188 65.765625 99.890625 65.164062 99.136719 65.164062 C 98.386719 65.164062 97.785156 65.765625 97.785156 66.519531 C 97.785156 67.269531 98.386719 67.871094 99.136719 67.871094 C 99.890625 67.871094 100.492188 67.269531 100.492188 66.519531 Z M 100.492188 66.519531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 185.46875 73.492188 C 185.46875 73.25 185.257812 73.042969 185.015625 73.042969 C 184.777344 73.042969 184.566406 73.25 184.566406 73.492188 C 184.566406 73.734375 184.777344 73.941406 185.015625 73.941406 C 185.257812 73.941406 185.46875 73.734375 185.46875 73.492188 Z M 185.46875 73.492188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 186.910156 73.492188 C 186.910156 74.542969 186.070312 75.386719 185.015625 75.386719 C 183.964844 75.386719 183.125 74.542969 183.125 73.492188 C 183.125 72.441406 183.964844 71.597656 185.015625 71.597656 C 186.070312 71.597656 186.910156 72.441406 186.910156 73.492188 Z M 186.789062 73.492188 C 186.789062 72.5 185.980469 71.71875 185.015625 71.71875 C 184.027344 71.71875 183.242188 72.53125 183.242188 73.492188 C 183.242188 74.484375 184.054688 75.265625 185.015625 75.265625 C 186.007812 75.296875 186.789062 74.484375 186.789062 73.492188 Z M 186.789062 73.492188 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 225.476562 96.035156 C 225.476562 95.796875 225.265625 95.585938 225.027344 95.585938 C 224.785156 95.585938 224.574219 95.796875 224.574219 96.035156 C 224.574219 96.277344 224.785156 96.488281 225.027344 96.488281 C 225.265625 96.488281 225.476562 96.308594 225.476562 96.035156 Z M 225.476562 96.035156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 226.921875 96.035156 C 226.921875 97.089844 226.078125 97.929688 225.027344 97.929688 C 223.972656 97.929688 223.132812 97.089844 223.132812 96.035156 C 223.132812 94.984375 223.972656 94.144531 225.027344 94.144531 C 226.078125 94.144531 226.921875 94.984375 226.921875 96.035156 Z M 226.828125 96.035156 C 226.828125 95.042969 226.019531 94.261719 225.054688 94.261719 C 224.066406 94.261719 223.28125 95.074219 223.28125 96.035156 C 223.28125 97 224.09375 97.808594 225.054688 97.808594 C 226.019531 97.839844 226.828125 97.027344 226.828125 96.035156 Z M 226.828125 96.035156 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 216.1875 111.214844 C 216.1875 110.976562 215.980469 110.765625 215.738281 110.765625 C 215.496094 110.765625 215.289062 110.976562 215.289062 111.214844 C 215.289062 111.457031 215.496094 111.667969 215.738281 111.667969 C 215.980469 111.667969 216.1875 111.457031 216.1875 111.214844 Z M 216.1875 111.214844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 217.632812 111.214844 C 217.632812 112.269531 216.789062 113.109375 215.738281 113.109375 C 214.6875 113.109375 213.84375 112.269531 213.84375 111.214844 C 213.84375 110.164062 214.6875 109.324219 215.738281 109.324219 C 216.789062 109.324219 217.632812 110.164062 217.632812 111.214844 Z M 217.511719 111.214844 C 217.511719 110.222656 216.699219 109.441406 215.738281 109.441406 C 214.746094 109.441406 213.964844 110.253906 213.964844 111.214844 C 213.964844 112.207031 214.777344 112.988281 215.738281 112.988281 C 216.730469 112.988281 217.511719 112.207031 217.511719 111.214844 Z M 217.511719 111.214844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 238.703125 90.894531 C 238.703125 90.746094 238.582031 90.625 238.433594 90.625 C 238.28125 90.625 238.164062 90.746094 238.164062 90.894531 C 238.164062 91.046875 238.28125 91.167969 238.433594 91.167969 C 238.582031 91.167969 238.703125 91.046875 238.703125 90.894531 Z M 238.703125 90.894531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 239.574219 90.894531 C 239.574219 91.527344 239.0625 92.039062 238.433594 92.039062 C 237.800781 92.039062 237.289062 91.527344 237.289062 90.894531 C 237.289062 90.265625 237.800781 89.753906 238.433594 89.753906 C 239.0625 89.753906 239.574219 90.265625 239.574219 90.894531 Z M 239.515625 90.894531 C 239.515625 90.296875 239.035156 89.8125 238.433594 89.8125 C 237.832031 89.8125 237.351562 90.296875 237.351562 90.894531 C 237.351562 91.496094 237.832031 91.976562 238.433594 91.976562 C 239.035156 91.976562 239.515625 91.496094 239.515625 90.894531 Z M 239.515625 90.894531 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 198.84375 92.730469 C 198.84375 92.550781 198.695312 92.429688 198.542969 92.429688 C 198.363281 92.429688 198.242188 92.578125 198.242188 92.730469 C 198.242188 92.910156 198.394531 93.03125 198.542969 93.03125 C 198.722656 93.03125 198.84375 92.910156 198.84375 92.730469 Z M 198.84375 92.730469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 199.835938 92.730469 C 199.835938 93.421875 199.265625 94.023438 198.542969 94.023438 C 197.851562 94.023438 197.25 93.453125 197.25 92.730469 C 197.25 92.039062 197.824219 91.4375 198.542969 91.4375 C 199.265625 91.4375 199.835938 92.039062 199.835938 92.730469 Z M 199.746094 92.730469 C 199.746094 92.070312 199.207031 91.527344 198.542969 91.527344 C 197.882812 91.527344 197.339844 92.070312 197.339844 92.730469 C 197.339844 93.390625 197.882812 93.933594 198.542969 93.933594 C 199.207031 93.933594 199.746094 93.390625 199.746094 92.730469 Z M 199.746094 92.730469 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 222.292969 82.390625 C 222.292969 83.410156 221.449219 84.222656 220.457031 84.222656 C 219.433594 84.222656 218.625 83.382812 218.625 82.390625 C 218.625 81.367188 219.464844 80.554688 220.457031 80.554688 C 221.449219 80.554688 222.292969 81.367188 222.292969 82.390625 Z M 222.199219 82.390625 C 222.199219 81.398438 221.417969 80.617188 220.425781 80.617188 C 219.433594 80.617188 218.652344 81.398438 218.652344 82.390625 C 218.652344 83.382812 219.433594 84.164062 220.425781 84.164062 C 221.417969 84.164062 222.199219 83.382812 222.199219 82.390625 Z M 222.199219 82.390625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 222.863281 82.390625 C 222.863281 83.742188 221.78125 84.824219 220.425781 84.824219 C 219.074219 84.824219 217.992188 83.742188 217.992188 82.390625 C 217.992188 81.035156 219.074219 79.953125 220.425781 79.953125 C 221.78125 79.953125 222.863281 81.066406 222.863281 82.390625 Z M 222.773438 82.390625 C 222.773438 81.097656 221.71875 80.046875 220.425781 80.046875 C 219.136719 80.046875 218.082031 81.097656 218.082031 82.390625 C 218.082031 83.683594 219.136719 84.734375 220.425781 84.734375 C 221.71875 84.734375 222.773438 83.683594 222.773438 82.390625 Z M 222.773438 82.390625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 219.105469 82.390625 C 219.105469 82.214844 219.136719 82.046875 219.207031 81.882812 C 219.273438 81.722656 219.367188 81.578125 219.492188 81.453125 C 219.617188 81.332031 219.757812 81.234375 219.921875 81.167969 C 220.082031 81.101562 220.253906 81.066406 220.425781 81.066406 C 220.601562 81.066406 220.769531 81.101562 220.933594 81.167969 C 221.09375 81.234375 221.238281 81.332031 221.363281 81.453125 C 221.488281 81.578125 221.582031 81.722656 221.648438 81.882812 C 221.714844 82.046875 221.75 82.214844 221.75 82.390625 C 221.75 82.566406 221.714844 82.734375 221.648438 82.894531 C 221.582031 83.058594 221.488281 83.199219 221.363281 83.324219 C 221.238281 83.449219 221.09375 83.542969 220.933594 83.613281 C 220.769531 83.679688 220.601562 83.710938 220.425781 83.710938 C 220.253906 83.710938 220.082031 83.679688 219.921875 83.613281 C 219.757812 83.542969 219.617188 83.449219 219.492188 83.324219 C 219.367188 83.199219 219.273438 83.058594 219.207031 82.894531 C 219.136719 82.734375 219.105469 82.566406 219.105469 82.390625 Z M 219.105469 82.390625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 146.84375 71.597656 C 146.84375 72.621094 146 73.433594 145.007812 73.433594 C 143.988281 73.433594 143.175781 72.589844 143.175781 71.597656 C 143.175781 70.578125 144.015625 69.765625 145.007812 69.765625 C 146.03125 69.734375 146.84375 70.578125 146.84375 71.597656 Z M 146.78125 71.597656 C 146.78125 70.605469 146 69.824219 145.007812 69.824219 C 144.015625 69.824219 143.234375 70.605469 143.234375 71.597656 C 143.234375 72.589844 144.015625 73.371094 145.007812 73.371094 C 146 73.371094 146.78125 72.558594 146.78125 71.597656 Z M 146.78125 71.597656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 147.441406 71.597656 C 147.441406 72.949219 146.359375 74.03125 145.007812 74.03125 C 143.65625 74.03125 142.574219 72.949219 142.574219 71.597656 C 142.574219 70.246094 143.65625 69.164062 145.007812 69.164062 C 146.359375 69.164062 147.441406 70.246094 147.441406 71.597656 Z M 147.351562 71.597656 C 147.351562 70.304688 146.300781 69.253906 145.007812 69.253906 C 143.714844 69.253906 142.664062 70.304688 142.664062 71.597656 C 142.664062 72.890625 143.714844 73.941406 145.007812 73.941406 C 146.300781 73.941406 147.351562 72.890625 147.351562 71.597656 Z M 147.351562 71.597656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 143.6875 71.597656 C 143.6875 71.421875 143.71875 71.253906 143.785156 71.09375 C 143.855469 70.929688 143.949219 70.785156 144.074219 70.664062 C 144.195312 70.539062 144.339844 70.445312 144.503906 70.375 C 144.664062 70.308594 144.832031 70.277344 145.007812 70.277344 C 145.183594 70.277344 145.351562 70.308594 145.515625 70.375 C 145.675781 70.445312 145.820312 70.539062 145.945312 70.664062 C 146.066406 70.785156 146.164062 70.929688 146.230469 71.09375 C 146.296875 71.253906 146.332031 71.421875 146.332031 71.597656 C 146.332031 71.773438 146.296875 71.941406 146.230469 72.105469 C 146.164062 72.265625 146.066406 72.410156 145.945312 72.535156 C 145.820312 72.65625 145.675781 72.753906 145.515625 72.820312 C 145.351562 72.886719 145.183594 72.921875 145.007812 72.921875 C 144.832031 72.921875 144.664062 72.886719 144.503906 72.820312 C 144.339844 72.753906 144.195312 72.65625 144.074219 72.535156 C 143.949219 72.410156 143.855469 72.265625 143.785156 72.105469 C 143.71875 71.941406 143.6875 71.773438 143.6875 71.597656 Z M 143.6875 71.597656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 186.070312 111.398438 C 186.070312 111.789062 185.738281 112.117188 185.347656 112.117188 C 184.957031 112.117188 184.625 111.789062 184.625 111.398438 C 184.625 111.007812 184.957031 110.675781 185.347656 110.675781 C 185.738281 110.675781 186.070312 111.007812 186.070312 111.398438 Z M 186.039062 111.398438 C 186.039062 111.007812 185.738281 110.707031 185.347656 110.707031 C 184.957031 110.707031 184.65625 111.007812 184.65625 111.398438 C 184.65625 111.789062 184.957031 112.089844 185.347656 112.089844 C 185.738281 112.117188 186.039062 111.789062 186.039062 111.398438 Z M 186.039062 111.398438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 186.308594 111.398438 C 186.308594 111.9375 185.890625 112.359375 185.347656 112.359375 C 184.808594 112.359375 184.386719 111.9375 184.386719 111.398438 C 184.386719 110.855469 184.808594 110.433594 185.347656 110.433594 C 185.890625 110.464844 186.308594 110.886719 186.308594 111.398438 Z M 186.28125 111.398438 C 186.28125 110.886719 185.859375 110.464844 185.347656 110.464844 C 184.835938 110.464844 184.417969 110.886719 184.417969 111.398438 C 184.417969 111.90625 184.835938 112.328125 185.347656 112.328125 C 185.859375 112.328125 186.28125 111.90625 186.28125 111.398438 Z M 186.28125 111.398438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 185.859375 111.398438 C 185.859375 111.097656 185.617188 110.886719 185.347656 110.886719 C 185.046875 110.886719 184.835938 111.125 184.835938 111.398438 C 184.835938 111.699219 185.078125 111.90625 185.347656 111.90625 C 185.648438 111.9375 185.859375 111.699219 185.859375 111.398438 Z M 185.859375 111.398438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 204.617188 96.398438 C 204.617188 96.96875 204.136719 97.449219 203.5625 97.449219 C 202.992188 97.449219 202.511719 96.96875 202.511719 96.398438 C 202.511719 95.824219 202.992188 95.34375 203.5625 95.34375 C 204.136719 95.375 204.617188 95.824219 204.617188 96.398438 Z M 204.554688 96.398438 C 204.554688 95.855469 204.105469 95.40625 203.5625 95.40625 C 203.023438 95.40625 202.570312 95.855469 202.570312 96.398438 C 202.570312 96.9375 203.023438 97.390625 203.5625 97.390625 C 204.105469 97.417969 204.554688 96.96875 204.554688 96.398438 Z M 204.554688 96.398438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 204.945312 96.398438 C 204.945312 97.148438 204.316406 97.78125 203.5625 97.78125 C 202.8125 97.78125 202.179688 97.148438 202.179688 96.398438 C 202.179688 95.644531 202.8125 95.015625 203.5625 95.015625 C 204.316406 95.042969 204.945312 95.644531 204.945312 96.398438 Z M 204.886719 96.398438 C 204.886719 95.675781 204.285156 95.074219 203.5625 95.074219 C 202.84375 95.074219 202.242188 95.675781 202.242188 96.398438 C 202.242188 97.117188 202.84375 97.71875 203.5625 97.71875 C 204.285156 97.71875 204.886719 97.148438 204.886719 96.398438 Z M 204.886719 96.398438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 202.8125 96.398438 C 202.8125 96.191406 202.886719 96.011719 203.03125 95.867188 C 203.179688 95.71875 203.355469 95.644531 203.5625 95.644531 C 203.773438 95.644531 203.949219 95.71875 204.09375 95.867188 C 204.242188 96.011719 204.316406 96.191406 204.316406 96.398438 C 204.316406 96.605469 204.242188 96.78125 204.09375 96.929688 C 203.949219 97.074219 203.773438 97.148438 203.5625 97.148438 C 203.355469 97.148438 203.179688 97.074219 203.03125 96.929688 C 202.886719 96.78125 202.8125 96.605469 202.8125 96.398438 Z M 202.8125 96.398438 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 123.726562 82.929688 C 123.726562 83.199219 123.515625 83.382812 123.277344 83.382812 C 123.003906 83.382812 122.824219 83.171875 122.824219 82.929688 C 122.824219 82.660156 123.035156 82.480469 123.277344 82.480469 C 123.515625 82.480469 123.726562 82.691406 123.726562 82.929688 Z M 123.726562 82.929688 C 123.726562 82.691406 123.515625 82.480469 123.277344 82.480469 C 123.035156 82.480469 122.824219 82.691406 122.824219 82.929688 C 122.824219 83.171875 123.035156 83.382812 123.277344 83.382812 C 123.515625 83.382812 123.726562 83.171875 123.726562 82.929688 Z M 123.726562 82.929688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 123.875 82.929688 C 123.875 83.261719 123.605469 83.53125 123.277344 83.53125 C 122.945312 83.53125 122.675781 83.261719 122.675781 82.929688 C 122.675781 82.601562 122.945312 82.328125 123.277344 82.328125 C 123.605469 82.328125 123.875 82.601562 123.875 82.929688 Z M 123.847656 82.929688 C 123.847656 82.601562 123.574219 82.328125 123.246094 82.328125 C 122.914062 82.328125 122.644531 82.601562 122.644531 82.929688 C 122.644531 83.261719 122.914062 83.53125 123.246094 83.53125 C 123.605469 83.53125 123.847656 83.261719 123.847656 82.929688 Z M 123.847656 82.929688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 123.605469 82.929688 C 123.605469 82.75 123.457031 82.601562 123.277344 82.601562 C 123.09375 82.601562 122.945312 82.75 122.945312 82.929688 C 122.945312 83.109375 123.09375 83.261719 123.277344 83.261719 C 123.457031 83.261719 123.605469 83.109375 123.605469 82.929688 Z M 123.605469 82.929688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 96.160156 87.5 C 96.160156 88.820312 95.078125 89.933594 93.726562 89.933594 C 92.40625 89.933594 91.292969 88.851562 91.292969 87.5 C 91.292969 86.175781 92.375 85.066406 93.726562 85.066406 C 95.078125 85.066406 96.160156 86.148438 96.160156 87.5 Z M 96.070312 87.5 C 96.070312 86.207031 95.019531 85.15625 93.726562 85.15625 C 92.433594 85.15625 91.382812 86.207031 91.382812 87.5 C 91.382812 88.792969 92.433594 89.84375 93.726562 89.84375 C 95.019531 89.8125 96.070312 88.792969 96.070312 87.5 Z M 96.070312 87.5 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 96.945312 87.5 C 96.945312 89.273438 95.5 90.6875 93.757812 90.6875 C 91.984375 90.6875 90.570312 89.242188 90.570312 87.5 C 90.570312 85.726562 92.015625 84.3125 93.757812 84.3125 C 95.5 84.285156 96.945312 85.726562 96.945312 87.5 Z M 96.824219 87.5 C 96.824219 85.785156 95.441406 84.402344 93.726562 84.402344 C 92.015625 84.402344 90.632812 85.785156 90.632812 87.5 C 90.632812 89.214844 92.015625 90.597656 93.726562 90.597656 C 95.441406 90.566406 96.824219 89.183594 96.824219 87.5 Z M 96.824219 87.5 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 92.015625 87.5 C 92.015625 87.269531 92.058594 87.046875 92.148438 86.832031 C 92.234375 86.617188 92.359375 86.429688 92.523438 86.265625 C 92.6875 86.101562 92.875 85.976562 93.089844 85.890625 C 93.304688 85.800781 93.527344 85.757812 93.757812 85.757812 C 93.988281 85.757812 94.210938 85.800781 94.425781 85.890625 C 94.636719 85.976562 94.828125 86.101562 94.988281 86.265625 C 95.152344 86.429688 95.28125 86.617188 95.367188 86.832031 C 95.457031 87.046875 95.5 87.269531 95.5 87.5 C 95.5 87.730469 95.457031 87.953125 95.367188 88.167969 C 95.28125 88.378906 95.152344 88.570312 94.988281 88.734375 C 94.828125 88.894531 94.636719 89.023438 94.425781 89.109375 C 94.210938 89.199219 93.988281 89.242188 93.757812 89.242188 C 93.527344 89.242188 93.304688 89.199219 93.089844 89.109375 C 92.875 89.023438 92.6875 88.894531 92.523438 88.734375 C 92.359375 88.570312 92.234375 88.378906 92.148438 88.167969 C 92.058594 87.953125 92.015625 87.730469 92.015625 87.5 Z M 92.015625 87.5 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 59.257812 83.703125 C 59.234375 83.566406 59.242188 83.429688 59.273438 83.292969 C 59.304688 83.15625 59.363281 83.035156 59.445312 82.921875 C 59.523438 82.808594 59.625 82.714844 59.742188 82.640625 C 59.863281 82.566406 59.992188 82.519531 60.128906 82.496094 C 60.265625 82.476562 60.402344 82.480469 60.539062 82.511719 C 60.675781 82.542969 60.800781 82.601562 60.914062 82.683594 C 61.027344 82.765625 61.117188 82.863281 61.191406 82.984375 C 61.265625 83.101562 61.3125 83.230469 61.335938 83.367188 C 61.359375 83.503906 61.351562 83.640625 61.320312 83.777344 C 61.289062 83.914062 61.230469 84.039062 61.152344 84.152344 C 61.070312 84.265625 60.96875 84.355469 60.851562 84.429688 C 60.730469 84.503906 60.601562 84.550781 60.464844 84.574219 C 60.328125 84.597656 60.191406 84.589844 60.054688 84.558594 C 59.917969 84.527344 59.796875 84.46875 59.683594 84.390625 C 59.570312 84.308594 59.476562 84.207031 59.402344 84.089844 C 59.328125 83.96875 59.28125 83.839844 59.257812 83.703125 Z M 59.257812 83.703125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 19.929688 90.6875 C 19.929688 90.488281 20.003906 90.316406 20.144531 90.175781 C 20.285156 90.035156 20.453125 89.964844 20.652344 89.964844 C 20.851562 89.964844 21.023438 90.035156 21.164062 90.175781 C 21.304688 90.316406 21.375 90.488281 21.375 90.6875 C 21.375 90.886719 21.304688 91.054688 21.164062 91.195312 C 21.023438 91.335938 20.851562 91.40625 20.652344 91.40625 C 20.453125 91.40625 20.285156 91.335938 20.144531 91.195312 C 20.003906 91.054688 19.929688 90.886719 19.929688 90.6875 Z M 19.929688 90.6875 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 257.371094 128.5625 C 257.371094 128.363281 257.441406 128.191406 257.582031 128.050781 C 257.722656 127.910156 257.890625 127.839844 258.089844 127.839844 C 258.289062 127.839844 258.460938 127.910156 258.601562 128.050781 C 258.742188 128.191406 258.8125 128.363281 258.8125 128.5625 C 258.8125 128.761719 258.742188 128.929688 258.601562 129.070312 C 258.460938 129.210938 258.289062 129.28125 258.089844 129.28125 C 257.890625 129.28125 257.722656 129.210938 257.582031 129.070312 C 257.441406 128.929688 257.371094 128.761719 257.371094 128.5625 Z M 257.371094 128.5625 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 105.628906 89.304688 C 105.628906 89.164062 105.65625 89.03125 105.710938 88.902344 C 105.765625 88.773438 105.839844 88.65625 105.9375 88.558594 C 106.039062 88.460938 106.152344 88.382812 106.28125 88.332031 C 106.410156 88.277344 106.542969 88.25 106.683594 88.25 C 106.824219 88.25 106.957031 88.277344 107.085938 88.332031 C 107.214844 88.382812 107.328125 88.460938 107.425781 88.558594 C 107.527344 88.65625 107.601562 88.773438 107.65625 88.902344 C 107.707031 89.03125 107.734375 89.164062 107.734375 89.304688 C 107.734375 89.441406 107.707031 89.578125 107.65625 89.707031 C 107.601562 89.835938 107.527344 89.949219 107.425781 90.046875 C 107.328125 90.144531 107.214844 90.222656 107.085938 90.273438 C 106.957031 90.328125 106.824219 90.355469 106.683594 90.355469 C 106.542969 90.355469 106.410156 90.328125 106.28125 90.273438 C 106.152344 90.222656 106.039062 90.144531 105.9375 90.046875 C 105.839844 89.949219 105.765625 89.835938 105.710938 89.707031 C 105.65625 89.578125 105.628906 89.441406 105.628906 89.304688 Z M 105.628906 89.304688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 56.632812 87.5 C 56.632812 87.332031 56.691406 87.191406 56.808594 87.074219 C 56.925781 86.957031 57.070312 86.898438 57.234375 86.898438 C 57.402344 86.898438 57.542969 86.957031 57.660156 87.074219 C 57.777344 87.191406 57.835938 87.332031 57.835938 87.5 C 57.835938 87.664062 57.777344 87.808594 57.660156 87.925781 C 57.542969 88.042969 57.402344 88.101562 57.234375 88.101562 C 57.070312 88.101562 56.925781 88.042969 56.808594 87.925781 C 56.691406 87.808594 56.632812 87.664062 56.632812 87.5 Z M 56.632812 87.5 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 12.117188 78.632812 C 12.117188 78.300781 11.84375 78.03125 11.515625 78.03125 C 11.183594 78.03125 10.914062 78.300781 10.914062 78.632812 C 10.914062 78.960938 11.183594 79.234375 11.515625 79.234375 C 11.84375 79.234375 12.117188 78.960938 12.117188 78.632812 Z M 12.117188 78.632812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 245.472656 112.304688 C 245.445312 112.140625 245.480469 111.992188 245.578125 111.859375 C 245.675781 111.722656 245.804688 111.644531 245.96875 111.617188 C 246.132812 111.589844 246.28125 111.625 246.417969 111.722656 C 246.550781 111.820312 246.632812 111.949219 246.660156 112.113281 C 246.6875 112.277344 246.652344 112.425781 246.554688 112.5625 C 246.457031 112.695312 246.328125 112.777344 246.164062 112.804688 C 246 112.828125 245.847656 112.792969 245.714844 112.699219 C 245.582031 112.601562 245.5 112.46875 245.472656 112.304688 Z M 245.472656 112.304688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g clip-path="url(#0896b634c9)">
        <path
          fill={fill}
          d="M 261.820312 93.722656 C 261.820312 93.390625 261.546875 93.121094 261.21875 93.121094 C 260.886719 93.121094 260.617188 93.390625 260.617188 93.722656 C 260.617188 94.050781 260.886719 94.324219 261.21875 94.324219 C 261.546875 94.292969 261.820312 94.050781 261.820312 93.722656 Z M 261.820312 93.722656 "
          fill-opacity="1"
          fill-rule="nonzero"
        />
      </g>
      <path
        fill={fill}
        d="M 121.1875 100.941406 C 121.144531 100.765625 121.171875 100.597656 121.269531 100.441406 C 121.363281 100.289062 121.5 100.1875 121.679688 100.148438 C 121.855469 100.105469 122.023438 100.132812 122.179688 100.226562 C 122.332031 100.324219 122.433594 100.460938 122.472656 100.640625 C 122.515625 100.816406 122.488281 100.984375 122.394531 101.136719 C 122.296875 101.292969 122.160156 101.390625 121.984375 101.433594 C 121.804688 101.476562 121.636719 101.449219 121.484375 101.351562 C 121.328125 101.257812 121.230469 101.121094 121.1875 100.941406 Z M 121.1875 100.941406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 80.742188 92.371094 C 80.742188 92.039062 80.472656 91.769531 80.140625 91.769531 C 79.808594 91.769531 79.539062 92.039062 79.539062 92.371094 C 79.539062 92.699219 79.808594 92.96875 80.140625 92.96875 C 80.472656 92.96875 80.742188 92.699219 80.742188 92.371094 Z M 80.742188 92.371094 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 69.648438 96.125 C 69.648438 95.796875 69.378906 95.527344 69.046875 95.527344 C 68.71875 95.527344 68.449219 95.796875 68.449219 96.125 C 68.449219 96.457031 68.71875 96.726562 69.046875 96.726562 C 69.378906 96.726562 69.648438 96.457031 69.648438 96.125 Z M 69.648438 96.125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 91.351562 77.941406 C 91.351562 77.730469 91.171875 77.519531 90.933594 77.519531 C 90.722656 77.519531 90.511719 77.699219 90.511719 77.941406 C 90.511719 78.179688 90.691406 78.363281 90.933594 78.363281 C 91.171875 78.332031 91.351562 78.152344 91.351562 77.941406 Z M 91.351562 77.941406 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 190.878906 96.308594 C 190.878906 96.148438 190.933594 96.015625 191.046875 95.902344 C 191.15625 95.792969 191.292969 95.734375 191.449219 95.734375 C 191.609375 95.734375 191.742188 95.792969 191.855469 95.902344 C 191.964844 96.015625 192.019531 96.148438 192.019531 96.308594 C 192.019531 96.464844 191.964844 96.597656 191.855469 96.710938 C 191.742188 96.824219 191.609375 96.878906 191.449219 96.878906 C 191.292969 96.878906 191.15625 96.824219 191.046875 96.710938 C 190.933594 96.597656 190.878906 96.464844 190.878906 96.308594 Z M 190.878906 96.308594 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 235.007812 92.339844 C 235.007812 92.160156 234.855469 92.007812 234.675781 92.007812 C 234.496094 92.007812 234.34375 92.160156 234.34375 92.339844 C 234.34375 92.519531 234.496094 92.667969 234.675781 92.667969 C 234.855469 92.667969 235.007812 92.519531 235.007812 92.339844 Z M 235.007812 92.339844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 209.125 102.257812 C 209.125 101.957031 208.882812 101.6875 208.554688 101.6875 C 208.253906 101.6875 207.984375 101.929688 207.984375 102.257812 C 207.984375 102.558594 208.222656 102.828125 208.554688 102.828125 C 208.882812 102.828125 209.125 102.589844 209.125 102.257812 Z M 209.125 102.257812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 203.191406 91.179688 C 203.070312 91.128906 202.964844 91.058594 202.871094 90.964844 C 202.777344 90.875 202.703125 90.765625 202.652344 90.644531 C 202.601562 90.523438 202.578125 90.398438 202.578125 90.265625 C 202.578125 90.132812 202.601562 90.007812 202.652344 89.886719 C 202.703125 89.765625 202.773438 89.65625 202.867188 89.5625 C 202.957031 89.46875 203.066406 89.398438 203.1875 89.347656 C 203.308594 89.296875 203.433594 89.269531 203.566406 89.269531 C 203.699219 89.269531 203.824219 89.292969 203.945312 89.34375 C 204.066406 89.394531 204.175781 89.464844 204.269531 89.558594 C 204.363281 89.652344 204.433594 89.757812 204.484375 89.878906 C 204.535156 90 204.558594 90.128906 204.5625 90.257812 C 204.5625 90.390625 204.535156 90.515625 204.488281 90.640625 C 204.4375 90.761719 204.367188 90.867188 204.273438 90.960938 C 204.179688 91.054688 204.074219 91.125 203.953125 91.175781 C 203.832031 91.226562 203.703125 91.253906 203.570312 91.253906 C 203.441406 91.253906 203.3125 91.230469 203.191406 91.179688 Z M 203.191406 91.179688 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 236.597656 62.460938 C 236.597656 62.328125 236.625 62.203125 236.675781 62.082031 C 236.726562 61.960938 236.796875 61.851562 236.890625 61.757812 C 236.984375 61.664062 237.089844 61.59375 237.210938 61.542969 C 237.332031 61.492188 237.460938 61.46875 237.589844 61.46875 C 237.722656 61.46875 237.847656 61.492188 237.972656 61.542969 C 238.09375 61.59375 238.199219 61.664062 238.292969 61.757812 C 238.386719 61.851562 238.457031 61.960938 238.507812 62.082031 C 238.558594 62.203125 238.582031 62.328125 238.582031 62.460938 C 238.582031 62.589844 238.558594 62.71875 238.507812 62.839844 C 238.457031 62.960938 238.386719 63.070312 238.292969 63.160156 C 238.199219 63.253906 238.09375 63.328125 237.972656 63.375 C 237.847656 63.425781 237.722656 63.453125 237.589844 63.453125 C 237.460938 63.453125 237.332031 63.425781 237.210938 63.375 C 237.089844 63.328125 236.984375 63.253906 236.890625 63.160156 C 236.796875 63.070312 236.726562 62.960938 236.675781 62.839844 C 236.625 62.71875 236.597656 62.589844 236.597656 62.460938 Z M 236.597656 62.460938 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 166.179688 96.835938 C 166.140625 96.597656 166.195312 96.382812 166.335938 96.1875 C 166.476562 95.992188 166.664062 95.875 166.902344 95.835938 C 167.140625 95.796875 167.355469 95.847656 167.550781 95.988281 C 167.746094 96.128906 167.863281 96.320312 167.902344 96.558594 C 167.941406 96.796875 167.890625 97.011719 167.75 97.207031 C 167.609375 97.402344 167.417969 97.519531 167.179688 97.558594 C 166.945312 97.597656 166.726562 97.542969 166.53125 97.402344 C 166.335938 97.261719 166.21875 97.074219 166.179688 96.835938 Z M 166.179688 96.835938 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 202.179688 67.027344 C 202.179688 66.851562 202.214844 66.675781 202.285156 66.511719 C 202.351562 66.347656 202.449219 66.199219 202.578125 66.074219 C 202.703125 65.945312 202.851562 65.847656 203.015625 65.78125 C 203.183594 65.710938 203.355469 65.675781 203.535156 65.675781 C 203.714844 65.675781 203.886719 65.710938 204.050781 65.78125 C 204.21875 65.847656 204.363281 65.945312 204.492188 66.074219 C 204.617188 66.199219 204.714844 66.347656 204.785156 66.511719 C 204.851562 66.675781 204.886719 66.851562 204.886719 67.027344 C 204.886719 67.207031 204.851562 67.382812 204.785156 67.546875 C 204.714844 67.710938 204.617188 67.859375 204.492188 67.984375 C 204.363281 68.113281 204.21875 68.210938 204.050781 68.277344 C 203.886719 68.347656 203.714844 68.382812 203.535156 68.382812 C 203.355469 68.382812 203.183594 68.347656 203.015625 68.277344 C 202.851562 68.210938 202.703125 68.113281 202.578125 67.984375 C 202.449219 67.859375 202.351562 67.710938 202.285156 67.546875 C 202.214844 67.382812 202.179688 67.207031 202.179688 67.027344 Z M 202.179688 67.027344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 178.855469 84.675781 C 178.855469 84.515625 178.910156 84.382812 179.023438 84.269531 C 179.132812 84.160156 179.269531 84.101562 179.425781 84.101562 C 179.585938 84.101562 179.71875 84.160156 179.832031 84.269531 C 179.941406 84.382812 179.996094 84.515625 179.996094 84.675781 C 179.996094 84.832031 179.941406 84.964844 179.832031 85.078125 C 179.71875 85.1875 179.585938 85.246094 179.425781 85.246094 C 179.269531 85.246094 179.132812 85.1875 179.023438 85.078125 C 178.910156 84.964844 178.855469 84.832031 178.855469 84.675781 Z M 178.855469 84.675781 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 181.921875 81.636719 C 181.921875 81.390625 182.007812 81.175781 182.183594 81 C 182.363281 80.824219 182.574219 80.734375 182.824219 80.734375 C 183.070312 80.734375 183.285156 80.824219 183.460938 81 C 183.636719 81.175781 183.726562 81.390625 183.726562 81.636719 C 183.726562 81.886719 183.636719 82.101562 183.460938 82.277344 C 183.285156 82.453125 183.070312 82.539062 182.824219 82.539062 C 182.574219 82.539062 182.363281 82.453125 182.183594 82.277344 C 182.007812 82.101562 181.921875 81.886719 181.921875 81.636719 Z M 181.921875 81.636719 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 36.902344 74.132812 C 36.6875 74.042969 36.535156 73.890625 36.445312 73.675781 C 36.355469 73.464844 36.355469 73.25 36.445312 73.035156 C 36.535156 72.820312 36.6875 72.667969 36.902344 72.578125 C 37.117188 72.488281 37.332031 72.488281 37.546875 72.578125 C 37.761719 72.667969 37.914062 72.820312 38 73.035156 C 38.089844 73.25 38.089844 73.464844 38 73.675781 C 37.914062 73.890625 37.761719 74.042969 37.546875 74.132812 C 37.332031 74.222656 37.117188 74.222656 36.902344 74.132812 Z M 36.902344 74.132812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 38.929688 73.339844 C 38.929688 74.273438 38.175781 75.054688 37.214844 75.054688 C 36.285156 75.054688 35.503906 74.304688 35.503906 73.339844 C 35.503906 72.410156 36.253906 71.628906 37.214844 71.628906 C 38.175781 71.628906 38.929688 72.410156 38.929688 73.339844 Z M 38.839844 73.339844 C 38.839844 72.46875 38.117188 71.75 37.246094 71.75 C 36.375 71.75 35.652344 72.46875 35.652344 73.339844 C 35.652344 74.214844 36.375 74.933594 37.246094 74.933594 C 38.117188 74.933594 38.839844 74.214844 38.839844 73.339844 Z M 38.839844 73.339844 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 13.050781 107.722656 C 12.890625 107.65625 12.75 107.5625 12.628906 107.441406 C 12.507812 107.320312 12.417969 107.179688 12.351562 107.023438 C 12.285156 106.863281 12.253906 106.699219 12.253906 106.527344 C 12.253906 106.355469 12.285156 106.191406 12.351562 106.035156 C 12.417969 105.875 12.507812 105.734375 12.628906 105.613281 C 12.75 105.492188 12.890625 105.398438 13.050781 105.332031 C 13.207031 105.269531 13.375 105.234375 13.542969 105.234375 C 13.714844 105.234375 13.882812 105.269531 14.039062 105.332031 C 14.199219 105.398438 14.335938 105.492188 14.457031 105.613281 C 14.578125 105.734375 14.671875 105.875 14.738281 106.035156 C 14.804688 106.191406 14.835938 106.355469 14.835938 106.527344 C 14.835938 106.699219 14.804688 106.863281 14.738281 107.023438 C 14.671875 107.179688 14.578125 107.320312 14.457031 107.441406 C 14.335938 107.5625 14.199219 107.65625 14.039062 107.722656 C 13.882812 107.789062 13.714844 107.820312 13.542969 107.820312 C 13.375 107.820312 13.207031 107.789062 13.050781 107.722656 Z M 13.050781 107.722656 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 16.175781 106.527344 C 16.175781 107.96875 15 109.140625 13.558594 109.140625 C 12.117188 109.140625 10.945312 107.96875 10.945312 106.527344 C 10.945312 105.085938 12.117188 103.910156 13.558594 103.910156 C 15 103.910156 16.175781 105.085938 16.175781 106.527344 Z M 16.023438 106.527344 C 16.023438 105.175781 14.910156 104.0625 13.558594 104.0625 C 12.207031 104.0625 11.09375 105.175781 11.09375 106.527344 C 11.09375 107.878906 12.207031 108.992188 13.558594 108.992188 C 14.910156 108.992188 16.023438 107.910156 16.023438 106.527344 Z M 16.023438 106.527344 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 248.074219 97.820312 C 248.027344 97.65625 248.011719 97.488281 248.027344 97.316406 C 248.042969 97.144531 248.09375 96.984375 248.175781 96.835938 C 248.253906 96.683594 248.363281 96.554688 248.496094 96.445312 C 248.628906 96.335938 248.777344 96.257812 248.941406 96.207031 C 249.105469 96.160156 249.269531 96.144531 249.441406 96.160156 C 249.613281 96.175781 249.773438 96.226562 249.925781 96.308594 C 250.074219 96.386719 250.207031 96.496094 250.3125 96.628906 C 250.421875 96.761719 250.5 96.910156 250.550781 97.074219 C 250.601562 97.238281 250.617188 97.40625 250.597656 97.574219 C 250.582031 97.746094 250.53125 97.90625 250.453125 98.058594 C 250.371094 98.207031 250.265625 98.339844 250.132812 98.445312 C 250 98.554688 249.851562 98.632812 249.6875 98.683594 C 249.523438 98.734375 249.355469 98.75 249.183594 98.730469 C 249.011719 98.714844 248.851562 98.664062 248.703125 98.585938 C 248.550781 98.503906 248.421875 98.398438 248.3125 98.265625 C 248.203125 98.132812 248.125 97.984375 248.074219 97.820312 Z M 248.074219 97.820312 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 251.929688 97.449219 C 251.929688 98.890625 250.757812 100.0625 249.3125 100.0625 C 247.871094 100.0625 246.699219 98.890625 246.699219 97.449219 C 246.699219 96.007812 247.871094 94.835938 249.3125 94.835938 C 250.757812 94.804688 251.929688 96.007812 251.929688 97.449219 Z M 251.777344 97.449219 C 251.777344 96.097656 250.667969 94.984375 249.3125 94.984375 C 247.960938 94.984375 246.847656 96.097656 246.847656 97.449219 C 246.847656 98.800781 247.960938 99.914062 249.3125 99.914062 C 250.667969 99.914062 251.777344 98.800781 251.777344 97.449219 Z M 251.777344 97.449219 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 156.3125 96.757812 C 156.3125 96.457031 156.070312 96.1875 155.738281 96.1875 C 155.410156 96.1875 155.167969 96.425781 155.167969 96.757812 C 155.167969 97.058594 155.410156 97.328125 155.738281 97.328125 C 156.070312 97.328125 156.3125 97.058594 156.3125 96.757812 Z M 156.3125 96.757812 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 4.207031 134.9375 8.773438 138.515625 13.613281 C 141.761719 18.003906 146.542969 22.75 152.703125 27.679688 C 163.105469 36.007812 177.59375 45.054688 195.808594 54.585938 C 212.011719 63.0625 228.183594 70.335938 238.914062 74.964844 C 250.546875 79.984375 258.421875 83.019531 258.511719 83.050781 L 258.511719 83.082031 C 258.421875 83.050781 250.546875 80.015625 238.914062 74.996094 C 228.183594 70.367188 212.011719 63.089844 195.808594 54.613281 C 177.59375 45.085938 163.105469 36.039062 152.703125 27.710938 C 146.542969 22.78125 141.730469 18.03125 138.484375 13.644531 C 134.910156 8.773438 133.105469 4.234375 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.785156 134.667969 8.082031 137.644531 12.863281 C 140.378906 17.222656 144.378906 22.089844 149.578125 27.320312 C 158.324219 36.128906 170.5 46.136719 185.769531 57.078125 C 199.355469 66.820312 212.972656 75.476562 221.960938 81.035156 C 231.757812 87.078125 238.371094 90.835938 238.433594 90.867188 L 238.402344 90.894531 C 238.34375 90.867188 231.699219 87.078125 221.960938 81.066406 C 212.941406 75.507812 199.355469 66.847656 185.769531 57.109375 C 170.46875 46.167969 158.292969 36.160156 149.578125 27.351562 C 144.378906 22.121094 140.378906 17.25 137.644531 12.890625 C 134.636719 8.082031 133.105469 3.785156 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.421875 134.636719 7.03125 137.613281 10.789062 C 140.320312 14.214844 144.285156 17.851562 149.425781 21.609375 C 158.085938 27.921875 170.167969 34.683594 185.316406 41.6875 C 198.816406 47.910156 212.28125 53.171875 221.207031 56.507812 C 230.886719 60.117188 237.472656 62.28125 237.53125 62.28125 L 237.53125 62.308594 C 237.472656 62.28125 230.886719 60.144531 221.207031 56.539062 C 212.28125 53.203125 198.785156 47.941406 185.316406 41.71875 C 170.167969 34.714844 158.085938 27.953125 149.425781 21.640625 C 144.285156 17.882812 140.289062 14.246094 137.582031 10.816406 C 134.609375 7.0625 133.105469 3.421875 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.421875 134.578125 7.210938 137.433594 11.328125 C 140.019531 15.085938 143.835938 19.203125 148.734375 23.5625 C 157.003906 30.929688 168.574219 39.132812 183.0625 47.941406 C 195.960938 55.785156 208.855469 62.671875 217.390625 67.058594 C 226.648438 71.808594 232.933594 74.753906 232.992188 74.785156 L 232.960938 74.816406 C 232.902344 74.785156 226.621094 71.839844 217.359375 67.089844 C 208.824219 62.699219 195.929688 55.816406 183.035156 47.972656 C 168.546875 39.164062 157.003906 30.957031 148.707031 23.59375 C 143.777344 19.234375 139.988281 15.117188 137.402344 11.359375 C 134.546875 7.210938 133.105469 3.421875 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.183594 134.398438 6.878906 136.890625 11.089844 C 139.148438 14.90625 142.484375 19.234375 146.78125 23.925781 C 154.027344 31.828125 164.125 40.9375 176.78125 51.007812 C 198.363281 68.140625 220.21875 82.269531 220.425781 82.417969 L 220.398438 82.449219 C 220.1875 82.300781 198.332031 68.171875 176.75 51.039062 C 164.066406 40.96875 153.964844 31.859375 146.722656 23.953125 C 142.421875 19.265625 139.085938 14.9375 136.832031 11.117188 C 134.367188 6.910156 133.105469 3.183594 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 4.746094 136.832031 11.179688 144.136719 19.175781 C 149.96875 25.578125 158.113281 32.972656 168.335938 41.207031 C 185.769531 55.15625 203.382812 66.820312 203.5625 66.9375 L 203.535156 66.96875 C 203.355469 66.847656 185.707031 55.214844 168.304688 41.207031 C 158.085938 33.003906 149.9375 25.578125 144.078125 19.175781 C 136.800781 11.179688 133.105469 4.777344 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.515625 134.457031 7.660156 137.074219 12.441406 C 139.449219 16.800781 142.964844 21.761719 147.472656 27.171875 C 155.078125 36.308594 165.71875 46.949219 179.035156 58.761719 C 201.730469 78.902344 224.726562 95.734375 224.964844 95.917969 L 224.9375 95.945312 C 224.695312 95.765625 201.699219 78.933594 179.003906 58.792969 C 165.660156 46.949219 155.046875 36.339844 147.441406 27.171875 C 142.90625 21.730469 139.417969 16.769531 137.042969 12.441406 C 134.425781 7.691406 133.105469 3.515625 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 4.054688 135.871094 10.367188 141.25 18.875 C 145.550781 25.667969 151.5625 33.902344 159.078125 43.28125 C 171.910156 59.273438 184.898438 73.339844 185.015625 73.460938 L 184.988281 73.492188 C 184.867188 73.339844 171.851562 59.304688 159.046875 43.3125 C 151.5 33.902344 145.519531 25.699219 141.222656 18.902344 C 135.839844 10.367188 133.105469 4.054688 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 4.234375 135.75 11.117188 140.890625 20.527344 C 145.007812 28.042969 150.75 37.179688 157.964844 47.699219 C 170.257812 65.585938 182.703125 81.457031 182.824219 81.636719 L 182.792969 81.667969 C 182.671875 81.519531 170.226562 65.648438 157.933594 47.730469 C 150.71875 37.210938 144.976562 28.074219 140.859375 20.558594 C 135.71875 11.148438 133.105469 4.265625 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 4.234375 135.570312 11.269531 140.378906 20.976562 C 144.226562 28.765625 149.578125 38.230469 156.28125 49.203125 C 167.734375 67.871094 179.304688 84.492188 179.425781 84.675781 L 179.394531 84.703125 C 179.277344 84.523438 167.703125 67.902344 156.25 49.234375 C 149.515625 38.261719 144.167969 28.792969 140.347656 21.007812 C 135.539062 11.269531 133.105469 4.234375 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 4.355469 134.910156 12.050781 138.425781 23.023438 C 141.222656 31.769531 145.160156 42.621094 150.058594 55.214844 C 158.414062 76.679688 166.921875 96.125 166.980469 96.308594 L 166.953125 96.335938 C 166.863281 96.15625 158.386719 76.707031 150.027344 55.246094 C 145.097656 42.621094 141.191406 31.800781 138.394531 23.050781 C 134.878906 12.082031 133.105469 4.355469 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 4.085938 134.335938 11.601562 136.652344 22.480469 C 138.515625 31.199219 141.128906 42.050781 144.40625 54.734375 C 150 76.347656 155.648438 96.097656 155.710938 96.308594 L 155.679688 96.308594 C 155.621094 96.097656 149.96875 76.378906 144.378906 54.734375 C 141.101562 42.019531 138.484375 31.167969 136.621094 22.480469 C 134.277344 11.601562 133.105469 4.085938 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.304688 134.488281 9.496094 137.164062 18.484375 C 139.296875 25.667969 142.304688 34.65625 146.058594 45.175781 C 152.464844 63.121094 158.925781 79.535156 158.984375 79.683594 L 158.957031 79.683594 C 158.894531 79.535156 152.402344 63.089844 146.03125 45.175781 C 142.273438 34.65625 139.296875 25.667969 137.132812 18.484375 C 134.457031 9.496094 133.105469 3.304688 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 10.667969 145.039062 70.726562 145.160156 71.328125 L 145.128906 71.328125 C 145.097656 71.175781 142.09375 55.996094 139.117188 39.707031 C 136.382812 24.707031 133.105469 5.617188 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 11.480469 134.425781 76.617188 134.457031 77.28125 L 134.425781 77.28125 C 134.398438 76.617188 133.105469 11.480469 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.875 131.0625 10.816406 126.972656 20.738281 C 123.695312 28.671875 119.15625 38.503906 113.445312 49.957031 C 103.738281 69.464844 93.878906 87.199219 93.789062 87.347656 L 93.757812 87.320312 C 93.847656 87.140625 103.707031 69.433594 113.417969 49.925781 C 119.128906 38.472656 123.667969 28.644531 126.941406 20.707031 C 131.03125 10.816406 133.105469 3.875 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 2.730469 131.363281 7.902344 127.816406 15.386719 C 124.988281 21.367188 121.050781 28.882812 116.121094 37.691406 C 107.734375 52.660156 99.226562 66.429688 99.136719 66.546875 L 99.109375 66.519531 C 99.199219 66.367188 107.703125 52.628906 116.089844 37.660156 C 121.019531 28.855469 124.957031 21.367188 127.785156 15.355469 C 131.332031 7.871094 133.105469 2.730469 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.4375 2.761719 132.984375 8.503906 131.78125 17.128906 C 130.820312 24.015625 129.40625 32.761719 127.515625 43.132812 C 124.328125 60.777344 120.960938 77.1875 120.929688 77.339844 L 120.902344 77.339844 C 120.929688 77.1875 124.328125 60.777344 127.484375 43.132812 C 129.347656 32.761719 130.789062 24.015625 131.753906 17.128906 C 132.953125 8.503906 133.40625 2.761719 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 4.023438 130.25 10.519531 124.539062 19.445312 C 119.96875 26.570312 113.625 35.226562 105.660156 45.175781 C 92.074219 62.128906 78.335938 77.160156 78.1875 77.308594 L 78.15625 77.28125 C 78.308594 77.128906 92.042969 62.097656 105.628906 45.144531 C 113.597656 35.195312 119.96875 26.539062 124.507812 19.414062 C 130.21875 10.519531 133.105469 4.023438 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 4.265625 129.738281 10.609375 123.066406 18.964844 C 117.714844 25.667969 110.289062 33.632812 100.941406 42.679688 C 85.039062 58.101562 68.929688 71.449219 68.746094 71.597656 L 68.71875 71.566406 C 68.867188 71.449219 85.011719 58.070312 100.910156 42.652344 C 110.261719 33.601562 117.683594 25.609375 123.035156 18.933594 C 129.707031 10.578125 133.105469 4.234375 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 5.046875 129.316406 12.5 121.742188 22.300781 C 115.699219 30.117188 107.285156 39.433594 96.703125 49.984375 C 78.699219 67.929688 60.480469 83.410156 60.300781 83.59375 L 60.269531 83.5625 C 60.453125 83.410156 78.667969 67.902344 96.671875 49.957031 C 107.253906 39.40625 115.671875 30.117188 121.710938 22.269531 C 129.289062 12.5 133.105469 5.015625 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.125 131.753906 6.640625 129.015625 10.546875 C 126.523438 14.09375 122.886719 18.03125 118.136719 22.269531 C 110.167969 29.394531 99.078125 37.480469 85.160156 46.257812 C 61.445312 61.226562 37.425781 73.25 37.1875 73.371094 L 37.15625 73.339844 C 37.394531 73.222656 61.414062 61.199219 85.128906 46.226562 C 99.046875 37.449219 110.140625 29.363281 118.105469 22.242188 C 122.824219 18.003906 126.492188 14.066406 128.957031 10.519531 C 131.722656 6.609375 133.105469 3.125 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.605469 131.601562 7.78125 128.597656 12.5 C 125.859375 16.769531 121.863281 21.609375 116.664062 26.839844 C 107.945312 35.648438 95.769531 45.746094 80.5 56.839844 C 66.914062 66.730469 53.328125 75.597656 44.339844 81.308594 C 34.601562 87.5 27.957031 91.378906 27.898438 91.40625 L 27.867188 91.378906 C 27.925781 91.347656 34.570312 87.441406 44.308594 81.277344 C 53.296875 75.566406 66.882812 66.699219 80.472656 56.808594 C 95.742188 45.6875 107.914062 35.617188 116.632812 26.808594 C 121.800781 21.578125 125.832031 16.769531 128.535156 12.5 C 131.570312 7.78125 133.105469 3.605469 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 2.101562 132.652344 4.234375 131.664062 6.339844 C 130.730469 8.382812 129.316406 10.488281 127.515625 12.589844 C 124.117188 16.53125 119.128906 20.675781 112.695312 24.886719 C 101.84375 31.980469 86.691406 39.433594 67.695312 47.039062 C 50.800781 53.804688 33.878906 59.425781 22.695312 62.972656 C 10.554688 66.789062 2.316406 69.042969 2.226562 69.074219 L 2.226562 69.042969 C 2.316406 69.011719 10.554688 66.757812 22.667969 62.941406 C 33.878906 59.425781 50.773438 53.773438 67.664062 47.007812 C 86.664062 39.40625 101.8125 31.949219 112.664062 24.855469 C 119.097656 20.648438 124.085938 16.5 127.484375 12.5625 C 131.210938 8.261719 133.105469 4.054688 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.632812 131.753906 10.519531 129.015625 20.585938 C 126.824219 28.613281 123.785156 38.683594 119.9375 50.496094 C 113.417969 70.605469 106.804688 89.03125 106.742188 89.242188 L 106.710938 89.242188 C 106.773438 89.0625 113.386719 70.605469 119.910156 50.496094 C 123.726562 38.683594 126.792969 28.613281 128.988281 20.585938 C 131.722656 10.519531 133.105469 3.632812 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.484375 135.328125 9.285156 139.628906 17.371094 C 143.054688 23.832031 147.863281 31.738281 153.875 40.90625 C 164.125 56.476562 174.527344 70.425781 174.617188 70.546875 L 174.585938 70.578125 C 174.496094 70.425781 164.097656 56.507812 153.847656 40.9375 C 147.835938 31.800781 143.023438 23.863281 139.566406 17.402344 C 135.300781 9.316406 133.105469 3.484375 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 5.195312 136.199219 13.433594 142.242188 24.554688 C 147.082031 33.453125 153.816406 44.246094 162.292969 56.570312 C 176.722656 77.578125 191.328125 96.097656 191.449219 96.277344 L 191.421875 96.308594 C 191.269531 96.125 176.660156 77.609375 162.261719 56.597656 C 153.785156 44.246094 147.050781 33.484375 142.214844 24.554688 C 136.171875 13.433594 133.105469 5.195312 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.195312 0.0859375 L 133.164062 0.0859375 C 133.164062 5.195312 130.097656 13.433594 124.058594 24.554688 C 119.21875 33.453125 112.484375 44.246094 104.007812 56.570312 C 89.578125 77.578125 74.96875 96.097656 74.851562 96.277344 L 74.878906 96.308594 C 75.03125 96.125 89.640625 77.609375 104.039062 56.597656 C 112.515625 44.246094 119.246094 33.484375 124.085938 24.554688 C 130.097656 13.433594 133.195312 5.195312 133.195312 0.0859375 Z M 133.195312 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 133.105469 0.0859375 L 133.136719 0.0859375 C 133.136719 3.695312 134.609375 7.960938 137.492188 12.742188 C 140.140625 17.101562 143.988281 22 149.007812 27.289062 C 157.421875 36.1875 169.175781 46.378906 183.90625 57.589844 C 197.011719 67.539062 210.117188 76.46875 218.804688 82.210938 C 228.210938 88.429688 234.617188 92.308594 234.675781 92.371094 L 234.644531 92.398438 C 234.585938 92.371094 228.183594 88.460938 218.773438 82.238281 C 210.085938 76.496094 196.980469 67.601562 183.875 57.621094 C 169.144531 46.4375 157.394531 36.25 148.976562 27.320312 C 143.988281 22.03125 140.109375 17.128906 137.464844 12.773438 C 134.578125 7.960938 133.105469 3.695312 133.105469 0.0859375 Z M 133.105469 0.0859375 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <g mask="url(#aaf6e7aaca)">
        <g transform="matrix(1, 0, 0, 1, 74, 96)">
          <g clip-path="url(#dc53b0869c)">
            <g clip-path="url(#c3c5d2ecc6)">
              <path
                fill={fill}
                d="M 0.878906 14.132812 L 0.789062 14.132812 L 0.789062 0.335938 L 0.878906 0.335938 Z M 0.878906 14.132812 "
                fill-opacity="1"
                fill-rule="nonzero"
              />
            </g>
          </g>
        </g>
      </g>
      <path
        fill={fill}
        d="M 75.179688 96.335938 C 75.179688 96.15625 75.03125 96.007812 74.851562 96.007812 C 74.667969 96.007812 74.519531 96.15625 74.519531 96.335938 C 74.519531 96.515625 74.667969 96.667969 74.851562 96.667969 C 75.03125 96.667969 75.179688 96.515625 75.179688 96.335938 Z M 75.179688 96.335938 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 75.421875 110.042969 C 75.421875 109.742188 75.179688 109.472656 74.851562 109.472656 C 74.550781 109.472656 74.277344 109.714844 74.277344 110.042969 C 74.277344 110.34375 74.519531 110.617188 74.851562 110.617188 C 75.152344 110.617188 75.421875 110.375 75.421875 110.042969 Z M 75.421875 110.042969 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 135.808594 77.28125 C 135.808594 76.4375 135.117188 75.746094 134.277344 75.746094 C 133.4375 75.746094 132.746094 76.4375 132.746094 77.28125 C 132.746094 78.121094 133.4375 78.8125 134.277344 78.8125 C 135.148438 78.8125 135.808594 78.121094 135.808594 77.28125 Z M 135.808594 77.28125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
      <path
        fill={fill}
        d="M 137.402344 77.28125 C 137.402344 78.992188 135.992188 80.40625 134.277344 80.40625 C 132.5625 80.40625 131.152344 78.992188 131.152344 77.28125 C 131.152344 75.566406 132.5625 74.152344 134.277344 74.152344 C 136.019531 74.152344 137.402344 75.566406 137.402344 77.28125 Z M 137.222656 77.28125 C 137.222656 75.65625 135.902344 74.332031 134.277344 74.332031 C 132.652344 74.332031 131.332031 75.65625 131.332031 77.28125 C 131.332031 78.902344 132.652344 80.226562 134.277344 80.226562 C 135.902344 80.226562 137.222656 78.902344 137.222656 77.28125 Z M 137.222656 77.28125 "
        fill-opacity="1"
        fill-rule="nonzero"
      />
    </svg>
  );
};
