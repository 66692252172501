import { ReactNode } from "react";

type TextElementPropsType = {
  children: ReactNode;
  headline?: string;
  order?: "left" | "right";
  text?: string;
};

export const TextElementSection = ({
  children,
  headline,
  order = "left",
  text,
}: TextElementPropsType) => {
  return (
    <div
      className={`flex justify-between section-bg flex-col  ${
        order === "left" ? "lg:flex-row" : "lg:flex-row-reverse"
      } `}
    >
      <div className={`p-5 md:p-10 min-h-full heading-4 flex flex-col justify-center gap-5 max-w-[700px] ${order === "left" ? "" : "mr-auto ml-5"} `}>
        <h5 className="heading-2 text-gradient" >{headline}</h5>
        <p className="body-1">{text}</p>
      </div>
      <div
        className={`p-2 -ml-4 -mr-4 md:p-5 lg:-mr-10 ${
          order === "left" ? "lg:-mr-10" : "lg:-ml-10"
        }`}
      >
        <div className="h-[400px] w-full lg:w-[600px]">{children}</div>
      </div>
    </div>
  );
};
