import { useState, useEffect } from "react";
import logo from "../../Assets/logo.png";
import { Link } from "react-router-dom";
import { LanguageSelector } from "../../Components/LanguageSelector/LanguageSelector";

export const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 100) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const pages = [
    { label: "Home", link: "/" },
    { label: "AI", link: "/ai" },
    { label: "Development", link: "/development" },
    { label: "Magnet Forms", link: "/forms" },
  ];

  return (
    <div
      className={`fixed w-full flex gap-5 md:flex-row flex-col justify-between z-50 items-center p-5 md:px-10 py-5 transition-colors duration-300 ${
        isScrolled ? "bg-background" : "bg-transparent"
      }`}
    >
      <hr
        className="absolute bottom-0 w-full h-px -translate-x-1/2 border-0 opacity-10 left-1/2"
        style={{
          backgroundImage:
            "linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 52.07%, rgba(255, 255, 255, 0) 100%)",
        }}
      ></hr>
      <div className="flex items-center">
        <img className="w-9 p-1 rounded-lg z-10" src={logo} alt="Logo" />
        <h1
          className={`logo pl-4 -ml-3 ${
            isScrolled ? "text-main" : "text-main"
          }`}
        >
          MILLERIO
        </h1>
      </div>
      <p className={`${isScrolled ? "text-main" : "text-main"} `}>
        Website under development - Preview version
      </p>
      <div>
        <LanguageSelector />
      </div>
      {/* <div className="flex gap-10">
        {pages.map((page) => (
          <Link
            className={`body-2 hover:underline ${
              isScrolled ? "text-main" : ""
            } `}
            to={page.link}
          >
            {page.label}
          </Link>
        ))}
      </div> */}
    </div>
  );
};
