import { StepFormStepType } from "../StepFormTypes";
import {
  FormUserActionType,
  useRenderStepAnswer,
} from "../useRenderStepAnswer";

type StepFormAnswerPropsType = {
  currentStepData: StepFormStepType;
  answerInState: any;
  translate: string;
  handleUserAction: (action: FormUserActionType) => void;
};

export const StepFormAnswer = ({
  currentStepData,
  answerInState,
  translate,
  handleUserAction,
}: StepFormAnswerPropsType) => {
  return (
    <div
      key={currentStepData.id}
      className={`w-full min-h-full gap-5 flex items-center justify-center `}
    >
      {useRenderStepAnswer(
        currentStepData.answer,
        answerInState,
        (action) => handleUserAction(action),
        translate
      )}
    </div>
  );
};
