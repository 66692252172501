export default function CarouselItem({
  text,
  image,
}: {
  text: string;
  image: any;
}) {
  return (
    <div className="relative h-fit">
      <p className="text-2xl text-gradient font-bold tracking-[0.4rem] z-10 absolute top-1/2 -translate-x-1/2 -translate-y-1/2  left-1/2">{text}</p>
      <div className="opacity-40">{image}</div>
    </div>
  );
}
