import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { useTranslate } from "../../hooks/useTranslation";
import { getSvg } from "../../Utils/svgs/getSvg";
import { getThemeColor } from "../../Utils/tailwind/tailwindUtils";
import { ImageButton } from "../../Components/TabImageButton/ImageButton";
import { Slider } from "../../Components/Slider/Slider";

export type SectionType = {
  headline: string;
  text?: string;
  image: string;
  listOfItems?: { text: string }[];
};

export type TabsSectionPropsType = {
  sections: SectionType[];
  right?: boolean;
  className?: string;
};

export const TabsSection = ({
  sections,
  right,
  className,
}: TabsSectionPropsType) => {
  const translate = useTranslate();
  const [selectedService, setSelectedService] = useState<number>(0);

  const getFloatingClassName = (index: number) => {
    switch (index) {
      case 0:
        return `mr-[10%] md:mr-[25%] `;
      case 1:
        return `ml-[10%] md:ml-[25%] `;
      case 2:
        return `mr-[10%] md:mr-[25%] `;
      case 3:
        return `ml-[10%] md:ml-[25%] `;
      case 4:
        return `mr-[10%] md:mr-[25%] `;
      case 5:
        return `ml-[10%] md:ml-[25%] `;
      default:
        return "";
    }
  };

  const listItemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
        delay: 0.1 + i * 0.1,
      },
    }),
    exit: { opacity: 0, y: 10, transition: { duration: 0.1 } },
  };

  const renderContent = (index: number, isMobile: boolean = false) => (
    <AnimatePresence mode="wait">
      <motion.div
        key={index}
        className="relative w-full h-full z-10 flex flex-col items-center justify-center"
      >
        {sections[index].image && (
          <motion.div
            key={sections[index].image}
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 0.3, y: 0 }}
            transition={{ duration: 2 }}
            className="w-full h-full absolute"
          >
            {getSvg(
              sections[index].image,
              getThemeColor("accent"),
              "100%",
              "100%"
            )}
          </motion.div>
        )}
        <motion.ul
          className={`w-full relative gap-4 flex flex-col items-center ${
            isMobile ? "" : "-translate-y-10"
          }`}
          initial="hidden"
          animate="visible"
          exit="exit"
        >
          {sections[index].listOfItems?.map((item, itemIndex) => (
            <motion.li
              key={itemIndex}
              className={`body-1 py-2 p-1 md:px-4 md:px-20 tracking-wide w-fit font-bold backdrop-blur-sm text-gradient ${getFloatingClassName(
                itemIndex
              )}`}
              custom={itemIndex}
              variants={listItemVariants}
            >
              {translate(item.text)}
            </motion.li>
          ))}
        </motion.ul>
      </motion.div>
    </AnimatePresence>
  );

  const mobileSlides = sections.map((service, index) => (
    <div
      key={index}
      className="flex flex-col items-center justify-between gap-y-10 h-full"
    >
      <div className="flex-grow w-full">{renderContent(index, true)}</div>
      <div className="mt-auto text-center">
        <h2 className="heading-3">{translate(service.headline)}</h2>
        {service.text && (
          <p className="body-1 max-w-[500px]">{translate(service.text!)}</p>
        )}
      </div>
    </div>
  ));

  return (
    <section
      className={`${className} rounded-lg h-full relative -ml-5 -mr-5 md:-ml-20 md:-mr-20 lg:mx-auto `}
    >
      <div className={`flex flex-col lg:flex-row ${right ? "lg:flex-row-reverse" : ""} `}>
        <div className="w-1/3 lg:block hidden">
          <ul className="">
            {sections.map((service, index) => (
              <li
                key={index}
                className="border-t py-4 first:pt-0 first:border-t-0 border-grey"
              >
                <button
                  onClick={() => setSelectedService(index)}
                  className={`text-left w-full p-2 pl-0 transition-colors duration-300 ${
                    selectedService === index
                      ? "text-main hover:cursor-default"
                      : "text-lightGrey"
                  }`}
                >
                  <h2 className={`heading-3 w-fit ${right ? "lg:ml-auto" : ""}`}>{translate(service.headline)}</h2>
                  {service.text && (
                    <p className="body-1">{translate(service.text!)}</p>
                  )}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div className="lg:block hidden w-2/3 p-6 flex h-[400px] items-center relative justify-center min-h-full ">
          {renderContent(selectedService)}
        </div>
      </div>
      <div className="lg:hidden">
        <Slider
          slides={mobileSlides}
          className="w-full h-full"
          slideClassName="flex flex-col  px-5 md:px-20"
        />
      </div>
    </section>
  );
};
