import React, { ReactNode, useState } from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

interface SliderProps {
  slides: ReactNode[];
  renderDots?: boolean;
  className?: string;
  slideClassName?: string;
}

interface DotsProps {
  selectedIndex: number;
  length: number;
  onClick: (index: number) => void;
}

const Dots: React.FC<DotsProps> = ({ selectedIndex, length, onClick }) => {
  return (
    <div className="flex justify-center mt-10">
      {[...Array(length)].map((_, idx) => (
        <button
          key={idx}
          onClick={() => onClick(idx)}
          className={`h-2 w-2 rounded-full mx-1 transition-all ${
            idx === selectedIndex ? "bg-main !w-10" : "bg-lightGrey"
          }`}
        />
      ))}
    </div>
  );
};

export const Slider: React.FC<SliderProps> = ({
  slides,
  renderDots = true,
  className = "",
  slideClassName = "",
}) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slideChanged(slider) {
      setCurrentSlide(slider.track.details.rel);
    },
  });

  return (
    <div className={className}>
      <div ref={sliderRef} className="keen-slider">
        {slides.map((slide, index) => (
          <div key={index} className={`keen-slider__slide ${slideClassName}`}>
            {slide}
          </div>
        ))}
      </div>
      {renderDots && (
        <Dots
          selectedIndex={currentSlide}
          length={slides.length}
          onClick={(index) => {
            instanceRef.current?.moveToIdx(index);
          }}
        />
      )}
    </div>
  );
};
